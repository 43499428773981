import React, { useEffect, useState, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import {
  FormGroup,
  Grid,
  Button,
  makeStyles,
  TextField,
  CircularProgress,
  Container,
} from '@material-ui/core';
import { API, graphqlOperation } from 'aws-amplify';

import { getCategory } from '../../graphql/queries';
import { updateCategory, createCategory } from '../../graphql/mutations';
import { categoryActions } from '../../actions';
import Message from '../../components/Message';
import LoadingBar from '../../components/Loading';

const useStyles = makeStyles((theme) => ({
  bottom: {
    marginTop: 20,
  },
  container: {
    textAlign: 'center',
  },
  form: {
    width: '100%',
    marginTop: 50,
  },
}));

const Category = () => {
  const { id } = useParams();
  const { category } = useSelector((state) => state.category);
  const history = useHistory();
  const classes = useStyles();
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState('');
  const [type, setType] = useState('');
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    dispatch(categoryActions.setCategoryInfo({ getCategory: null }));
    if (id) {
      setLoading(true);
      getCategoryInfo(id);
    }
  }, [id]);

  useEffect(() => {
    reset({ title: category?.title, category_Id: category?.category_Id });
  }, [category]);

  const { register, handleSubmit, errors: fieldsErrors, reset } = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
  });

  const getCategoryInfo = async (id) => {
    try {
      const { data } = await API.graphql(
        graphqlOperation(getCategory, {
          id: id,
        })
      );
      setLoading(false);
      dispatch(categoryActions.setCategoryInfo(data));
    } catch (error) {
      console.log('error on fetching follows', error);
      setLoading(false);
      setOpen(true);
      setMessage('Something went wrong');
      setType('error');
    }
  };

  const onSubmit = async (formData) => {
    const updatedData = {
      ...formData,
      id: category?.id,
    };
    setOpen(false);
    try {
      if (category?.id) {
        const { data } = await API.graphql(
          graphqlOperation(updateCategory, { input: updatedData })
        );
        dispatch(categoryActions.updateCategoryInfo(data));
      } else {
        const { data } = await API.graphql(
          graphqlOperation(createCategory, {
            input: { ...formData },
          })
        );
      }
      setMessage('Category has been updated successfully');
      setType('success');
      setOpen(true);
      setTimeout(() => {
        history.goBack();
      }, 3000);
    } catch (err) {
      setOpen(true);
      setMessage('Something went wrong');
      setType('error');
    }
  };

  if (loading) return <LoadingBar />;

  return (
    <Container maxWidth="sm">
      <Message
        open={open}
        type={type}
        handleClose={() => setOpen(false)}
        message={message}
      />
      <Grid container>
        <form
          onSubmit={handleSubmit(onSubmit)}
          fullWidth
          noValidate
          autoComplete="off"
          className={classes.form}>
          <FormGroup>
            <TextField
              label="Title"
              name="title"
              type="text"
              variant="outlined"
              margin="normal"
              error={!!fieldsErrors.title}
              inputRef={register({
                required: 'Title is required.',
              })}
              fullWidth
              helperText={fieldsErrors.title && fieldsErrors.title.message}
            />
          </FormGroup>
          <FormGroup>
            <TextField
              label="Category Id"
              name="category_Id"
              type="number"
              variant="outlined"
              margin="normal"
              error={!!fieldsErrors.category_Id}
              inputRef={register({
                required: 'Category Id is required.',
              })}
              fullWidth
              helperText={
                fieldsErrors.category_Id && fieldsErrors.category_Id.message
              }
            />
          </FormGroup>
          <Grid container justify="space-between" className={classes.bottom}>
            <Button
              variant="outlined"
              type="button"
              onClick={() => history.goBack()}>
              Cancel
            </Button>
            <Button variant="contained" color="primary" type="submit">
              Save Changes
            </Button>
          </Grid>
        </form>
      </Grid>
    </Container>
  );
};

export default Category;
