import React from 'react';
import ReactHlsPlayer from 'react-hls-player';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';

export default function VideoDialog({ open, handleClose, videoUrl }) {
  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <video width="320" height="480" controls>
          <source src={videoUrl} type="video/mp4" />
        </video>
        <DialogActions>
          <a href={videoUrl} download>
            Download
          </a>
        </DialogActions>
      </Dialog>
    </div>
  );
}
