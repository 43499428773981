import { handleActions } from 'redux-actions';
import { categoryActions } from '../actions';

const defaultState = {
  categories: [],
  nextToken: null,
  category: null,
};

const reducer = handleActions(
  {
    [categoryActions.setCategoryList]: (
      state,
      { payload: { listCategorys } }
    ) => {
      const sortedCategories = listCategorys.items.sort(
        (a, b) => new Date(b.updatedAt) - new Date(a.updatedAt)
      );
      return {
        ...state,
        categories: sortedCategories,
        nextToken: listCategorys.nextToken,
      };
    },
    [categoryActions.setCategoryInfo]: (
      state,
      { payload: { getCategory } }
    ) => {
      return {
        ...state,
        category: getCategory,
      };
    },
    [categoryActions.updateCategoryInfo]: (
      state,
      { payload: { updateCategory } }
    ) => {
      return {
        ...state,
        category: updateCategory,
      };
    },
  },
  defaultState
);

export default reducer;
