import { createActions } from 'redux-actions';

const authActions = createActions({
  SET_USER_INFO: undefined,
  SIGN_OUT: undefined,
});

const userActions = createActions({
  SET_USER_LIST: undefined,
  SET_USER_PROFILE: undefined,
});

const affiliateReport = createActions({
  VIEW_DETAILS: undefined,
});

const categoryActions = createActions({
  SET_CATEGORY_LIST: undefined,
  SET_CATEGORY_INFO: undefined,
  UPDATE_CATEGORY_INFO: undefined,
});

const videoActions = createActions({
  SET_POST_LIST: undefined,
  SET_REPORTED_LIST: undefined,
  SET_VIDEO_CATEGORY_LIST: undefined,
  UPDATE_VIDEO_POST: undefined,
  DELETE_VIDEO_POST: undefined,
});

export { authActions, userActions, categoryActions, videoActions, affiliateReport };
