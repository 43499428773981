/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { Paper, Grid, Button } from '@material-ui/core';
import { API, graphqlOperation } from 'aws-amplify';

import { listCategorys } from '../../graphql/queries';
import Title from '../../components/Title';
import CustomTable from '../../components/Table';
import SearchInput from '../../components/SearchInput';
import LoadingBar from '../../components/Loading';
import { categoryActions } from '../../actions';

const useStyles = makeStyles((theme) => ({
  seeMore: {
    marginTop: theme.spacing(3),
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  action: {
    display: 'flex',
    justifyContent: 'space-between',
    width: 100,
    float: 'right',
  },
  title: {
    marginBottom: theme.spacing(2),
  },
}));

const headers = [
  { id: 'title', label: 'Title', minWidth: 170, maxWidth: 250 },
  { id: 'action', label: '', align: 'right' },
];

function createData(title, action) {
  return {
    title,
    action,
  };
}

export default function Categories() {
  const classes = useStyles();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState();
  const [rows, setRows] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const history = useHistory();
  const dispatch = useDispatch();
  const { categories } = useSelector((state) => state.category);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(event.target.value);
    setPage(0);
  };

  const handleRequestSort = (event, property) => {
    if (property === 'action') return;
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  useEffect(() => {
    setLoading(true);
    getAllCategories();
  }, []);

  useEffect(() => {
    generateRows(categories);
  }, [categories]);

  const getAllCategories = async () => {
    try {
      const { data } = await API.graphql(graphqlOperation(listCategorys));
      console.log('DATA', data)
      dispatch(categoryActions.setCategoryList(data));
      setLoading(false);
    } catch (error) {
      console.log('error on fetching follows', error);
      setLoading(false);
    }
  };

  const generateRows = (categories) => {
    const rows = [];
    categories.forEach((v) => {
      rows.push(
        createData(
          v.title,
          <div className={classes.action}>
            <Link to={`/dashboard/category/${v.id}`}>EDIT</Link>
          </div>
        )
      );
    });
    setRows(rows);
  };

  const handleSearch = (e) => {
    const filtedCategories = categories.filter((v) =>
      v.title?.toLowerCase().includes(e.target.value.toLowerCase())
    );
    generateRows(filtedCategories);
  };

  return (
    <>
      <Paper className={classes.paper}>
        <Grid container justify="space-between" className={classes.title}>
          <Title>Categories</Title>
          <Button
            variant="contained"
            color="primary"
            onClick={() => history.push('/dashboard/category')}>
            Create Category
          </Button>
        </Grid>
        <SearchInput onChange={handleSearch} />
        <CustomTable
          headers={headers}
          rows={rows}
          page={page}
          rowsPerPage={rowsPerPage}
          handleChangePage={handleChangePage}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
          handleRequestSort={handleRequestSort}
          order={order}
          orderBy={orderBy}
        />
      </Paper>
      {isLoading && <LoadingBar />}
    </>
  );
}
