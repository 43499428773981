/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_appsync_graphqlEndpoint": "https://34x2la4cybcvrl33yli7aymsxm.appsync-api.us-east-1.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_appsync_apiKey": "da2-vfyas7sayng4tdpaechncgygsy",
    "aws_user_files_s3_bucket": "cazvidmobile-input102305-develop",
    "aws_user_files_s3_bucket_region": "us-east-1",
    "aws_mobile_analytics_app_id": "e74fb4dd298d4aea92ffac8614c54959",
    "aws_mobile_analytics_app_region": "us-east-1",
    "aws_cognito_identity_pool_id": "us-east-1:42637a0a-d54a-4cd7-957e-c5089b77e1e3",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_fP25FMXLP",
    "aws_user_pools_web_client_id": "70bn6n5cqmkmioti6tdm1g35ea",
    "oauth": {
        "domain": "cazvid.auth.us-east-1.amazoncognito.com",
        "scope": [
            "aws.cognito.signin.user.admin",
            "email",
            "openid",
            "phone",
            "profile"
        ],
        "redirectSignIn": "cazvidapp://",
        "redirectSignOut": "cazvidapp://",
        "responseType": "code"
    },
    "federationTarget": "COGNITO_USER_POOLS",
    "Analytics": {
        "disabled": "true"
    }
};


export default awsmobile;
