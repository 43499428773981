import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';

import {
  FormGroup,
  Grid,
  Button,
  makeStyles,
  Typography,
  TextField,
  Select,
  MenuItem,
} from '@material-ui/core';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import { API, graphqlOperation } from 'aws-amplify';

import VideoDialog from './VideoDialog';
import CustomTable from '../../components/Table';
import CustomTab from '../../components/Tabs';
import LoadingBar from '../../components/Loading';
import Message from '../../components/Message';
import { getUser } from '../../graphql/queries';
import { updatePost, updateUser } from '../../graphql/mutations';
import { userActions } from '../../actions';

const useStyles = makeStyles((theme) => ({
  form: {
    width: '100%',
  },
  bottom: {
    width: '100%',
    maxWidth: '300px',
    marginTop: theme.spacing(4),
  },
  container: {
    marginTop: theme.spacing(4),
  },
}));

const headers = [
  { id: 'user_name', label: 'User', minWidth: 170 },
  {
    id: 'category',
    label: 'Category',
    minWidth: 170,
  },
  {
    id: 'loc_name',
    label: 'Location',
    minWidth: 170,
  },
  {
    id: 'post_type',
    label: 'Job Type',
    minWidth: 170,
  },
  {
    id: 'createdAt',
    label: 'Date',
    minWidth: 170,
  },
  {
    id: 'content',
    label: 'Description',
    minWidth: 120,
  },
  {
    id: 'default_signature',
    label: 'Signature',
    minWidth: 170,
  },
  {
    id: 'video',
    label: 'Video',
    minWidth: 170,
    align: 'center',
  },
  { id: 'action', label: '' },
];

function createData(
  user_name,
  category,
  loc_name,
  post_type,
  createdAt,
  content,
  default_signature,
  video,
  action
) {
  return {
    user_name,
    category,
    loc_name,
    post_type,
    createdAt,
    content,
    default_signature,
    video,
    action,
  };
}

const LABELS = [
  { id: 'all', label: 'ALL' },
  { id: 'approved', label: 'APPROVED' },
  { id: 'waiting', label: 'WAITING APPROVAL' },
  { id: 'rejected', label: 'REJECTED' },
];

function Profile() {
  const { id } = useParams();
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const { userProfile } = useSelector((state) => state.user);
  const [allVideos, setAllVideos] = useState([]);
  const [filteredVideos, setFilteredVideos] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('first_name');
  const [rows, setRows] = useState([]);
  const [category, setCategory] = useState();
  const [videoUrl, setVideoUrl] = useState();
  const [open, setOpenModal] = useState(false);
  const [openMessage, setOpen] = useState(false);
  const [message, setMessage] = useState('');
  const [type, setType] = useState('');

  const { register, handleSubmit, errors: fieldsErrors, reset } = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
  });

  useEffect(() => {
    dispatch(userActions.setUserProfile({ getUser: null }));
    getUserProfile(id);
  }, [id]);

  useEffect(() => {
    if (userProfile && userProfile.allVideos) {
      setAllVideos(userProfile.allVideos.items);
      setFilteredVideos(userProfile.allVideos.items);
    }
    reset({ ...userProfile });
  }, [userProfile]);

  useEffect(() => {
    updateRows(filteredVideos.filter((v) => v.is_active === 1));
  }, [filteredVideos]);

  const getUserProfile = async (id) => {
    try {
      const { data } = await API.graphql(
        graphqlOperation(getUser, {
          user_id: id,
        })
      );
      dispatch(userActions.setUserProfile(data));
    } catch (error) {
      console.log('error on fetching follows', error);
    }
  };

  const onSelectLabel = (id) => {
    let filteredVideos = [];
    if (id === 0) {
      filteredVideos = allVideos.filter((v) => v.is_active === 1);
    } else if (id === 1) {
      filteredVideos = allVideos.filter(
        (v) => v.is_active === 1 && v.is_approved === 1
      );
    } else if (id === 2) {
      filteredVideos = allVideos.filter(
        (v) => v.is_active === 1 && !v.is_flagged && v.is_approved === 0
      );
    } else {
      filteredVideos = allVideos.filter(
        (v) => v.is_active === 1 && v.is_approved === 2
      );
    }
    setFilteredVideos(filteredVideos);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(event.target.value);
    setPage(0);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangeCategory = (event) => {
    setCategory(event.target.value);
  };

  const showVideo = (video) => {
    setVideoUrl(video);
    setOpenModal(true);
  };

  const toggleApproveVideo = async (type, video) => {
    let updatedData = {
      id: video.id,
      is_approved: type === 'approve' ? 1 : 2,
    };
    try {
      await API.graphql(graphqlOperation(updatePost, { input: updatedData }));
      setMessage('Video has been updated successfully');
      setType('success');
      setOpen(true);
    } catch (err) {
      setOpen(true);
      setMessage('Please contact admin user');
      setType('error');
    }
  };

  const updateRows = (filteredVideos) => {
    const rows = [];
    filteredVideos.forEach((v) => {
      rows.push(
        createData(
          v.user_name,
          <>
            {v.categories.items.filter((_v) => _v).length > 0 && (
              <Select
                labelId="select-helper-label"
                id="select-helper"
                value={category}
                onChange={handleChangeCategory}
                className={classes.select}>
                {v.categories.items
                  .filter((_v) => _v)
                  .map((item) => (
                    <MenuItem value={item.categoryID} key={item.id}>
                      {item.category.title}
                    </MenuItem>
                  ))}
              </Select>
            )}
          </>,
          v.loc_name,
          v.post_type,
          v.createdAt,
          v.content,
          v.default_signature,
          <img
            src={v.post_thumbnail}
            alt="thumbnail"
            className={classes.thumbnail}
            onClick={() => showVideo(v.post_video)}
          />,
          <div>
            <Button
              variant="outlined"
              color="primary"
              disabled={v.is_approved === 1}
              onClick={() => toggleApproveVideo('approve', v)}
              className={classes.button}
              startIcon={<CheckIcon />}>
              Approve
            </Button>
            <Button
              variant="outlined"
              color="secondary"
              aria-label="close"
              disabled={v.is_approved === 2}
              className={classes.button}
              startIcon={<CloseIcon />}
              onClick={() => toggleApproveVideo('reject', v)}>
              Reject
            </Button>
          </div>
        )
      );
    });
    setRows(rows);
  };

  const onSubmit = async (data) => {
    data.user_id = userProfile.user_id;
    console.log(data);
    try {
      await API.graphql(graphqlOperation(updateUser, { input: data }));
      setMessage('Profile has been updated successfully');
      setType('success');
      setOpen(true);
      setTimeout(() => {
        history.goBack();
      }, 3000);
    } catch (err) {
      setOpen(true);
      setMessage('Please contact admin user');
      setType('error');
    }
  };

  if (!userProfile) return <LoadingBar />;

  return (
    <Grid container>
      <Message
        open={openMessage}
        type={type}
        handleClose={() => setOpen(false)}
        message={message}
      />
      <Grid container>
        <Typography component="h2" variant="h6" color="primary" gutterBottom>
          {userProfile.first_name} {userProfile.last_name}
        </Typography>
      </Grid>
      <Grid container>
        <form
          onSubmit={handleSubmit(onSubmit)}
          noValidate
          className={classes.form}
          autoComplete="off">
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <FormGroup>
                <TextField
                  label="User Status"
                  name="is_active"
                  type="number"
                  autoComplete="off"
                  margin="normal"
                  error={!!fieldsErrors.is_active}
                  inputRef={register({
                    required: 'User Status is Required',
                  })}
                  helperText={
                    fieldsErrors.is_active && fieldsErrors.is_active.message
                  }
                  fullWidth
                />
              </FormGroup>
            </Grid>
            <Grid item xs={12} md={6}>
              <FormGroup>
                <TextField
                  label="Affiliate Link"
                  name="affiliate_link"
                  type="text"
                  margin="normal"
                  error={!!fieldsErrors.affiliate_link}
                  inputRef={register({
                    required: 'Affiliate Link is Required',
                  })}
                  helperText={
                    fieldsErrors.affiliate_link &&
                    fieldsErrors.affiliate_link.message
                  }
                  fullWidth
                />
              </FormGroup>
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <FormGroup>
                <TextField
                  label="Referral Source "
                  name="affiliated_by"
                  type="text"
                  margin="normal"
                  error={!!fieldsErrors.affiliated_by}
                  fullWidth
                  helperText={
                    fieldsErrors.affiliated_by &&
                    fieldsErrors.affiliated_by.message
                  }
                />
              </FormGroup>
            </Grid>
            <Grid item xs={12} md={6}>
              <FormGroup>
                <TextField
                  label="Premium Level"
                  name="premium_account_lvl"
                  type="number"
                  margin="normal"
                  error={!!fieldsErrors.premium_account_lvl}
                  fullWidth
                  inputRef={register({
                    required: 'Premium Level is Required',
                  })}
                  helperText={
                    fieldsErrors.premium_account_lvl &&
                    fieldsErrors.premium_account_lvl.message
                  }
                />
              </FormGroup>
            </Grid>
          </Grid>
          <Grid container justify="space-between" className={classes.bottom}>
            <Button
              variant="outlined"
              type="button"
              onClick={() => history.goBack()}>
              Cancel
            </Button>
            <Button variant="contained" color="primary" type="submit">
              Save Changes
            </Button>
          </Grid>
        </form>
      </Grid>
      {allVideos.length > 0 && (
        <>
          <Grid container className={classes.container}>
            <Typography component="h2" variant="h6" color="primary">
              Videos
            </Typography>
          </Grid>
          <Grid container className={classes.container}>
            <CustomTab labels={LABELS} onSelectLabel={onSelectLabel}>
              <CustomTable
                headers={headers}
                rows={rows}
                page={page}
                rowsPerPage={rowsPerPage}
                handleChangePage={handleChangePage}
                handleChangeRowsPerPage={handleChangeRowsPerPage}
                handleRequestSort={handleRequestSort}
                order={order}
                orderBy={orderBy}
              />
            </CustomTab>
          </Grid>
        </>
      )}
      <VideoDialog
        open={open}
        videoUrl={videoUrl}
        handleClose={() => setOpenModal(false)}
      />
    </Grid>
  );
}

export default Profile;
