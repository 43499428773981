import { handleActions } from 'redux-actions';
import { authActions } from '../actions';

const defaultState = {
  user: null,
  isAuthenticated: false,
};

const reducer = handleActions(
  {
    [authActions.setUserInfo]: (state, { payload }) => {
      return { ...state, user: payload, isAuthenticated: true };
    },
    [authActions.signOut]: (state) => {
      return { ...state, user: null, isAuthenticated: false };
    },
  },
  defaultState
);

export default reducer;
