import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { Auth } from 'aws-amplify';
import {
  Container,
  FormGroup,
  InputAdornment,
  IconButton,
  Grid,
  Button,
  makeStyles,
  Typography,
  TextField,
} from '@material-ui/core';
import { Visibility, VisibilityOff } from '@material-ui/icons';

import LoadinBar from '../../components/Loading';
import Message from '../../components/Message';
import { authActions } from '../../actions';
import Logo from '../../assets/icons/logos/logo.svg';

const useStyles = makeStyles((theme) => ({
  container: {
    padding: theme.spacing(3),
  },
  loginForm: {
    minHeight: '100vh',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  button: {
    width: 100,
    margin: theme.spacing(1),
  },
  title: {
    fontSize: 40,
    paddingTop: 30,
    paddingBottom: 30,
    color: '#3c3f46',
    textAlign: 'center',
  },
  description: {
    fontSize: 18,
    color: '#666d77',
    textAlign: 'center',
    marginBottom: 40,
  },
  bottom: {
    marginTop: 20,
  },
  logo: {
    maxWidth: '100%',
  },
  buttonWrapper: {
    [theme.breakpoints.down('xs')]: {
      justifyContent: 'space-between',
    },
  },
  loading: {
    textAlign: 'center',
  },
}));

function Login({ onSubmitHandle }) {
  const classes = useStyles();
  const history = useHistory();
  const [showPassword, setShowPassword] = useState(false);
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState('');
  const [disabled, setDisabled] = useState(false);
  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();
  const { register, handleSubmit, errors: fieldsErrors } = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {
      email: '',
      password: '',
    },
  });

  const onSubmit = (data) => {
    setLoading(true);
    const user = {
      username: data.email,
      password: data.password,
    };
    setDisabled(true);
    Auth.signIn(user)
      .then((response) => {
        setLoading(false);
        dispatch(
          authActions.setUserInfo({
            name: 'Cazvid',
            lastname: 'Admin',
            email: data.email,
          })
        );
        history.push('/dashboard');
      })
      .catch((err) => {
        setLoading(false);
        setOpen(true);
        setMessage(err.message);
        setDisabled(false);
      });
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleRegister = () => history.push('/register');

  const handleClose = () => setOpen(false);

  return (
    <div className={classes.loginForm}>
      <Message
        open={open}
        type="error"
        handleClose={handleClose}
        message={message}
      />
      <Container className={classes.container} maxWidth="sm">
        <Grid container justify="center">
          <img src={Logo} alt="logo" className={classes.logo} />
          <Grid item sm={12}>
            <Typography className={classes.title}>Admin Login</Typography>
            <Typography className={classes.description}>
              Enter your sign in details below to access your CazvidAdmin
              account.
            </Typography>
          </Grid>
        </Grid>
        <form onSubmit={handleSubmit(onSubmitHandle || onSubmit)}>
          <FormGroup>
            <TextField
              label="Email address"
              type="email"
              name="email"
              autoComplete="off"
              margin="normal"
              variant="outlined"
              error={!!fieldsErrors.email}
              inputRef={register({
                required: 'Email is Required',
                pattern: {
                  value: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                  message: 'You must provide a valid email address!',
                },
              })}
              helperText={fieldsErrors.email && fieldsErrors.email.message}
              fullWidth
            />
          </FormGroup>
          <FormGroup>
            <TextField
              name="password"
              label="Password"
              type={showPassword ? 'text' : 'password'}
              variant="outlined"
              margin="normal"
              error={!!fieldsErrors.password}
              inputRef={register({
                required: 'You must provide a password.',
              })}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}>
                      {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              fullWidth
              autoComplete="current-password"
              helperText={
                fieldsErrors.password && fieldsErrors.password.message
              }
            />
          </FormGroup>
          <Grid
            container
            alignItems="center"
            justify="space-between"
            className={classes.bottom}>
            <Grid item xs={12} sm={6}>
              <Link to="/forgot-password">Forgot password?</Link>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Grid
                container
                justify="flex-end"
                className={classes.buttonWrapper}>
                <Button
                  variant="contained"
                  color="primary"
                  type="submit"
                  className={classes.button}
                  disabled={disabled}>
                  Login
                </Button>
                {/* <Button
                  variant="contained"
                  color="primary"
                  type="button"
                  className={classes.button}
                  onClick={handleRegister}>
                  Register
                </Button> */}
              </Grid>
            </Grid>
          </Grid>
        </form>
      </Container>
      {loading && <LoadinBar />}
    </div>
  );
}

export default Login;
