export const listReportPosts = /* GraphQL */ `
  query ListPosts(
    $filter: ModelPostFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPosts(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        is_approved
        post_type
        createdAt
        author {
          affiliated_by
        }
      }
      nextToken
    }
  }
`;