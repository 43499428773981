import React from 'react';
import { TextField, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  input: {
    width: '100%',
    maxWidth: 500,
    marginBottom: 30,
  },
}));

const SearchInput = ({ label, type, name, ...props }) => {
  const classes = useStyles();
  return (
    <Grid container className={classes.input}>
      <TextField
        {...props}
        label={label}
        type={type}
        name={name}
        margin="normal"
        placeholder="search..."
        fullWidth
      />
    </Grid>
  );
};

export default SearchInput;
