import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { Paper, Typography } from '@material-ui/core';
import { API, graphqlOperation } from 'aws-amplify';
import moment from 'moment';
import { S3Image } from 'aws-amplify-react';

import { listUsers } from '../../graphql/queries';
import { updateUser } from '../../graphql/mutations';
import Title from '../../components/Title';
import SearchInput from '../../components/SearchInput';
import CustomTable from '../../components/Table';
import LoadingBar from '../../components/Loading';
import { userActions } from '../../actions';

import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';

const useStyles = makeStyles((theme) => ({
  seeMore: {
    marginTop: theme.spacing(3),
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
}));

const headers = [
  {
    id: 'createdAt',
    label: 'Date',
    minWidth: 190,
  },
  { id: 'first_name', label: 'User', minWidth: 170 },
  { id: 'is_approved', label: 'Approved Post', minWidth: 170 },
  { id: 'user_avatar', label: 'Profile Picture', minWidth: 170, align: 'center' },
  {
    id: 'email',
    label: 'Email',
    minWidth: 170,
  },
  {
    id: 'phone_number',
    label: 'Phone',
    minWidth: 170,
  },
  {
    id: 'affiliated_by',
    label: 'Referred By (Manual)',
    minWidth: 170,
  },
  {
    id: 'location',
    label: 'Location',
    minWidth: 170,
  },
  {
    id: 'language',
    label: 'Language',
    minWidth: 170,
  },
  {
    id: 'device_info',
    label: 'Device Type',
    minWidth: 170
  },
  {
    id: 'user_id',
    label: 'ID #',
    minWidth: 170,
  },
  {
    id: 'status',
    label: 'Status',
    minWidth: 120,
    align: 'center',
  },
  {
    id: 'type',
    label: 'Type',
    minWidth: 170,
  },
];

function createData(
  first_name,
  email,
  phone_number,
  affiliated_by,
  createdAt,
  device_info,
  user_id,
  status,
  user_avatar,
  is_approved,
  language,
  location,
  type
) {
  return {
    first_name,
    email,
    phone_number,
    affiliated_by,
    createdAt,
    device_info,
    user_id,
    status,
    user_avatar,
    is_approved,
    language,
    location,
    type
  };
}

export default function Users() {
  const classes = useStyles();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [order, setOrder] = useState('desc');
  const [orderBy, setOrderBy] = useState('createdAt');
  const [rows, setRows] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const history = useHistory();
  const [userList, setUserList] = useState();

  useEffect(() => {
    setLoading(true);
    getListUsers();
  }, []);

  useEffect(() => {
    userList && generateRows(userList);
  }, [userList]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(event.target.value);
    setPage(0);
  };

  const handleRequestSort = (event, property) => {
    if (property === 'status') return;
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const showProfileDetail = (row) => {
    history.push(`/dashboard/user/profile/${row.user_id}`);
  };

  const getListUsers = async () => {
    try {
      const { data } = await API.graphql(
        graphqlOperation(listUsers, {
          user_id: undefined,
          filter: undefined,
          limit: 1500
        })
      );
      console.log('data', data);
      setUserList(data.listUsers.items);
      getMoreUsers(data.listUsers.nextToken, data.listUsers.items);
    } catch (error) {
      console.log('error on fetching users', error);
      setLoading(false);
    }
  };

  const getMoreUsers = async (nextToken, list) => {
    console.log('get more users');
    setLoading(true);
    try {
      const { data } = await API.graphql(
        graphqlOperation(listUsers, {
          user_id: undefined,
          filter: undefined,
          limit: 1500,
          nextToken: nextToken
        })
      );
      const newList = data.listUsers.items;
      newList.push(...list);
      setUserList(newList);
      data.listUsers.nextToken && getMoreUsers(data.listUsers.nextToken, newList);
      !data.listUsers.nextToken && setLoading(false);
    } catch (error) {
      console.log('error on fetching users', error);
      setLoading(false);
    }
  };

  const generateRows = (userList) => {
    const rows = [];
    userList.forEach((v) => {
      rows.push(
        createData(
          v?.first_name,
          v?.email,
          v?.phone_number,
          v?.affiliated_by,
          v?.createdAt?.replace('T', ' '),
          v?.deviceInfo,
          v?.user_id,
          <>
            {v?.is_active === 1 ? (
              <Typography color="primary">Active</Typography>
            ) : (
              <Typography color="secondary">Deactive</Typography>
            )}
          </>,
          <>
            {v?.user_avatar?.substring(0,5) != 'https' ? (
              <S3Image
                level="public"
                imgKey={v?.user_avatar}
                theme={{ photoImg: { width: '80px', height: '80px', borderRadius: 100 } }}
              />
            ) : (
              <>
              <img src={v?.user_avatar} referrerpolicy="no-referrer" width="72" height="72" />
              </>
            )
            }
          </>,
          <>
            {v?.posts?.items.map((a => a.is_approved)).includes(1) ? 1 : 0}
          </>,
          // <Select
          //   labelId="language"
          //   id="language-select"
          //   value={v?.language}
          //   onChange={(event) => handleChangeLanguage(event, v)}
          // >
          //   <MenuItem value={'en'}>English</MenuItem>
          //   <MenuItem value={'es'}>Spanish</MenuItem>
          //   <MenuItem value={'multiple'}>Multiple</MenuItem>
          // </Select>,
          v?.language,
          v?.location,
          v?.type,
        )
      );
    });
    setRows(rows);
  };

  const handleSearch = (e) => {
    const filtedList = userList.filter(
      (v) =>
        v.first_name?.toLowerCase().includes(e.target.value.toLowerCase()) ||
        v.email?.toLowerCase().includes(e.target.value.toLowerCase()) ||
        v.phone_number?.toLowerCase().includes(e.target.value.toLowerCase()) ||
        v.affiliated_by?.toLowerCase().includes(e.target.value.toLowerCase()) ||
        v.user_id?.toLowerCase().includes(e.target.value.toLowerCase())
    );
    generateRows(filtedList);
  };

  const handleChangeLanguage = (event, user) => {
    const updatedData = {
      id: user.id,
      language: event.target.value,
    };
    updateUser(updatedData);
  };

  const updateUser = async (updatedData) => {
    setLoading(true);
    try {
      const { data } = await API.graphql(
        graphqlOperation(updateUser, { input: updatedData })
      );
      console.log('update user data', data);
      getListUsers();
    } catch (err) {
      console.log('update user error', err)
      setLoading(false);
    }
  };

  return (
    <>
      <Paper className={classes.paper}>
        <Title>All users</Title>
        <SearchInput onChange={handleSearch} />
        <CustomTable
          headers={headers}
          rows={rows}
          page={page}
          rowsPerPage={rowsPerPage}
          handleChangePage={handleChangePage}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
          handleRequestSort={handleRequestSort}
          order={order}
          orderBy={orderBy}
          // onRowClick={showProfileDetail}
          // key="user_id"
        />
      </Paper>
      {isLoading && <LoadingBar />}
    </>
  );
}
