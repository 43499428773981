/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createUser = /* GraphQL */ `
  mutation CreateUser(
    $input: CreateUserInput!
    $condition: ModelUserConditionInput
  ) {
    createUser(input: $input, condition: $condition) {
      user_id
      is_active
      username
      first_name
      last_name
      phone_number
      email
      postal_code
      user_avatar
      default_signature
      profile_info
      affiliate_link
      affiliated_by
      url
      email_alerts
      email_alerts_contact
      email_alerts_follow
      sms_alerts
      sms_alerts_contact
      sms_alerts_follow
      push_alerts
      push_alerts_contact
      push_alerts_follow
      hide_account
      premium_account
      premium_account_lvl
      last_active
      following {
        items {
          id
          follower_id
          following_id
          createdAt
          updatedAt
          follower {
            user_id
            is_active
            username
            first_name
            last_name
            phone_number
            email
            postal_code
            default_signature
            profile_info
            affiliate_link
            affiliated_by
            url
            email_alerts
            email_alerts_contact
            email_alerts_follow
            sms_alerts
            sms_alerts_contact
            sms_alerts_follow
            push_alerts
            push_alerts_contact
            push_alerts_follow
            hide_account
            premium_account
            premium_account_lvl
            last_active
            updated_by
            createdAt
            updatedAt
          }
          following {
            user_id
            is_active
            username
            first_name
            last_name
            phone_number
            email
            postal_code
            default_signature
            profile_info
            affiliate_link
            affiliated_by
            url
            email_alerts
            email_alerts_contact
            email_alerts_follow
            sms_alerts
            sms_alerts_contact
            sms_alerts_follow
            push_alerts
            push_alerts_contact
            push_alerts_follow
            hide_account
            premium_account
            premium_account_lvl
            last_active
            updated_by
            createdAt
            updatedAt
          }
        }
        nextToken
      }
      blocked {
        items {
          id
          blocker_id
          blocked_id
          createdAt
          updatedAt
          blocker {
            user_id
            is_active
            username
            first_name
            last_name
            phone_number
            email
            postal_code
            default_signature
            profile_info
            affiliate_link
            affiliated_by
            url
            email_alerts
            email_alerts_contact
            email_alerts_follow
            sms_alerts
            sms_alerts_contact
            sms_alerts_follow
            push_alerts
            push_alerts_contact
            push_alerts_follow
            hide_account
            premium_account
            premium_account_lvl
            last_active
            updated_by
            createdAt
            updatedAt
          }
          blocked {
            user_id
            is_active
            username
            first_name
            last_name
            phone_number
            email
            postal_code
            default_signature
            profile_info
            affiliate_link
            affiliated_by
            url
            email_alerts
            email_alerts_contact
            email_alerts_follow
            sms_alerts
            sms_alerts_contact
            sms_alerts_follow
            push_alerts
            push_alerts_contact
            push_alerts_follow
            hide_account
            premium_account
            premium_account_lvl
            last_active
            updated_by
            createdAt
            updatedAt
          }
        }
        nextToken
      }
      hidden {
        items {
          id
          reporter_id
          hidden_id
          createdAt
          updatedAt
          reporter {
            user_id
            is_active
            username
            first_name
            last_name
            phone_number
            email
            postal_code
            default_signature
            profile_info
            affiliate_link
            affiliated_by
            url
            email_alerts
            email_alerts_contact
            email_alerts_follow
            sms_alerts
            sms_alerts_contact
            sms_alerts_follow
            push_alerts
            push_alerts_contact
            push_alerts_follow
            hide_account
            premium_account
            premium_account_lvl
            last_active
            updated_by
            createdAt
            updatedAt
          }
          hidden {
            id
            post_id
            is_active
            is_approved
            is_flagged
            author_id
            content
            post_video
            post_thumbnail
            post_type
            lat_lon
            loc_name
            updated_by
            createdAt
            updatedAt
          }
        }
        nextToken
      }
      favorites {
        items {
          id
          post_id
          user_id
          created_at
          createdAt
          updatedAt
          user {
            user_id
            is_active
            username
            first_name
            last_name
            phone_number
            email
            postal_code
            default_signature
            profile_info
            affiliate_link
            affiliated_by
            url
            email_alerts
            email_alerts_contact
            email_alerts_follow
            sms_alerts
            sms_alerts_contact
            sms_alerts_follow
            push_alerts
            push_alerts_contact
            push_alerts_follow
            hide_account
            premium_account
            premium_account_lvl
            last_active
            updated_by
            createdAt
            updatedAt
          }
          post {
            id
            post_id
            is_active
            is_approved
            is_flagged
            author_id
            content
            post_video
            post_thumbnail
            post_type
            lat_lon
            loc_name
            updated_by
            createdAt
            updatedAt
          }
        }
        nextToken
      }
      updated_by
      createdAt
      updatedAt
      posts {
        items {
          id
          post_id
          is_active
          is_approved
          is_flagged
          author_id
          content
          post_video
          post_thumbnail
          post_type
          lat_lon
          loc_name
          categories {
            nextToken
          }
          likes {
            nextToken
          }
          favorites {
            nextToken
          }
          updated_by
          createdAt
          updatedAt
          author {
            user_id
            is_active
            username
            first_name
            last_name
            phone_number
            email
            postal_code
            default_signature
            profile_info
            affiliate_link
            affiliated_by
            url
            email_alerts
            email_alerts_contact
            email_alerts_follow
            sms_alerts
            sms_alerts_contact
            sms_alerts_follow
            push_alerts
            push_alerts_contact
            push_alerts_follow
            hide_account
            premium_account
            premium_account_lvl
            last_active
            updated_by
            createdAt
            updatedAt
          }
        }
        nextToken
      }
    }
  }
`;
export const updateUser = /* GraphQL */ `
  mutation UpdateUser(
    $input: UpdateUserInput!
    $condition: ModelUserConditionInput
  ) {
    updateUser(input: $input, condition: $condition) {
      user_id
      is_active
      username
      first_name
      last_name
      phone_number
      email
      postal_code
      user_avatar
      default_signature
      profile_info
      affiliate_link
      affiliated_by
      url
      email_alerts
      email_alerts_contact
      email_alerts_follow
      sms_alerts
      sms_alerts_contact
      sms_alerts_follow
      push_alerts
      push_alerts_contact
      push_alerts_follow
      hide_account
      premium_account
      premium_account_lvl
      last_active
      language
      type
      following {
        items {
          id
          follower_id
          following_id
          createdAt
          updatedAt
          follower {
            user_id
            is_active
            username
            first_name
            last_name
            phone_number
            email
            postal_code
            default_signature
            profile_info
            affiliate_link
            affiliated_by
            url
            email_alerts
            email_alerts_contact
            email_alerts_follow
            sms_alerts
            sms_alerts_contact
            sms_alerts_follow
            push_alerts
            push_alerts_contact
            push_alerts_follow
            hide_account
            premium_account
            premium_account_lvl
            last_active
            updated_by
            createdAt
            updatedAt
          }
          following {
            user_id
            is_active
            username
            first_name
            last_name
            phone_number
            email
            postal_code
            default_signature
            profile_info
            affiliate_link
            affiliated_by
            url
            email_alerts
            email_alerts_contact
            email_alerts_follow
            sms_alerts
            sms_alerts_contact
            sms_alerts_follow
            push_alerts
            push_alerts_contact
            push_alerts_follow
            hide_account
            premium_account
            premium_account_lvl
            last_active
            updated_by
            createdAt
            updatedAt
          }
        }
        nextToken
      }
      blocked {
        items {
          id
          blocker_id
          blocked_id
          createdAt
          updatedAt
          blocker {
            user_id
            is_active
            username
            first_name
            last_name
            phone_number
            email
            postal_code
            default_signature
            profile_info
            affiliate_link
            affiliated_by
            url
            email_alerts
            email_alerts_contact
            email_alerts_follow
            sms_alerts
            sms_alerts_contact
            sms_alerts_follow
            push_alerts
            push_alerts_contact
            push_alerts_follow
            hide_account
            premium_account
            premium_account_lvl
            last_active
            updated_by
            createdAt
            updatedAt
          }
          blocked {
            user_id
            is_active
            username
            first_name
            last_name
            phone_number
            email
            postal_code
            default_signature
            profile_info
            affiliate_link
            affiliated_by
            url
            email_alerts
            email_alerts_contact
            email_alerts_follow
            sms_alerts
            sms_alerts_contact
            sms_alerts_follow
            push_alerts
            push_alerts_contact
            push_alerts_follow
            hide_account
            premium_account
            premium_account_lvl
            last_active
            updated_by
            createdAt
            updatedAt
          }
        }
        nextToken
      }
      hidden {
        items {
          id
          reporter_id
          hidden_id
          createdAt
          updatedAt
          reporter {
            user_id
            is_active
            username
            first_name
            last_name
            phone_number
            email
            postal_code
            default_signature
            profile_info
            affiliate_link
            affiliated_by
            url
            email_alerts
            email_alerts_contact
            email_alerts_follow
            sms_alerts
            sms_alerts_contact
            sms_alerts_follow
            push_alerts
            push_alerts_contact
            push_alerts_follow
            hide_account
            premium_account
            premium_account_lvl
            last_active
            updated_by
            createdAt
            updatedAt
          }
          hidden {
            id
            post_id
            is_active
            is_approved
            is_flagged
            author_id
            content
            post_video
            post_thumbnail
            post_type
            lat_lon
            loc_name
            updated_by
            createdAt
            updatedAt
          }
        }
        nextToken
      }
      favorites {
        items {
          id
          post_id
          user_id
          created_at
          createdAt
          updatedAt
          user {
            user_id
            is_active
            username
            first_name
            last_name
            phone_number
            email
            postal_code
            default_signature
            profile_info
            affiliate_link
            affiliated_by
            url
            email_alerts
            email_alerts_contact
            email_alerts_follow
            sms_alerts
            sms_alerts_contact
            sms_alerts_follow
            push_alerts
            push_alerts_contact
            push_alerts_follow
            hide_account
            premium_account
            premium_account_lvl
            last_active
            updated_by
            createdAt
            updatedAt
          }
          post {
            id
            post_id
            is_active
            is_approved
            is_flagged
            author_id
            content
            post_video
            post_thumbnail
            post_type
            lat_lon
            loc_name
            updated_by
            createdAt
            updatedAt
          }
        }
        nextToken
      }
      updated_by
      createdAt
      updatedAt
      posts {
        items {
          id
          post_id
          is_active
          is_approved
          is_flagged
          author_id
          content
          post_video
          post_thumbnail
          post_type
          lat_lon
          loc_name
          categories {
            nextToken
          }
          likes {
            nextToken
          }
          favorites {
            nextToken
          }
          updated_by
          createdAt
          updatedAt
          author {
            user_id
            is_active
            username
            first_name
            last_name
            phone_number
            email
            postal_code
            default_signature
            profile_info
            affiliate_link
            affiliated_by
            url
            email_alerts
            email_alerts_contact
            email_alerts_follow
            sms_alerts
            sms_alerts_contact
            sms_alerts_follow
            push_alerts
            push_alerts_contact
            push_alerts_follow
            hide_account
            premium_account
            premium_account_lvl
            last_active
            updated_by
            createdAt
            updatedAt
          }
        }
        nextToken
      }
    }
  }
`;
export const deleteUser = /* GraphQL */ `
  mutation DeleteUser(
    $input: DeleteUserInput!
    $condition: ModelUserConditionInput
  ) {
    deleteUser(input: $input, condition: $condition) {
      user_id
      is_active
      username
      first_name
      last_name
      phone_number
      email
      postal_code
      user_avatar
      default_signature
      profile_info
      affiliate_link
      affiliated_by
      url
      email_alerts
      email_alerts_contact
      email_alerts_follow
      sms_alerts
      sms_alerts_contact
      sms_alerts_follow
      push_alerts
      push_alerts_contact
      push_alerts_follow
      hide_account
      premium_account
      premium_account_lvl
      last_active
      following {
        items {
          id
          follower_id
          following_id
          createdAt
          updatedAt
          follower {
            user_id
            is_active
            username
            first_name
            last_name
            phone_number
            email
            postal_code
            default_signature
            profile_info
            affiliate_link
            affiliated_by
            url
            email_alerts
            email_alerts_contact
            email_alerts_follow
            sms_alerts
            sms_alerts_contact
            sms_alerts_follow
            push_alerts
            push_alerts_contact
            push_alerts_follow
            hide_account
            premium_account
            premium_account_lvl
            last_active
            updated_by
            createdAt
            updatedAt
          }
          following {
            user_id
            is_active
            username
            first_name
            last_name
            phone_number
            email
            postal_code
            default_signature
            profile_info
            affiliate_link
            affiliated_by
            url
            email_alerts
            email_alerts_contact
            email_alerts_follow
            sms_alerts
            sms_alerts_contact
            sms_alerts_follow
            push_alerts
            push_alerts_contact
            push_alerts_follow
            hide_account
            premium_account
            premium_account_lvl
            last_active
            updated_by
            createdAt
            updatedAt
          }
        }
        nextToken
      }
      blocked {
        items {
          id
          blocker_id
          blocked_id
          createdAt
          updatedAt
          blocker {
            user_id
            is_active
            username
            first_name
            last_name
            phone_number
            email
            postal_code
            default_signature
            profile_info
            affiliate_link
            affiliated_by
            url
            email_alerts
            email_alerts_contact
            email_alerts_follow
            sms_alerts
            sms_alerts_contact
            sms_alerts_follow
            push_alerts
            push_alerts_contact
            push_alerts_follow
            hide_account
            premium_account
            premium_account_lvl
            last_active
            updated_by
            createdAt
            updatedAt
          }
          blocked {
            user_id
            is_active
            username
            first_name
            last_name
            phone_number
            email
            postal_code
            default_signature
            profile_info
            affiliate_link
            affiliated_by
            url
            email_alerts
            email_alerts_contact
            email_alerts_follow
            sms_alerts
            sms_alerts_contact
            sms_alerts_follow
            push_alerts
            push_alerts_contact
            push_alerts_follow
            hide_account
            premium_account
            premium_account_lvl
            last_active
            updated_by
            createdAt
            updatedAt
          }
        }
        nextToken
      }
      hidden {
        items {
          id
          reporter_id
          hidden_id
          createdAt
          updatedAt
          reporter {
            user_id
            is_active
            username
            first_name
            last_name
            phone_number
            email
            postal_code
            default_signature
            profile_info
            affiliate_link
            affiliated_by
            url
            email_alerts
            email_alerts_contact
            email_alerts_follow
            sms_alerts
            sms_alerts_contact
            sms_alerts_follow
            push_alerts
            push_alerts_contact
            push_alerts_follow
            hide_account
            premium_account
            premium_account_lvl
            last_active
            updated_by
            createdAt
            updatedAt
          }
          hidden {
            id
            post_id
            is_active
            is_approved
            is_flagged
            author_id
            content
            post_video
            post_thumbnail
            post_type
            lat_lon
            loc_name
            updated_by
            createdAt
            updatedAt
          }
        }
        nextToken
      }
      favorites {
        items {
          id
          post_id
          user_id
          created_at
          createdAt
          updatedAt
          user {
            user_id
            is_active
            username
            first_name
            last_name
            phone_number
            email
            postal_code
            default_signature
            profile_info
            affiliate_link
            affiliated_by
            url
            email_alerts
            email_alerts_contact
            email_alerts_follow
            sms_alerts
            sms_alerts_contact
            sms_alerts_follow
            push_alerts
            push_alerts_contact
            push_alerts_follow
            hide_account
            premium_account
            premium_account_lvl
            last_active
            updated_by
            createdAt
            updatedAt
          }
          post {
            id
            post_id
            is_active
            is_approved
            is_flagged
            author_id
            content
            post_video
            post_thumbnail
            post_type
            lat_lon
            loc_name
            updated_by
            createdAt
            updatedAt
          }
        }
        nextToken
      }
      updated_by
      createdAt
      updatedAt
      posts {
        items {
          id
          post_id
          is_active
          is_approved
          is_flagged
          author_id
          content
          post_video
          post_thumbnail
          post_type
          lat_lon
          loc_name
          categories {
            nextToken
          }
          likes {
            nextToken
          }
          favorites {
            nextToken
          }
          updated_by
          createdAt
          updatedAt
          author {
            user_id
            is_active
            username
            first_name
            last_name
            phone_number
            email
            postal_code
            default_signature
            profile_info
            affiliate_link
            affiliated_by
            url
            email_alerts
            email_alerts_contact
            email_alerts_follow
            sms_alerts
            sms_alerts_contact
            sms_alerts_follow
            push_alerts
            push_alerts_contact
            push_alerts_follow
            hide_account
            premium_account
            premium_account_lvl
            last_active
            updated_by
            createdAt
            updatedAt
          }
        }
        nextToken
      }
    }
  }
`;
export const createPost = /* GraphQL */ `
  mutation CreatePost(
    $input: CreatePostInput!
    $condition: ModelPostConditionInput
  ) {
    createPost(input: $input, condition: $condition) {
      id
      post_id
      is_active
      is_approved
      is_flagged
      author_id
      content
      post_video
      post_thumbnail
      post_type
      lat_lon
      loc_name
      categories {
        items {
          id
          post_id
          categoryID
          category {
            id
            category_Id
            title
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
          post {
            id
            post_id
            is_active
            is_approved
            is_flagged
            author_id
            content
            post_video
            post_thumbnail
            post_type
            lat_lon
            loc_name
            updated_by
            createdAt
            updatedAt
          }
        }
        nextToken
      }
      likes {
        items {
          id
          post_id
          user_id
          created_at
          createdAt
          updatedAt
          user {
            user_id
            is_active
            username
            first_name
            last_name
            phone_number
            email
            postal_code
            default_signature
            profile_info
            affiliate_link
            affiliated_by
            url
            email_alerts
            email_alerts_contact
            email_alerts_follow
            sms_alerts
            sms_alerts_contact
            sms_alerts_follow
            push_alerts
            push_alerts_contact
            push_alerts_follow
            hide_account
            premium_account
            premium_account_lvl
            last_active
            updated_by
            createdAt
            updatedAt
          }
          post {
            id
            post_id
            is_active
            is_approved
            is_flagged
            author_id
            content
            post_video
            post_thumbnail
            post_type
            lat_lon
            loc_name
            updated_by
            createdAt
            updatedAt
          }
        }
        nextToken
      }
      favorites {
        items {
          id
          post_id
          user_id
          created_at
          createdAt
          updatedAt
          user {
            user_id
            is_active
            username
            first_name
            last_name
            phone_number
            email
            postal_code
            default_signature
            profile_info
            affiliate_link
            affiliated_by
            url
            email_alerts
            email_alerts_contact
            email_alerts_follow
            sms_alerts
            sms_alerts_contact
            sms_alerts_follow
            push_alerts
            push_alerts_contact
            push_alerts_follow
            hide_account
            premium_account
            premium_account_lvl
            last_active
            updated_by
            createdAt
            updatedAt
          }
          post {
            id
            post_id
            is_active
            is_approved
            is_flagged
            author_id
            content
            post_video
            post_thumbnail
            post_type
            lat_lon
            loc_name
            updated_by
            createdAt
            updatedAt
          }
        }
        nextToken
      }
      updated_by
      createdAt
      updatedAt
      author {
        user_id
        is_active
        username
        first_name
        last_name
        phone_number
        email
        postal_code
        user_avatar
        default_signature
        profile_info
        affiliate_link
        affiliated_by
        url
        email_alerts
        email_alerts_contact
        email_alerts_follow
        sms_alerts
        sms_alerts_contact
        sms_alerts_follow
        push_alerts
        push_alerts_contact
        push_alerts_follow
        hide_account
        premium_account
        premium_account_lvl
        last_active
        following {
          items {
            id
            follower_id
            following_id
            createdAt
            updatedAt
          }
          nextToken
        }
        blocked {
          items {
            id
            blocker_id
            blocked_id
            createdAt
            updatedAt
          }
          nextToken
        }
        hidden {
          items {
            id
            reporter_id
            hidden_id
            createdAt
            updatedAt
          }
          nextToken
        }
        favorites {
          items {
            id
            post_id
            user_id
            created_at
            createdAt
            updatedAt
          }
          nextToken
        }
        updated_by
        createdAt
        updatedAt
        posts {
          items {
            id
            post_id
            is_active
            is_approved
            is_flagged
            author_id
            content
            post_video
            post_thumbnail
            post_type
            lat_lon
            loc_name
            updated_by
            createdAt
            updatedAt
          }
          nextToken
        }
      }
    }
  }
`;
export const updatePost = /* GraphQL */ `
  mutation UpdatePost(
    $input: UpdatePostInput!
    $condition: ModelPostConditionInput
  ) {
    updatePost(input: $input, condition: $condition) {
      id
      post_id
      is_active
      is_approved
      is_flagged
      author_id
      content
      post_video
      post_thumbnail
      post_type
      lat_lon
      loc_name
      language
      categories {
        items {
          id
          post_id
          categoryID
          category {
            id
            category_Id
            title
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
          post {
            id
            post_id
            is_active
            is_approved
            is_flagged
            author_id
            content
            post_video
            post_thumbnail
            post_type
            lat_lon
            loc_name
            updated_by
            createdAt
            updatedAt
          }
        }
        nextToken
      }
      likes {
        items {
          id
          post_id
          user_id
          created_at
          createdAt
          updatedAt
          user {
            user_id
            is_active
            username
            first_name
            last_name
            phone_number
            email
            postal_code
            default_signature
            profile_info
            affiliate_link
            affiliated_by
            url
            email_alerts
            email_alerts_contact
            email_alerts_follow
            sms_alerts
            sms_alerts_contact
            sms_alerts_follow
            push_alerts
            push_alerts_contact
            push_alerts_follow
            hide_account
            premium_account
            premium_account_lvl
            last_active
            updated_by
            createdAt
            updatedAt
          }
          post {
            id
            post_id
            is_active
            is_approved
            is_flagged
            author_id
            content
            post_video
            post_thumbnail
            post_type
            lat_lon
            loc_name
            updated_by
            createdAt
            updatedAt
          }
        }
        nextToken
      }
      favorites {
        items {
          id
          post_id
          user_id
          created_at
          createdAt
          updatedAt
          user {
            user_id
            is_active
            username
            first_name
            last_name
            phone_number
            email
            postal_code
            default_signature
            profile_info
            affiliate_link
            affiliated_by
            url
            email_alerts
            email_alerts_contact
            email_alerts_follow
            sms_alerts
            sms_alerts_contact
            sms_alerts_follow
            push_alerts
            push_alerts_contact
            push_alerts_follow
            hide_account
            premium_account
            premium_account_lvl
            last_active
            updated_by
            createdAt
            updatedAt
          }
          post {
            id
            post_id
            is_active
            is_approved
            is_flagged
            author_id
            content
            post_video
            post_thumbnail
            post_type
            lat_lon
            loc_name
            updated_by
            createdAt
            updatedAt
          }
        }
        nextToken
      }
      updated_by
      createdAt
      updatedAt
      author {
        user_id
        is_active
        username
        first_name
        last_name
        phone_number
        email
        postal_code
        user_avatar
        default_signature
        profile_info
        affiliate_link
        affiliated_by
        url
        email_alerts
        email_alerts_contact
        email_alerts_follow
        sms_alerts
        sms_alerts_contact
        sms_alerts_follow
        push_alerts
        push_alerts_contact
        push_alerts_follow
        hide_account
        premium_account
        premium_account_lvl
        last_active
        following {
          items {
            id
            follower_id
            following_id
            createdAt
            updatedAt
          }
          nextToken
        }
        blocked {
          items {
            id
            blocker_id
            blocked_id
            createdAt
            updatedAt
          }
          nextToken
        }
        hidden {
          items {
            id
            reporter_id
            hidden_id
            createdAt
            updatedAt
          }
          nextToken
        }
        favorites {
          items {
            id
            post_id
            user_id
            created_at
            createdAt
            updatedAt
          }
          nextToken
        }
        updated_by
        createdAt
        updatedAt
        posts {
          items {
            id
            post_id
            is_active
            is_approved
            is_flagged
            author_id
            content
            post_video
            post_thumbnail
            post_type
            lat_lon
            loc_name
            updated_by
            createdAt
            updatedAt
          }
          nextToken
        }
      }
    }
  }
`;
export const deletePost = /* GraphQL */ `
  mutation DeletePost(
    $input: DeletePostInput!
    $condition: ModelPostConditionInput
  ) {
    deletePost(input: $input, condition: $condition) {
      id
      post_id
      is_active
      is_approved
      is_flagged
      author_id
      content
      post_video
      post_thumbnail
      post_type
      lat_lon
      loc_name
      updated_by
      createdAt
      updatedAt
      author {
        user_id
        is_active
        username
        first_name
        last_name
        phone_number
        email
        postal_code
        user_avatar
        default_signature
        profile_info
        affiliate_link
        affiliated_by
        url
        email_alerts
        email_alerts_contact
        email_alerts_follow
        sms_alerts
        sms_alerts_contact
        sms_alerts_follow
        push_alerts
        push_alerts_contact
        push_alerts_follow
        hide_account
        premium_account
        premium_account_lvl
        last_active
        following {
          items {
            id
            follower_id
            following_id
            createdAt
            updatedAt
          }
          nextToken
        }
        blocked {
          items {
            id
            blocker_id
            blocked_id
            createdAt
            updatedAt
          }
          nextToken
        }
        hidden {
          items {
            id
            reporter_id
            hidden_id
            createdAt
            updatedAt
          }
          nextToken
        }
        favorites {
          items {
            id
            post_id
            user_id
            created_at
            createdAt
            updatedAt
          }
          nextToken
        }
        updated_by
        createdAt
        updatedAt
        posts {
          items {
            id
            post_id
            is_active
            is_approved
            is_flagged
            author_id
            content
            post_video
            post_thumbnail
            post_type
            lat_lon
            loc_name
            updated_by
            createdAt
            updatedAt
          }
          nextToken
        }
      }
    }
  }
`;
export const createPostCategory = /* GraphQL */ `
  mutation CreatePostCategory(
    $input: CreatePostCategoryInput!
    $condition: ModelPostCategoryConditionInput
  ) {
    createPostCategory(input: $input, condition: $condition) {
      id
      post_id
      categoryID
      category {
        id
        category_Id
        title
        posts {
          items {
            id
            post_id
            categoryID
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
      post {
        id
        post_id
        is_active
        is_approved
        is_flagged
        author_id
        content
        post_video
        post_thumbnail
        post_type
        lat_lon
        loc_name
        categories {
          items {
            id
            post_id
            categoryID
            createdAt
            updatedAt
          }
          nextToken
        }
        likes {
          items {
            id
            post_id
            user_id
            created_at
            createdAt
            updatedAt
          }
          nextToken
        }
        favorites {
          items {
            id
            post_id
            user_id
            created_at
            createdAt
            updatedAt
          }
          nextToken
        }
        updated_by
        createdAt
        updatedAt
        author {
          user_id
          is_active
          username
          first_name
          last_name
          phone_number
          email
          postal_code
          user_avatar
          default_signature
          profile_info
          affiliate_link
          affiliated_by
          url
          email_alerts
          email_alerts_contact
          email_alerts_follow
          sms_alerts
          sms_alerts_contact
          sms_alerts_follow
          push_alerts
          push_alerts_contact
          push_alerts_follow
          hide_account
          premium_account
          premium_account_lvl
          last_active
          following {
            nextToken
          }
          blocked {
            nextToken
          }
          hidden {
            nextToken
          }
          favorites {
            nextToken
          }
          updated_by
          createdAt
          updatedAt
          posts {
            nextToken
          }
        }
      }
    }
  }
`;
export const updatePostCategory = /* GraphQL */ `
  mutation UpdatePostCategory(
    $input: UpdatePostCategoryInput!
    $condition: ModelPostCategoryConditionInput
  ) {
    updatePostCategory(input: $input, condition: $condition) {
      id
      post_id
      categoryID
      category {
        id
        category_Id
        title
        posts {
          items {
            id
            post_id
            categoryID
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
      post {
        id
        post_id
        is_active
        is_approved
        is_flagged
        author_id
        content
        post_video
        post_thumbnail
        post_type
        lat_lon
        loc_name
        categories {
          items {
            id
            post_id
            categoryID
            createdAt
            updatedAt
          }
          nextToken
        }
        likes {
          items {
            id
            post_id
            user_id
            created_at
            createdAt
            updatedAt
          }
          nextToken
        }
        favorites {
          items {
            id
            post_id
            user_id
            created_at
            createdAt
            updatedAt
          }
          nextToken
        }
        updated_by
        createdAt
        updatedAt
        author {
          user_id
          is_active
          username
          first_name
          last_name
          phone_number
          email
          postal_code
          user_avatar
          default_signature
          profile_info
          affiliate_link
          affiliated_by
          url
          email_alerts
          email_alerts_contact
          email_alerts_follow
          sms_alerts
          sms_alerts_contact
          sms_alerts_follow
          push_alerts
          push_alerts_contact
          push_alerts_follow
          hide_account
          premium_account
          premium_account_lvl
          last_active
          following {
            nextToken
          }
          blocked {
            nextToken
          }
          hidden {
            nextToken
          }
          favorites {
            nextToken
          }
          updated_by
          createdAt
          updatedAt
          posts {
            nextToken
          }
        }
      }
    }
  }
`;
export const deletePostCategory = /* GraphQL */ `
  mutation DeletePostCategory(
    $input: DeletePostCategoryInput!
    $condition: ModelPostCategoryConditionInput
  ) {
    deletePostCategory(input: $input, condition: $condition) {
      id
      post_id
      categoryID
      category {
        id
        category_Id
        title
        posts {
          items {
            id
            post_id
            categoryID
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
      post {
        id
        post_id
        is_active
        is_approved
        is_flagged
        author_id
        content
        post_video
        post_thumbnail
        post_type
        lat_lon
        loc_name
        categories {
          items {
            id
            post_id
            categoryID
            createdAt
            updatedAt
          }
          nextToken
        }
        likes {
          items {
            id
            post_id
            user_id
            created_at
            createdAt
            updatedAt
          }
          nextToken
        }
        favorites {
          items {
            id
            post_id
            user_id
            created_at
            createdAt
            updatedAt
          }
          nextToken
        }
        updated_by
        createdAt
        updatedAt
        author {
          user_id
          is_active
          username
          first_name
          last_name
          phone_number
          email
          postal_code
          user_avatar
          default_signature
          profile_info
          affiliate_link
          affiliated_by
          url
          email_alerts
          email_alerts_contact
          email_alerts_follow
          sms_alerts
          sms_alerts_contact
          sms_alerts_follow
          push_alerts
          push_alerts_contact
          push_alerts_follow
          hide_account
          premium_account
          premium_account_lvl
          last_active
          following {
            nextToken
          }
          blocked {
            nextToken
          }
          hidden {
            nextToken
          }
          favorites {
            nextToken
          }
          updated_by
          createdAt
          updatedAt
          posts {
            nextToken
          }
        }
      }
    }
  }
`;
export const createCategory = /* GraphQL */ `
  mutation CreateCategory(
    $input: CreateCategoryInput!
    $condition: ModelCategoryConditionInput
  ) {
    createCategory(input: $input, condition: $condition) {
      id
      category_Id
      title
      posts {
        items {
          id
          post_id
          categoryID
          category {
            id
            category_Id
            title
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
          post {
            id
            post_id
            is_active
            is_approved
            is_flagged
            author_id
            content
            post_video
            post_thumbnail
            post_type
            lat_lon
            loc_name
            updated_by
            createdAt
            updatedAt
          }
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateCategory = /* GraphQL */ `
  mutation UpdateCategory(
    $input: UpdateCategoryInput!
    $condition: ModelCategoryConditionInput
  ) {
    updateCategory(input: $input, condition: $condition) {
      id
      category_Id
      title
      posts {
        items {
          id
          post_id
          categoryID
          category {
            id
            category_Id
            title
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
          post {
            id
            post_id
            is_active
            is_approved
            is_flagged
            author_id
            content
            post_video
            post_thumbnail
            post_type
            lat_lon
            loc_name
            updated_by
            createdAt
            updatedAt
          }
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteCategory = /* GraphQL */ `
  mutation DeleteCategory(
    $input: DeleteCategoryInput!
    $condition: ModelCategoryConditionInput
  ) {
    deleteCategory(input: $input, condition: $condition) {
      id
      category_Id
      title
      posts {
        items {
          id
          post_id
          categoryID
          category {
            id
            category_Id
            title
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
          post {
            id
            post_id
            is_active
            is_approved
            is_flagged
            author_id
            content
            post_video
            post_thumbnail
            post_type
            lat_lon
            loc_name
            updated_by
            createdAt
            updatedAt
          }
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const createPostLike = /* GraphQL */ `
  mutation CreatePostLike(
    $input: CreatePostLikeInput!
    $condition: ModelPostLikeConditionInput
  ) {
    createPostLike(input: $input, condition: $condition) {
      id
      post_id
      user_id
      created_at
      createdAt
      updatedAt
      user {
        user_id
        is_active
        username
        first_name
        last_name
        phone_number
        email
        postal_code
        user_avatar
        default_signature
        profile_info
        affiliate_link
        affiliated_by
        url
        email_alerts
        email_alerts_contact
        email_alerts_follow
        sms_alerts
        sms_alerts_contact
        sms_alerts_follow
        push_alerts
        push_alerts_contact
        push_alerts_follow
        hide_account
        premium_account
        premium_account_lvl
        last_active
        following {
          items {
            id
            follower_id
            following_id
            createdAt
            updatedAt
          }
          nextToken
        }
        blocked {
          items {
            id
            blocker_id
            blocked_id
            createdAt
            updatedAt
          }
          nextToken
        }
        hidden {
          items {
            id
            reporter_id
            hidden_id
            createdAt
            updatedAt
          }
          nextToken
        }
        favorites {
          items {
            id
            post_id
            user_id
            created_at
            createdAt
            updatedAt
          }
          nextToken
        }
        updated_by
        createdAt
        updatedAt
        posts {
          items {
            id
            post_id
            is_active
            is_approved
            is_flagged
            author_id
            content
            post_video
            post_thumbnail
            post_type
            lat_lon
            loc_name
            updated_by
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      post {
        id
        post_id
        is_active
        is_approved
        is_flagged
        author_id
        content
        post_video
        post_thumbnail
        post_type
        lat_lon
        loc_name
        categories {
          items {
            id
            post_id
            categoryID
            createdAt
            updatedAt
          }
          nextToken
        }
        likes {
          items {
            id
            post_id
            user_id
            created_at
            createdAt
            updatedAt
          }
          nextToken
        }
        favorites {
          items {
            id
            post_id
            user_id
            created_at
            createdAt
            updatedAt
          }
          nextToken
        }
        updated_by
        createdAt
        updatedAt
        author {
          user_id
          is_active
          username
          first_name
          last_name
          phone_number
          email
          postal_code
          user_avatar
          default_signature
          profile_info
          affiliate_link
          affiliated_by
          url
          email_alerts
          email_alerts_contact
          email_alerts_follow
          sms_alerts
          sms_alerts_contact
          sms_alerts_follow
          push_alerts
          push_alerts_contact
          push_alerts_follow
          hide_account
          premium_account
          premium_account_lvl
          last_active
          following {
            nextToken
          }
          blocked {
            nextToken
          }
          hidden {
            nextToken
          }
          favorites {
            nextToken
          }
          updated_by
          createdAt
          updatedAt
          posts {
            nextToken
          }
        }
      }
    }
  }
`;
export const updatePostLike = /* GraphQL */ `
  mutation UpdatePostLike(
    $input: UpdatePostLikeInput!
    $condition: ModelPostLikeConditionInput
  ) {
    updatePostLike(input: $input, condition: $condition) {
      id
      post_id
      user_id
      created_at
      createdAt
      updatedAt
      user {
        user_id
        is_active
        username
        first_name
        last_name
        phone_number
        email
        postal_code
        user_avatar
        default_signature
        profile_info
        affiliate_link
        affiliated_by
        url
        email_alerts
        email_alerts_contact
        email_alerts_follow
        sms_alerts
        sms_alerts_contact
        sms_alerts_follow
        push_alerts
        push_alerts_contact
        push_alerts_follow
        hide_account
        premium_account
        premium_account_lvl
        last_active
        following {
          items {
            id
            follower_id
            following_id
            createdAt
            updatedAt
          }
          nextToken
        }
        blocked {
          items {
            id
            blocker_id
            blocked_id
            createdAt
            updatedAt
          }
          nextToken
        }
        hidden {
          items {
            id
            reporter_id
            hidden_id
            createdAt
            updatedAt
          }
          nextToken
        }
        favorites {
          items {
            id
            post_id
            user_id
            created_at
            createdAt
            updatedAt
          }
          nextToken
        }
        updated_by
        createdAt
        updatedAt
        posts {
          items {
            id
            post_id
            is_active
            is_approved
            is_flagged
            author_id
            content
            post_video
            post_thumbnail
            post_type
            lat_lon
            loc_name
            updated_by
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      post {
        id
        post_id
        is_active
        is_approved
        is_flagged
        author_id
        content
        post_video
        post_thumbnail
        post_type
        lat_lon
        loc_name
        categories {
          items {
            id
            post_id
            categoryID
            createdAt
            updatedAt
          }
          nextToken
        }
        likes {
          items {
            id
            post_id
            user_id
            created_at
            createdAt
            updatedAt
          }
          nextToken
        }
        favorites {
          items {
            id
            post_id
            user_id
            created_at
            createdAt
            updatedAt
          }
          nextToken
        }
        updated_by
        createdAt
        updatedAt
        author {
          user_id
          is_active
          username
          first_name
          last_name
          phone_number
          email
          postal_code
          user_avatar
          default_signature
          profile_info
          affiliate_link
          affiliated_by
          url
          email_alerts
          email_alerts_contact
          email_alerts_follow
          sms_alerts
          sms_alerts_contact
          sms_alerts_follow
          push_alerts
          push_alerts_contact
          push_alerts_follow
          hide_account
          premium_account
          premium_account_lvl
          last_active
          following {
            nextToken
          }
          blocked {
            nextToken
          }
          hidden {
            nextToken
          }
          favorites {
            nextToken
          }
          updated_by
          createdAt
          updatedAt
          posts {
            nextToken
          }
        }
      }
    }
  }
`;
export const deletePostLike = /* GraphQL */ `
  mutation DeletePostLike(
    $input: DeletePostLikeInput!
    $condition: ModelPostLikeConditionInput
  ) {
    deletePostLike(input: $input, condition: $condition) {
      id
      post_id
      user_id
      created_at
      createdAt
      updatedAt
      user {
        user_id
        is_active
        username
        first_name
        last_name
        phone_number
        email
        postal_code
        user_avatar
        default_signature
        profile_info
        affiliate_link
        affiliated_by
        url
        email_alerts
        email_alerts_contact
        email_alerts_follow
        sms_alerts
        sms_alerts_contact
        sms_alerts_follow
        push_alerts
        push_alerts_contact
        push_alerts_follow
        hide_account
        premium_account
        premium_account_lvl
        last_active
        following {
          items {
            id
            follower_id
            following_id
            createdAt
            updatedAt
          }
          nextToken
        }
        blocked {
          items {
            id
            blocker_id
            blocked_id
            createdAt
            updatedAt
          }
          nextToken
        }
        hidden {
          items {
            id
            reporter_id
            hidden_id
            createdAt
            updatedAt
          }
          nextToken
        }
        favorites {
          items {
            id
            post_id
            user_id
            created_at
            createdAt
            updatedAt
          }
          nextToken
        }
        updated_by
        createdAt
        updatedAt
        posts {
          items {
            id
            post_id
            is_active
            is_approved
            is_flagged
            author_id
            content
            post_video
            post_thumbnail
            post_type
            lat_lon
            loc_name
            updated_by
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      post {
        id
        post_id
        is_active
        is_approved
        is_flagged
        author_id
        content
        post_video
        post_thumbnail
        post_type
        lat_lon
        loc_name
        categories {
          items {
            id
            post_id
            categoryID
            createdAt
            updatedAt
          }
          nextToken
        }
        likes {
          items {
            id
            post_id
            user_id
            created_at
            createdAt
            updatedAt
          }
          nextToken
        }
        favorites {
          items {
            id
            post_id
            user_id
            created_at
            createdAt
            updatedAt
          }
          nextToken
        }
        updated_by
        createdAt
        updatedAt
        author {
          user_id
          is_active
          username
          first_name
          last_name
          phone_number
          email
          postal_code
          user_avatar
          default_signature
          profile_info
          affiliate_link
          affiliated_by
          url
          email_alerts
          email_alerts_contact
          email_alerts_follow
          sms_alerts
          sms_alerts_contact
          sms_alerts_follow
          push_alerts
          push_alerts_contact
          push_alerts_follow
          hide_account
          premium_account
          premium_account_lvl
          last_active
          following {
            nextToken
          }
          blocked {
            nextToken
          }
          hidden {
            nextToken
          }
          favorites {
            nextToken
          }
          updated_by
          createdAt
          updatedAt
          posts {
            nextToken
          }
        }
      }
    }
  }
`;
export const createPostFavorite = /* GraphQL */ `
  mutation CreatePostFavorite(
    $input: CreatePostFavoriteInput!
    $condition: ModelPostFavoriteConditionInput
  ) {
    createPostFavorite(input: $input, condition: $condition) {
      id
      post_id
      user_id
      created_at
      createdAt
      updatedAt
      user {
        user_id
        is_active
        username
        first_name
        last_name
        phone_number
        email
        postal_code
        user_avatar
        default_signature
        profile_info
        affiliate_link
        affiliated_by
        url
        email_alerts
        email_alerts_contact
        email_alerts_follow
        sms_alerts
        sms_alerts_contact
        sms_alerts_follow
        push_alerts
        push_alerts_contact
        push_alerts_follow
        hide_account
        premium_account
        premium_account_lvl
        last_active
        following {
          items {
            id
            follower_id
            following_id
            createdAt
            updatedAt
          }
          nextToken
        }
        blocked {
          items {
            id
            blocker_id
            blocked_id
            createdAt
            updatedAt
          }
          nextToken
        }
        hidden {
          items {
            id
            reporter_id
            hidden_id
            createdAt
            updatedAt
          }
          nextToken
        }
        favorites {
          items {
            id
            post_id
            user_id
            created_at
            createdAt
            updatedAt
          }
          nextToken
        }
        updated_by
        createdAt
        updatedAt
        posts {
          items {
            id
            post_id
            is_active
            is_approved
            is_flagged
            author_id
            content
            post_video
            post_thumbnail
            post_type
            lat_lon
            loc_name
            updated_by
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      post {
        id
        post_id
        is_active
        is_approved
        is_flagged
        author_id
        content
        post_video
        post_thumbnail
        post_type
        lat_lon
        loc_name
        categories {
          items {
            id
            post_id
            categoryID
            createdAt
            updatedAt
          }
          nextToken
        }
        likes {
          items {
            id
            post_id
            user_id
            created_at
            createdAt
            updatedAt
          }
          nextToken
        }
        favorites {
          items {
            id
            post_id
            user_id
            created_at
            createdAt
            updatedAt
          }
          nextToken
        }
        updated_by
        createdAt
        updatedAt
        author {
          user_id
          is_active
          username
          first_name
          last_name
          phone_number
          email
          postal_code
          user_avatar
          default_signature
          profile_info
          affiliate_link
          affiliated_by
          url
          email_alerts
          email_alerts_contact
          email_alerts_follow
          sms_alerts
          sms_alerts_contact
          sms_alerts_follow
          push_alerts
          push_alerts_contact
          push_alerts_follow
          hide_account
          premium_account
          premium_account_lvl
          last_active
          following {
            nextToken
          }
          blocked {
            nextToken
          }
          hidden {
            nextToken
          }
          favorites {
            nextToken
          }
          updated_by
          createdAt
          updatedAt
          posts {
            nextToken
          }
        }
      }
    }
  }
`;
export const updatePostFavorite = /* GraphQL */ `
  mutation UpdatePostFavorite(
    $input: UpdatePostFavoriteInput!
    $condition: ModelPostFavoriteConditionInput
  ) {
    updatePostFavorite(input: $input, condition: $condition) {
      id
      post_id
      user_id
      created_at
      createdAt
      updatedAt
      user {
        user_id
        is_active
        username
        first_name
        last_name
        phone_number
        email
        postal_code
        user_avatar
        default_signature
        profile_info
        affiliate_link
        affiliated_by
        url
        email_alerts
        email_alerts_contact
        email_alerts_follow
        sms_alerts
        sms_alerts_contact
        sms_alerts_follow
        push_alerts
        push_alerts_contact
        push_alerts_follow
        hide_account
        premium_account
        premium_account_lvl
        last_active
        following {
          items {
            id
            follower_id
            following_id
            createdAt
            updatedAt
          }
          nextToken
        }
        blocked {
          items {
            id
            blocker_id
            blocked_id
            createdAt
            updatedAt
          }
          nextToken
        }
        hidden {
          items {
            id
            reporter_id
            hidden_id
            createdAt
            updatedAt
          }
          nextToken
        }
        favorites {
          items {
            id
            post_id
            user_id
            created_at
            createdAt
            updatedAt
          }
          nextToken
        }
        updated_by
        createdAt
        updatedAt
        posts {
          items {
            id
            post_id
            is_active
            is_approved
            is_flagged
            author_id
            content
            post_video
            post_thumbnail
            post_type
            lat_lon
            loc_name
            updated_by
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      post {
        id
        post_id
        is_active
        is_approved
        is_flagged
        author_id
        content
        post_video
        post_thumbnail
        post_type
        lat_lon
        loc_name
        categories {
          items {
            id
            post_id
            categoryID
            createdAt
            updatedAt
          }
          nextToken
        }
        likes {
          items {
            id
            post_id
            user_id
            created_at
            createdAt
            updatedAt
          }
          nextToken
        }
        favorites {
          items {
            id
            post_id
            user_id
            created_at
            createdAt
            updatedAt
          }
          nextToken
        }
        updated_by
        createdAt
        updatedAt
        author {
          user_id
          is_active
          username
          first_name
          last_name
          phone_number
          email
          postal_code
          user_avatar
          default_signature
          profile_info
          affiliate_link
          affiliated_by
          url
          email_alerts
          email_alerts_contact
          email_alerts_follow
          sms_alerts
          sms_alerts_contact
          sms_alerts_follow
          push_alerts
          push_alerts_contact
          push_alerts_follow
          hide_account
          premium_account
          premium_account_lvl
          last_active
          following {
            nextToken
          }
          blocked {
            nextToken
          }
          hidden {
            nextToken
          }
          favorites {
            nextToken
          }
          updated_by
          createdAt
          updatedAt
          posts {
            nextToken
          }
        }
      }
    }
  }
`;
export const deletePostFavorite = /* GraphQL */ `
  mutation DeletePostFavorite(
    $input: DeletePostFavoriteInput!
    $condition: ModelPostFavoriteConditionInput
  ) {
    deletePostFavorite(input: $input, condition: $condition) {
      id
      post_id
      user_id
      created_at
      createdAt
      updatedAt
      user {
        user_id
        is_active
        username
        first_name
        last_name
        phone_number
        email
        postal_code
        user_avatar
        default_signature
        profile_info
        affiliate_link
        affiliated_by
        url
        email_alerts
        email_alerts_contact
        email_alerts_follow
        sms_alerts
        sms_alerts_contact
        sms_alerts_follow
        push_alerts
        push_alerts_contact
        push_alerts_follow
        hide_account
        premium_account
        premium_account_lvl
        last_active
        following {
          items {
            id
            follower_id
            following_id
            createdAt
            updatedAt
          }
          nextToken
        }
        blocked {
          items {
            id
            blocker_id
            blocked_id
            createdAt
            updatedAt
          }
          nextToken
        }
        hidden {
          items {
            id
            reporter_id
            hidden_id
            createdAt
            updatedAt
          }
          nextToken
        }
        favorites {
          items {
            id
            post_id
            user_id
            created_at
            createdAt
            updatedAt
          }
          nextToken
        }
        updated_by
        createdAt
        updatedAt
        posts {
          items {
            id
            post_id
            is_active
            is_approved
            is_flagged
            author_id
            content
            post_video
            post_thumbnail
            post_type
            lat_lon
            loc_name
            updated_by
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      post {
        id
        post_id
        is_active
        is_approved
        is_flagged
        author_id
        content
        post_video
        post_thumbnail
        post_type
        lat_lon
        loc_name
        categories {
          items {
            id
            post_id
            categoryID
            createdAt
            updatedAt
          }
          nextToken
        }
        likes {
          items {
            id
            post_id
            user_id
            created_at
            createdAt
            updatedAt
          }
          nextToken
        }
        favorites {
          items {
            id
            post_id
            user_id
            created_at
            createdAt
            updatedAt
          }
          nextToken
        }
        updated_by
        createdAt
        updatedAt
        author {
          user_id
          is_active
          username
          first_name
          last_name
          phone_number
          email
          postal_code
          user_avatar
          default_signature
          profile_info
          affiliate_link
          affiliated_by
          url
          email_alerts
          email_alerts_contact
          email_alerts_follow
          sms_alerts
          sms_alerts_contact
          sms_alerts_follow
          push_alerts
          push_alerts_contact
          push_alerts_follow
          hide_account
          premium_account
          premium_account_lvl
          last_active
          following {
            nextToken
          }
          blocked {
            nextToken
          }
          hidden {
            nextToken
          }
          favorites {
            nextToken
          }
          updated_by
          createdAt
          updatedAt
          posts {
            nextToken
          }
        }
      }
    }
  }
`;
export const createFollowing = /* GraphQL */ `
  mutation CreateFollowing(
    $input: CreateFollowingInput!
    $condition: ModelFollowingConditionInput
  ) {
    createFollowing(input: $input, condition: $condition) {
      id
      follower_id
      following_id
      createdAt
      updatedAt
      follower {
        user_id
        is_active
        username
        first_name
        last_name
        phone_number
        email
        postal_code
        user_avatar
        default_signature
        profile_info
        affiliate_link
        affiliated_by
        url
        email_alerts
        email_alerts_contact
        email_alerts_follow
        sms_alerts
        sms_alerts_contact
        sms_alerts_follow
        push_alerts
        push_alerts_contact
        push_alerts_follow
        hide_account
        premium_account
        premium_account_lvl
        last_active
        following {
          items {
            id
            follower_id
            following_id
            createdAt
            updatedAt
          }
          nextToken
        }
        blocked {
          items {
            id
            blocker_id
            blocked_id
            createdAt
            updatedAt
          }
          nextToken
        }
        hidden {
          items {
            id
            reporter_id
            hidden_id
            createdAt
            updatedAt
          }
          nextToken
        }
        favorites {
          items {
            id
            post_id
            user_id
            created_at
            createdAt
            updatedAt
          }
          nextToken
        }
        updated_by
        createdAt
        updatedAt
        posts {
          items {
            id
            post_id
            is_active
            is_approved
            is_flagged
            author_id
            content
            post_video
            post_thumbnail
            post_type
            lat_lon
            loc_name
            updated_by
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      following {
        user_id
        is_active
        username
        first_name
        last_name
        phone_number
        email
        postal_code
        user_avatar
        default_signature
        profile_info
        affiliate_link
        affiliated_by
        url
        email_alerts
        email_alerts_contact
        email_alerts_follow
        sms_alerts
        sms_alerts_contact
        sms_alerts_follow
        push_alerts
        push_alerts_contact
        push_alerts_follow
        hide_account
        premium_account
        premium_account_lvl
        last_active
        following {
          items {
            id
            follower_id
            following_id
            createdAt
            updatedAt
          }
          nextToken
        }
        blocked {
          items {
            id
            blocker_id
            blocked_id
            createdAt
            updatedAt
          }
          nextToken
        }
        hidden {
          items {
            id
            reporter_id
            hidden_id
            createdAt
            updatedAt
          }
          nextToken
        }
        favorites {
          items {
            id
            post_id
            user_id
            created_at
            createdAt
            updatedAt
          }
          nextToken
        }
        updated_by
        createdAt
        updatedAt
        posts {
          items {
            id
            post_id
            is_active
            is_approved
            is_flagged
            author_id
            content
            post_video
            post_thumbnail
            post_type
            lat_lon
            loc_name
            updated_by
            createdAt
            updatedAt
          }
          nextToken
        }
      }
    }
  }
`;
export const updateFollowing = /* GraphQL */ `
  mutation UpdateFollowing(
    $input: UpdateFollowingInput!
    $condition: ModelFollowingConditionInput
  ) {
    updateFollowing(input: $input, condition: $condition) {
      id
      follower_id
      following_id
      createdAt
      updatedAt
      follower {
        user_id
        is_active
        username
        first_name
        last_name
        phone_number
        email
        postal_code
        user_avatar
        default_signature
        profile_info
        affiliate_link
        affiliated_by
        url
        email_alerts
        email_alerts_contact
        email_alerts_follow
        sms_alerts
        sms_alerts_contact
        sms_alerts_follow
        push_alerts
        push_alerts_contact
        push_alerts_follow
        hide_account
        premium_account
        premium_account_lvl
        last_active
        following {
          items {
            id
            follower_id
            following_id
            createdAt
            updatedAt
          }
          nextToken
        }
        blocked {
          items {
            id
            blocker_id
            blocked_id
            createdAt
            updatedAt
          }
          nextToken
        }
        hidden {
          items {
            id
            reporter_id
            hidden_id
            createdAt
            updatedAt
          }
          nextToken
        }
        favorites {
          items {
            id
            post_id
            user_id
            created_at
            createdAt
            updatedAt
          }
          nextToken
        }
        updated_by
        createdAt
        updatedAt
        posts {
          items {
            id
            post_id
            is_active
            is_approved
            is_flagged
            author_id
            content
            post_video
            post_thumbnail
            post_type
            lat_lon
            loc_name
            updated_by
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      following {
        user_id
        is_active
        username
        first_name
        last_name
        phone_number
        email
        postal_code
        user_avatar
        default_signature
        profile_info
        affiliate_link
        affiliated_by
        url
        email_alerts
        email_alerts_contact
        email_alerts_follow
        sms_alerts
        sms_alerts_contact
        sms_alerts_follow
        push_alerts
        push_alerts_contact
        push_alerts_follow
        hide_account
        premium_account
        premium_account_lvl
        last_active
        following {
          items {
            id
            follower_id
            following_id
            createdAt
            updatedAt
          }
          nextToken
        }
        blocked {
          items {
            id
            blocker_id
            blocked_id
            createdAt
            updatedAt
          }
          nextToken
        }
        hidden {
          items {
            id
            reporter_id
            hidden_id
            createdAt
            updatedAt
          }
          nextToken
        }
        favorites {
          items {
            id
            post_id
            user_id
            created_at
            createdAt
            updatedAt
          }
          nextToken
        }
        updated_by
        createdAt
        updatedAt
        posts {
          items {
            id
            post_id
            is_active
            is_approved
            is_flagged
            author_id
            content
            post_video
            post_thumbnail
            post_type
            lat_lon
            loc_name
            updated_by
            createdAt
            updatedAt
          }
          nextToken
        }
      }
    }
  }
`;
export const deleteFollowing = /* GraphQL */ `
  mutation DeleteFollowing(
    $input: DeleteFollowingInput!
    $condition: ModelFollowingConditionInput
  ) {
    deleteFollowing(input: $input, condition: $condition) {
      id
      follower_id
      following_id
      createdAt
      updatedAt
      follower {
        user_id
        is_active
        username
        first_name
        last_name
        phone_number
        email
        postal_code
        user_avatar
        default_signature
        profile_info
        affiliate_link
        affiliated_by
        url
        email_alerts
        email_alerts_contact
        email_alerts_follow
        sms_alerts
        sms_alerts_contact
        sms_alerts_follow
        push_alerts
        push_alerts_contact
        push_alerts_follow
        hide_account
        premium_account
        premium_account_lvl
        last_active
        following {
          items {
            id
            follower_id
            following_id
            createdAt
            updatedAt
          }
          nextToken
        }
        blocked {
          items {
            id
            blocker_id
            blocked_id
            createdAt
            updatedAt
          }
          nextToken
        }
        hidden {
          items {
            id
            reporter_id
            hidden_id
            createdAt
            updatedAt
          }
          nextToken
        }
        favorites {
          items {
            id
            post_id
            user_id
            created_at
            createdAt
            updatedAt
          }
          nextToken
        }
        updated_by
        createdAt
        updatedAt
        posts {
          items {
            id
            post_id
            is_active
            is_approved
            is_flagged
            author_id
            content
            post_video
            post_thumbnail
            post_type
            lat_lon
            loc_name
            updated_by
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      following {
        user_id
        is_active
        username
        first_name
        last_name
        phone_number
        email
        postal_code
        user_avatar
        default_signature
        profile_info
        affiliate_link
        affiliated_by
        url
        email_alerts
        email_alerts_contact
        email_alerts_follow
        sms_alerts
        sms_alerts_contact
        sms_alerts_follow
        push_alerts
        push_alerts_contact
        push_alerts_follow
        hide_account
        premium_account
        premium_account_lvl
        last_active
        following {
          items {
            id
            follower_id
            following_id
            createdAt
            updatedAt
          }
          nextToken
        }
        blocked {
          items {
            id
            blocker_id
            blocked_id
            createdAt
            updatedAt
          }
          nextToken
        }
        hidden {
          items {
            id
            reporter_id
            hidden_id
            createdAt
            updatedAt
          }
          nextToken
        }
        favorites {
          items {
            id
            post_id
            user_id
            created_at
            createdAt
            updatedAt
          }
          nextToken
        }
        updated_by
        createdAt
        updatedAt
        posts {
          items {
            id
            post_id
            is_active
            is_approved
            is_flagged
            author_id
            content
            post_video
            post_thumbnail
            post_type
            lat_lon
            loc_name
            updated_by
            createdAt
            updatedAt
          }
          nextToken
        }
      }
    }
  }
`;
export const createBlocked = /* GraphQL */ `
  mutation CreateBlocked(
    $input: CreateBlockedInput!
    $condition: ModelBlockedConditionInput
  ) {
    createBlocked(input: $input, condition: $condition) {
      id
      blocker_id
      blocked_id
      createdAt
      updatedAt
      blocker {
        user_id
        is_active
        username
        first_name
        last_name
        phone_number
        email
        postal_code
        user_avatar
        default_signature
        profile_info
        affiliate_link
        affiliated_by
        url
        email_alerts
        email_alerts_contact
        email_alerts_follow
        sms_alerts
        sms_alerts_contact
        sms_alerts_follow
        push_alerts
        push_alerts_contact
        push_alerts_follow
        hide_account
        premium_account
        premium_account_lvl
        last_active
        following {
          items {
            id
            follower_id
            following_id
            createdAt
            updatedAt
          }
          nextToken
        }
        blocked {
          items {
            id
            blocker_id
            blocked_id
            createdAt
            updatedAt
          }
          nextToken
        }
        hidden {
          items {
            id
            reporter_id
            hidden_id
            createdAt
            updatedAt
          }
          nextToken
        }
        favorites {
          items {
            id
            post_id
            user_id
            created_at
            createdAt
            updatedAt
          }
          nextToken
        }
        updated_by
        createdAt
        updatedAt
        posts {
          items {
            id
            post_id
            is_active
            is_approved
            is_flagged
            author_id
            content
            post_video
            post_thumbnail
            post_type
            lat_lon
            loc_name
            updated_by
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      blocked {
        user_id
        is_active
        username
        first_name
        last_name
        phone_number
        email
        postal_code
        user_avatar
        default_signature
        profile_info
        affiliate_link
        affiliated_by
        url
        email_alerts
        email_alerts_contact
        email_alerts_follow
        sms_alerts
        sms_alerts_contact
        sms_alerts_follow
        push_alerts
        push_alerts_contact
        push_alerts_follow
        hide_account
        premium_account
        premium_account_lvl
        last_active
        following {
          items {
            id
            follower_id
            following_id
            createdAt
            updatedAt
          }
          nextToken
        }
        blocked {
          items {
            id
            blocker_id
            blocked_id
            createdAt
            updatedAt
          }
          nextToken
        }
        hidden {
          items {
            id
            reporter_id
            hidden_id
            createdAt
            updatedAt
          }
          nextToken
        }
        favorites {
          items {
            id
            post_id
            user_id
            created_at
            createdAt
            updatedAt
          }
          nextToken
        }
        updated_by
        createdAt
        updatedAt
        posts {
          items {
            id
            post_id
            is_active
            is_approved
            is_flagged
            author_id
            content
            post_video
            post_thumbnail
            post_type
            lat_lon
            loc_name
            updated_by
            createdAt
            updatedAt
          }
          nextToken
        }
      }
    }
  }
`;
export const updateBlocked = /* GraphQL */ `
  mutation UpdateBlocked(
    $input: UpdateBlockedInput!
    $condition: ModelBlockedConditionInput
  ) {
    updateBlocked(input: $input, condition: $condition) {
      id
      blocker_id
      blocked_id
      createdAt
      updatedAt
      blocker {
        user_id
        is_active
        username
        first_name
        last_name
        phone_number
        email
        postal_code
        user_avatar
        default_signature
        profile_info
        affiliate_link
        affiliated_by
        url
        email_alerts
        email_alerts_contact
        email_alerts_follow
        sms_alerts
        sms_alerts_contact
        sms_alerts_follow
        push_alerts
        push_alerts_contact
        push_alerts_follow
        hide_account
        premium_account
        premium_account_lvl
        last_active
        following {
          items {
            id
            follower_id
            following_id
            createdAt
            updatedAt
          }
          nextToken
        }
        blocked {
          items {
            id
            blocker_id
            blocked_id
            createdAt
            updatedAt
          }
          nextToken
        }
        hidden {
          items {
            id
            reporter_id
            hidden_id
            createdAt
            updatedAt
          }
          nextToken
        }
        favorites {
          items {
            id
            post_id
            user_id
            created_at
            createdAt
            updatedAt
          }
          nextToken
        }
        updated_by
        createdAt
        updatedAt
        posts {
          items {
            id
            post_id
            is_active
            is_approved
            is_flagged
            author_id
            content
            post_video
            post_thumbnail
            post_type
            lat_lon
            loc_name
            updated_by
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      blocked {
        user_id
        is_active
        username
        first_name
        last_name
        phone_number
        email
        postal_code
        user_avatar
        default_signature
        profile_info
        affiliate_link
        affiliated_by
        url
        email_alerts
        email_alerts_contact
        email_alerts_follow
        sms_alerts
        sms_alerts_contact
        sms_alerts_follow
        push_alerts
        push_alerts_contact
        push_alerts_follow
        hide_account
        premium_account
        premium_account_lvl
        last_active
        following {
          items {
            id
            follower_id
            following_id
            createdAt
            updatedAt
          }
          nextToken
        }
        blocked {
          items {
            id
            blocker_id
            blocked_id
            createdAt
            updatedAt
          }
          nextToken
        }
        hidden {
          items {
            id
            reporter_id
            hidden_id
            createdAt
            updatedAt
          }
          nextToken
        }
        favorites {
          items {
            id
            post_id
            user_id
            created_at
            createdAt
            updatedAt
          }
          nextToken
        }
        updated_by
        createdAt
        updatedAt
        posts {
          items {
            id
            post_id
            is_active
            is_approved
            is_flagged
            author_id
            content
            post_video
            post_thumbnail
            post_type
            lat_lon
            loc_name
            updated_by
            createdAt
            updatedAt
          }
          nextToken
        }
      }
    }
  }
`;
export const deleteBlocked = /* GraphQL */ `
  mutation DeleteBlocked(
    $input: DeleteBlockedInput!
    $condition: ModelBlockedConditionInput
  ) {
    deleteBlocked(input: $input, condition: $condition) {
      id
      blocker_id
      blocked_id
      createdAt
      updatedAt
      blocker {
        user_id
        is_active
        username
        first_name
        last_name
        phone_number
        email
        postal_code
        user_avatar
        default_signature
        profile_info
        affiliate_link
        affiliated_by
        url
        email_alerts
        email_alerts_contact
        email_alerts_follow
        sms_alerts
        sms_alerts_contact
        sms_alerts_follow
        push_alerts
        push_alerts_contact
        push_alerts_follow
        hide_account
        premium_account
        premium_account_lvl
        last_active
        following {
          items {
            id
            follower_id
            following_id
            createdAt
            updatedAt
          }
          nextToken
        }
        blocked {
          items {
            id
            blocker_id
            blocked_id
            createdAt
            updatedAt
          }
          nextToken
        }
        hidden {
          items {
            id
            reporter_id
            hidden_id
            createdAt
            updatedAt
          }
          nextToken
        }
        favorites {
          items {
            id
            post_id
            user_id
            created_at
            createdAt
            updatedAt
          }
          nextToken
        }
        updated_by
        createdAt
        updatedAt
        posts {
          items {
            id
            post_id
            is_active
            is_approved
            is_flagged
            author_id
            content
            post_video
            post_thumbnail
            post_type
            lat_lon
            loc_name
            updated_by
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      blocked {
        user_id
        is_active
        username
        first_name
        last_name
        phone_number
        email
        postal_code
        user_avatar
        default_signature
        profile_info
        affiliate_link
        affiliated_by
        url
        email_alerts
        email_alerts_contact
        email_alerts_follow
        sms_alerts
        sms_alerts_contact
        sms_alerts_follow
        push_alerts
        push_alerts_contact
        push_alerts_follow
        hide_account
        premium_account
        premium_account_lvl
        last_active
        following {
          items {
            id
            follower_id
            following_id
            createdAt
            updatedAt
          }
          nextToken
        }
        blocked {
          items {
            id
            blocker_id
            blocked_id
            createdAt
            updatedAt
          }
          nextToken
        }
        hidden {
          items {
            id
            reporter_id
            hidden_id
            createdAt
            updatedAt
          }
          nextToken
        }
        favorites {
          items {
            id
            post_id
            user_id
            created_at
            createdAt
            updatedAt
          }
          nextToken
        }
        updated_by
        createdAt
        updatedAt
        posts {
          items {
            id
            post_id
            is_active
            is_approved
            is_flagged
            author_id
            content
            post_video
            post_thumbnail
            post_type
            lat_lon
            loc_name
            updated_by
            createdAt
            updatedAt
          }
          nextToken
        }
      }
    }
  }
`;
export const createHidden = /* GraphQL */ `
  mutation CreateHidden(
    $input: CreateHiddenInput!
    $condition: ModelHiddenConditionInput
  ) {
    createHidden(input: $input, condition: $condition) {
      id
      reporter_id
      hidden_id
      createdAt
      updatedAt
      reporter {
        user_id
        is_active
        username
        first_name
        last_name
        phone_number
        email
        postal_code
        user_avatar
        default_signature
        profile_info
        affiliate_link
        affiliated_by
        url
        email_alerts
        email_alerts_contact
        email_alerts_follow
        sms_alerts
        sms_alerts_contact
        sms_alerts_follow
        push_alerts
        push_alerts_contact
        push_alerts_follow
        hide_account
        premium_account
        premium_account_lvl
        last_active
        following {
          items {
            id
            follower_id
            following_id
            createdAt
            updatedAt
          }
          nextToken
        }
        blocked {
          items {
            id
            blocker_id
            blocked_id
            createdAt
            updatedAt
          }
          nextToken
        }
        hidden {
          items {
            id
            reporter_id
            hidden_id
            createdAt
            updatedAt
          }
          nextToken
        }
        favorites {
          items {
            id
            post_id
            user_id
            created_at
            createdAt
            updatedAt
          }
          nextToken
        }
        updated_by
        createdAt
        updatedAt
        posts {
          items {
            id
            post_id
            is_active
            is_approved
            is_flagged
            author_id
            content
            post_video
            post_thumbnail
            post_type
            lat_lon
            loc_name
            updated_by
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      hidden {
        id
        post_id
        is_active
        is_approved
        is_flagged
        author_id
        content
        post_video
        post_thumbnail
        post_type
        lat_lon
        loc_name
        categories {
          items {
            id
            post_id
            categoryID
            createdAt
            updatedAt
          }
          nextToken
        }
        likes {
          items {
            id
            post_id
            user_id
            created_at
            createdAt
            updatedAt
          }
          nextToken
        }
        favorites {
          items {
            id
            post_id
            user_id
            created_at
            createdAt
            updatedAt
          }
          nextToken
        }
        updated_by
        createdAt
        updatedAt
        author {
          user_id
          is_active
          username
          first_name
          last_name
          phone_number
          email
          postal_code
          user_avatar
          default_signature
          profile_info
          affiliate_link
          affiliated_by
          url
          email_alerts
          email_alerts_contact
          email_alerts_follow
          sms_alerts
          sms_alerts_contact
          sms_alerts_follow
          push_alerts
          push_alerts_contact
          push_alerts_follow
          hide_account
          premium_account
          premium_account_lvl
          last_active
          following {
            nextToken
          }
          blocked {
            nextToken
          }
          hidden {
            nextToken
          }
          favorites {
            nextToken
          }
          updated_by
          createdAt
          updatedAt
          posts {
            nextToken
          }
        }
      }
    }
  }
`;
export const updateHidden = /* GraphQL */ `
  mutation UpdateHidden(
    $input: UpdateHiddenInput!
    $condition: ModelHiddenConditionInput
  ) {
    updateHidden(input: $input, condition: $condition) {
      id
      reporter_id
      hidden_id
      createdAt
      updatedAt
      reporter {
        user_id
        is_active
        username
        first_name
        last_name
        phone_number
        email
        postal_code
        user_avatar
        default_signature
        profile_info
        affiliate_link
        affiliated_by
        url
        email_alerts
        email_alerts_contact
        email_alerts_follow
        sms_alerts
        sms_alerts_contact
        sms_alerts_follow
        push_alerts
        push_alerts_contact
        push_alerts_follow
        hide_account
        premium_account
        premium_account_lvl
        last_active
        following {
          items {
            id
            follower_id
            following_id
            createdAt
            updatedAt
          }
          nextToken
        }
        blocked {
          items {
            id
            blocker_id
            blocked_id
            createdAt
            updatedAt
          }
          nextToken
        }
        hidden {
          items {
            id
            reporter_id
            hidden_id
            createdAt
            updatedAt
          }
          nextToken
        }
        favorites {
          items {
            id
            post_id
            user_id
            created_at
            createdAt
            updatedAt
          }
          nextToken
        }
        updated_by
        createdAt
        updatedAt
        posts {
          items {
            id
            post_id
            is_active
            is_approved
            is_flagged
            author_id
            content
            post_video
            post_thumbnail
            post_type
            lat_lon
            loc_name
            updated_by
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      hidden {
        id
        post_id
        is_active
        is_approved
        is_flagged
        author_id
        content
        post_video
        post_thumbnail
        post_type
        lat_lon
        loc_name
        categories {
          items {
            id
            post_id
            categoryID
            createdAt
            updatedAt
          }
          nextToken
        }
        likes {
          items {
            id
            post_id
            user_id
            created_at
            createdAt
            updatedAt
          }
          nextToken
        }
        favorites {
          items {
            id
            post_id
            user_id
            created_at
            createdAt
            updatedAt
          }
          nextToken
        }
        updated_by
        createdAt
        updatedAt
        author {
          user_id
          is_active
          username
          first_name
          last_name
          phone_number
          email
          postal_code
          user_avatar
          default_signature
          profile_info
          affiliate_link
          affiliated_by
          url
          email_alerts
          email_alerts_contact
          email_alerts_follow
          sms_alerts
          sms_alerts_contact
          sms_alerts_follow
          push_alerts
          push_alerts_contact
          push_alerts_follow
          hide_account
          premium_account
          premium_account_lvl
          last_active
          following {
            nextToken
          }
          blocked {
            nextToken
          }
          hidden {
            nextToken
          }
          favorites {
            nextToken
          }
          updated_by
          createdAt
          updatedAt
          posts {
            nextToken
          }
        }
      }
    }
  }
`;
export const deleteHidden = /* GraphQL */ `
  mutation DeleteHidden(
    $input: DeleteHiddenInput!
    $condition: ModelHiddenConditionInput
  ) {
    deleteHidden(input: $input, condition: $condition) {
      id
      reporter_id
      hidden_id
      createdAt
      updatedAt
      reporter {
        user_id
        is_active
        username
        first_name
        last_name
        phone_number
        email
        postal_code
        user_avatar
        default_signature
        profile_info
        affiliate_link
        affiliated_by
        url
        email_alerts
        email_alerts_contact
        email_alerts_follow
        sms_alerts
        sms_alerts_contact
        sms_alerts_follow
        push_alerts
        push_alerts_contact
        push_alerts_follow
        hide_account
        premium_account
        premium_account_lvl
        last_active
        following {
          items {
            id
            follower_id
            following_id
            createdAt
            updatedAt
          }
          nextToken
        }
        blocked {
          items {
            id
            blocker_id
            blocked_id
            createdAt
            updatedAt
          }
          nextToken
        }
        hidden {
          items {
            id
            reporter_id
            hidden_id
            createdAt
            updatedAt
          }
          nextToken
        }
        favorites {
          items {
            id
            post_id
            user_id
            created_at
            createdAt
            updatedAt
          }
          nextToken
        }
        updated_by
        createdAt
        updatedAt
        posts {
          items {
            id
            post_id
            is_active
            is_approved
            is_flagged
            author_id
            content
            post_video
            post_thumbnail
            post_type
            lat_lon
            loc_name
            updated_by
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      hidden {
        id
        post_id
        is_active
        is_approved
        is_flagged
        author_id
        content
        post_video
        post_thumbnail
        post_type
        lat_lon
        loc_name
        categories {
          items {
            id
            post_id
            categoryID
            createdAt
            updatedAt
          }
          nextToken
        }
        likes {
          items {
            id
            post_id
            user_id
            created_at
            createdAt
            updatedAt
          }
          nextToken
        }
        favorites {
          items {
            id
            post_id
            user_id
            created_at
            createdAt
            updatedAt
          }
          nextToken
        }
        updated_by
        createdAt
        updatedAt
        author {
          user_id
          is_active
          username
          first_name
          last_name
          phone_number
          email
          postal_code
          user_avatar
          default_signature
          profile_info
          affiliate_link
          affiliated_by
          url
          email_alerts
          email_alerts_contact
          email_alerts_follow
          sms_alerts
          sms_alerts_contact
          sms_alerts_follow
          push_alerts
          push_alerts_contact
          push_alerts_follow
          hide_account
          premium_account
          premium_account_lvl
          last_active
          following {
            nextToken
          }
          blocked {
            nextToken
          }
          hidden {
            nextToken
          }
          favorites {
            nextToken
          }
          updated_by
          createdAt
          updatedAt
          posts {
            nextToken
          }
        }
      }
    }
  }
`;
export const createReported = /* GraphQL */ `
  mutation CreateReported(
    $input: CreateReportedInput!
    $condition: ModelReportedConditionInput
  ) {
    createReported(input: $input, condition: $condition) {
      id
      reporter_id
      reported_id
      report_reason
      report_desc
      createdAt
      updatedAt
      reporter {
        user_id
        is_active
        username
        first_name
        last_name
        phone_number
        email
        postal_code
        user_avatar
        default_signature
        profile_info
        affiliate_link
        affiliated_by
        url
        email_alerts
        email_alerts_contact
        email_alerts_follow
        sms_alerts
        sms_alerts_contact
        sms_alerts_follow
        push_alerts
        push_alerts_contact
        push_alerts_follow
        hide_account
        premium_account
        premium_account_lvl
        last_active
        following {
          items {
            id
            follower_id
            following_id
            createdAt
            updatedAt
          }
          nextToken
        }
        blocked {
          items {
            id
            blocker_id
            blocked_id
            createdAt
            updatedAt
          }
          nextToken
        }
        hidden {
          items {
            id
            reporter_id
            hidden_id
            createdAt
            updatedAt
          }
          nextToken
        }
        favorites {
          items {
            id
            post_id
            user_id
            created_at
            createdAt
            updatedAt
          }
          nextToken
        }
        updated_by
        createdAt
        updatedAt
        posts {
          items {
            id
            post_id
            is_active
            is_approved
            is_flagged
            author_id
            content
            post_video
            post_thumbnail
            post_type
            lat_lon
            loc_name
            updated_by
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      reported {
        id
        post_id
        is_active
        is_approved
        is_flagged
        author_id
        content
        post_video
        post_thumbnail
        post_type
        lat_lon
        loc_name
        categories {
          items {
            id
            post_id
            categoryID
            createdAt
            updatedAt
          }
          nextToken
        }
        likes {
          items {
            id
            post_id
            user_id
            created_at
            createdAt
            updatedAt
          }
          nextToken
        }
        favorites {
          items {
            id
            post_id
            user_id
            created_at
            createdAt
            updatedAt
          }
          nextToken
        }
        updated_by
        createdAt
        updatedAt
        author {
          user_id
          is_active
          username
          first_name
          last_name
          phone_number
          email
          postal_code
          user_avatar
          default_signature
          profile_info
          affiliate_link
          affiliated_by
          url
          email_alerts
          email_alerts_contact
          email_alerts_follow
          sms_alerts
          sms_alerts_contact
          sms_alerts_follow
          push_alerts
          push_alerts_contact
          push_alerts_follow
          hide_account
          premium_account
          premium_account_lvl
          last_active
          following {
            nextToken
          }
          blocked {
            nextToken
          }
          hidden {
            nextToken
          }
          favorites {
            nextToken
          }
          updated_by
          createdAt
          updatedAt
          posts {
            nextToken
          }
        }
      }
    }
  }
`;
export const updateReported = /* GraphQL */ `
  mutation UpdateReported(
    $input: UpdateReportedInput!
    $condition: ModelReportedConditionInput
  ) {
    updateReported(input: $input, condition: $condition) {
      id
      reporter_id
      reported_id
      report_reason
      report_desc
      createdAt
      updatedAt
      reporter {
        user_id
        is_active
        username
        first_name
        last_name
        phone_number
        email
        postal_code
        user_avatar
        default_signature
        profile_info
        affiliate_link
        affiliated_by
        url
        email_alerts
        email_alerts_contact
        email_alerts_follow
        sms_alerts
        sms_alerts_contact
        sms_alerts_follow
        push_alerts
        push_alerts_contact
        push_alerts_follow
        hide_account
        premium_account
        premium_account_lvl
        last_active
        following {
          items {
            id
            follower_id
            following_id
            createdAt
            updatedAt
          }
          nextToken
        }
        blocked {
          items {
            id
            blocker_id
            blocked_id
            createdAt
            updatedAt
          }
          nextToken
        }
        hidden {
          items {
            id
            reporter_id
            hidden_id
            createdAt
            updatedAt
          }
          nextToken
        }
        favorites {
          items {
            id
            post_id
            user_id
            created_at
            createdAt
            updatedAt
          }
          nextToken
        }
        updated_by
        createdAt
        updatedAt
        posts {
          items {
            id
            post_id
            is_active
            is_approved
            is_flagged
            author_id
            content
            post_video
            post_thumbnail
            post_type
            lat_lon
            loc_name
            updated_by
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      reported {
        id
        post_id
        is_active
        is_approved
        is_flagged
        author_id
        content
        post_video
        post_thumbnail
        post_type
        lat_lon
        loc_name
        categories {
          items {
            id
            post_id
            categoryID
            createdAt
            updatedAt
          }
          nextToken
        }
        likes {
          items {
            id
            post_id
            user_id
            created_at
            createdAt
            updatedAt
          }
          nextToken
        }
        favorites {
          items {
            id
            post_id
            user_id
            created_at
            createdAt
            updatedAt
          }
          nextToken
        }
        updated_by
        createdAt
        updatedAt
        author {
          user_id
          is_active
          username
          first_name
          last_name
          phone_number
          email
          postal_code
          user_avatar
          default_signature
          profile_info
          affiliate_link
          affiliated_by
          url
          email_alerts
          email_alerts_contact
          email_alerts_follow
          sms_alerts
          sms_alerts_contact
          sms_alerts_follow
          push_alerts
          push_alerts_contact
          push_alerts_follow
          hide_account
          premium_account
          premium_account_lvl
          last_active
          following {
            nextToken
          }
          blocked {
            nextToken
          }
          hidden {
            nextToken
          }
          favorites {
            nextToken
          }
          updated_by
          createdAt
          updatedAt
          posts {
            nextToken
          }
        }
      }
    }
  }
`;
export const deleteReported = /* GraphQL */ `
  mutation DeleteReported(
    $input: DeleteReportedInput!
    $condition: ModelReportedConditionInput
  ) {
    deleteReported(input: $input, condition: $condition) {
      id
      reporter_id
      reported_id
      report_reason
      report_desc
      createdAt
      updatedAt
      reporter {
        user_id
        is_active
        username
        first_name
        last_name
        phone_number
        email
        postal_code
        user_avatar
        default_signature
        profile_info
        affiliate_link
        affiliated_by
        url
        email_alerts
        email_alerts_contact
        email_alerts_follow
        sms_alerts
        sms_alerts_contact
        sms_alerts_follow
        push_alerts
        push_alerts_contact
        push_alerts_follow
        hide_account
        premium_account
        premium_account_lvl
        last_active
        following {
          items {
            id
            follower_id
            following_id
            createdAt
            updatedAt
          }
          nextToken
        }
        blocked {
          items {
            id
            blocker_id
            blocked_id
            createdAt
            updatedAt
          }
          nextToken
        }
        hidden {
          items {
            id
            reporter_id
            hidden_id
            createdAt
            updatedAt
          }
          nextToken
        }
        favorites {
          items {
            id
            post_id
            user_id
            created_at
            createdAt
            updatedAt
          }
          nextToken
        }
        updated_by
        createdAt
        updatedAt
        posts {
          items {
            id
            post_id
            is_active
            is_approved
            is_flagged
            author_id
            content
            post_video
            post_thumbnail
            post_type
            lat_lon
            loc_name
            updated_by
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      reported {
        id
        post_id
        is_active
        is_approved
        is_flagged
        author_id
        content
        post_video
        post_thumbnail
        post_type
        lat_lon
        loc_name
        categories {
          items {
            id
            post_id
            categoryID
            createdAt
            updatedAt
          }
          nextToken
        }
        likes {
          items {
            id
            post_id
            user_id
            created_at
            createdAt
            updatedAt
          }
          nextToken
        }
        favorites {
          items {
            id
            post_id
            user_id
            created_at
            createdAt
            updatedAt
          }
          nextToken
        }
        updated_by
        createdAt
        updatedAt
        author {
          user_id
          is_active
          username
          first_name
          last_name
          phone_number
          email
          postal_code
          user_avatar
          default_signature
          profile_info
          affiliate_link
          affiliated_by
          url
          email_alerts
          email_alerts_contact
          email_alerts_follow
          sms_alerts
          sms_alerts_contact
          sms_alerts_follow
          push_alerts
          push_alerts_contact
          push_alerts_follow
          hide_account
          premium_account
          premium_account_lvl
          last_active
          following {
            nextToken
          }
          blocked {
            nextToken
          }
          hidden {
            nextToken
          }
          favorites {
            nextToken
          }
          updated_by
          createdAt
          updatedAt
          posts {
            nextToken
          }
        }
      }
    }
  }
`;
export const createS3Object = /* GraphQL */ `
  mutation CreateS3Object(
    $input: CreateS3ObjectInput!
    $condition: ModelS3ObjectConditionInput
  ) {
    createS3Object(input: $input, condition: $condition) {
      id
      bucket
      region
      key
      createdAt
      updatedAt
    }
  }
`;
export const updateS3Object = /* GraphQL */ `
  mutation UpdateS3Object(
    $input: UpdateS3ObjectInput!
    $condition: ModelS3ObjectConditionInput
  ) {
    updateS3Object(input: $input, condition: $condition) {
      id
      bucket
      region
      key
      createdAt
      updatedAt
    }
  }
`;
export const deleteS3Object = /* GraphQL */ `
  mutation DeleteS3Object(
    $input: DeleteS3ObjectInput!
    $condition: ModelS3ObjectConditionInput
  ) {
    deleteS3Object(input: $input, condition: $condition) {
      id
      bucket
      region
      key
      createdAt
      updatedAt
    }
  }
`;
