import { handleActions } from 'redux-actions';
import { userActions } from '../actions';

const defaultState = {
  userList: [],
  userProfile: null,
  nextToken: null,
};

const reducer = handleActions(
  {
    [userActions.setUserList]: (state, { payload: { listUsers } }) => {
      return {
        ...state,
        userList: listUsers.items,
        nextToken: listUsers.nextToken,
      };
    },
    [userActions.setUserProfile]: (state, { payload: { getUser } }) => {
      return {
        ...state,
        userProfile: getUser,
      };
    },
  },
  defaultState
);

export default reducer;
