import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { Auth } from 'aws-amplify';
import {
  Container,
  FormGroup,
  Grid,
  Button,
  makeStyles,
  Typography,
  TextField,
} from '@material-ui/core';

import Message from '../../components/Message';
import Logo from '../../assets/icons/logos/logo.svg';
import { authActions } from '../../actions';

const useStyles = makeStyles((theme) => ({
  container: {
    padding: theme.spacing(3),
  },
  confirmForm: {
    minHeight: '100vh',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  button: {
    width: 100,
    margin: theme.spacing(1),
  },
  title: {
    fontSize: 40,
    paddingTop: 30,
    paddingBottom: 30,
    color: '#3c3f46',
    textAlign: 'center',
  },
  description: {
    fontSize: 18,
    color: '#666d77',
    textAlign: 'center',
    marginBottom: 40,
  },
  bottom: {
    marginTop: 20,
  },
  logo: {
    maxWidth: '100%',
  },
}));

function ConfirmAccount() {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState(false);
  const [disabled, setDisabled] = useState(false);

  const { register, handleSubmit, errors: fieldsErrors } = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {
      email: '',
      verficationCode: '',
    },
  });

  const onSubmit = (data) => {
    setDisabled(true);
    Auth.confirmSignUp(data.email, data.verficationCode, {
      forceAliasCreation: true,
    })
      .then((response) => {
        history.push('/');
        setDisabled(false);
      })
      .catch((err) => {
        setOpen(true);
        setMessage(err.message);
        setDisabled(false);
      });
  };

  const handleClose = () => setOpen(false);

  // const resendCode = (email) => {
  //   Auth.resendSignUp(email)
  //     .then(response => {
  //       console.log(response)
  //     })
  //     .catch(err => {
  //       console.log(err)
  //     });
  // }

  return (
    <div className={classes.confirmForm}>
      <Message
        open={open}
        type="error"
        handleClose={handleClose}
        message={message}
      />
      <Container className={classes.container} maxWidth="sm">
        <Grid container justify="center">
          <img src={Logo} alt="logo" className={classes.logo} />
          <Grid item sm={12}>
            <Typography className={classes.title}>Admin Login</Typography>
            <Typography className={classes.description}>
              Enter confirmation code and email to confirm your CazvidAdmin
              account.
            </Typography>
          </Grid>
        </Grid>
        <form onSubmit={handleSubmit(onSubmit)}>
          <FormGroup>
            <TextField
              label="Email address"
              type="email"
              name="email"
              autoComplete="off"
              margin="normal"
              variant="outlined"
              error={!!fieldsErrors.email}
              inputRef={register({
                required: 'Email is Required',
                pattern: {
                  value: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                  message: 'You must provide a valid email address!',
                },
              })}
              helperText={fieldsErrors.email && fieldsErrors.email.message}
              fullWidth
            />
          </FormGroup>
          <FormGroup>
            <TextField
              name="verficationCode"
              label="Verfication Code"
              type="text"
              variant="outlined"
              margin="normal"
              error={!!fieldsErrors.verficationCode}
              inputRef={register({
                required: 'You must provide a verfication code.',
              })}
              fullWidth
              helperText={
                fieldsErrors.verficationCode &&
                fieldsErrors.verficationCode.message
              }
            />
          </FormGroup>
          <Grid
            container
            alignItems="center"
            justify="center"
            className={classes.bottom}>
            <Button
              variant="contained"
              color="primary"
              type="submit"
              disabled={disabled}>
              Confirm Account
            </Button>
          </Grid>
        </form>
      </Container>
    </div>
  );
}

export default ConfirmAccount;
