import React from 'react';
import { useSelector } from 'react-redux';
import { Route, Switch, Redirect } from 'react-router-dom';

import Login from './containers/auth/Login';
import Register from './containers/auth/Register';
import ForgotPassword from './containers/auth/ForgotPassword';
import ConfirmAccount from './containers/auth/ConfirmAccount';
import RecoverPassword from './containers/auth/RecoverPassword';
import NotFound from './containers/Notfound';
import Videos from './containers/dashboard/Videos';
import Users from './containers/dashboard/Users';
import Categories from './containers/dashboard/Categories';
import Category from './containers/dashboard/Category';
import AffiliateReports from './containers/dashboard/AffiliateReports';
import Profile from './containers/dashboard/Profile';

import AdminLayout from './layout/Admin';
import GuestRoute from './route-helpers/GuestRoute';
import PrivateRoute from './route-helpers/PrivateRoute';

function AppRouter() {
  const { isAuthenticated } = useSelector((state) => state.auth);

  return (
    <>
      {isAuthenticated ? (
        <AdminLayout>
          <Switch>
            <PrivateRoute
              exact
              path="/dashboard/videos"
              canAccess={isAuthenticated}
              component={Videos}
            />
            <PrivateRoute
              exact
              path="/dashboard/categories"
              canAccess={isAuthenticated}
              component={Categories}
            />
            <PrivateRoute
              exact
              path="/dashboard/category/:id"
              canAccess={isAuthenticated}
              component={Category}
            />
            <PrivateRoute
              exact
              path="/dashboard/category"
              canAccess={isAuthenticated}
              component={Category}
            />
            <PrivateRoute
              exact
              path="/dashboard/users"
              canAccess={isAuthenticated}
              component={Users}
            />
            <PrivateRoute
              exact
              path="/dashboard/affiliatereports"
              canAccess={isAuthenticated}
              component={AffiliateReports}
            />
            <PrivateRoute
              path="/dashboard/user/profile/:id"
              canAccess={isAuthenticated}
              component={Profile}
            />
            <Redirect to="/dashboard/videos" />
          </Switch>
        </AdminLayout>
      ) : (
        <Switch>
          <GuestRoute
            exact
            path="/"
            component={Login}
            canAccess={isAuthenticated}
          />
          <GuestRoute
            exact
            path="/register"
            component={Register}
            canAccess={isAuthenticated}
          />
          <GuestRoute
            exact
            path="/forgot-password"
            component={ForgotPassword}
            canAccess={isAuthenticated}
          />
          <GuestRoute
            exact
            path="/confirm-account"
            component={ConfirmAccount}
            canAccess={isAuthenticated}
          />
          <GuestRoute
            exact
            path="/recover-password"
            component={RecoverPassword}
          />
          <Route component={NotFound} />
        </Switch>
      )}
    </>
  );
}

export default AppRouter;
