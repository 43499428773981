import React from 'react';
import VideoLibraryIcon from '@material-ui/icons/VideoLibrary';
import PeopleIcon from '@material-ui/icons/People';
import CategoryIcon from '@material-ui/icons/Category';
import AssessmentIcon from '@material-ui/icons/Assessment';

import ListItemLink from './ListItemLink';

export const mainListItems = (
  <div>
    <ListItemLink
      icon={<VideoLibraryIcon />}
      primary="Videos"
      to="/dashboard/videos"
    />
    <ListItemLink icon={<PeopleIcon />} primary="Users" to="/dashboard/users" />
    <ListItemLink
      icon={<CategoryIcon />}
      primary="Categories"
      to="/dashboard/categories"
    />
    <ListItemLink icon={<AssessmentIcon />} primary="Affiliate Report" to="/dashboard/affiliatereports" />
  </div>
);
