import React, { useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { Auth } from 'aws-amplify';
import {
  Container,
  FormGroup,
  Grid,
  Button,
  makeStyles,
  Typography,
  TextField,
} from '@material-ui/core';

import Message from '../../components/Message';

const useStyles = makeStyles((theme) => ({
  container: {
    padding: theme.spacing(3),
  },
  forgotForm: {
    minHeight: '100vh',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  title: {
    fontSize: 40,
    paddingTop: 30,
    paddingBottom: 30,
    color: '#3c3f46',
  },
  description: {
    fontSize: 18,
    color: '#666d77',
    marginBottom: 40,
  },
  bottom: {
    marginTop: 20,
  },
}));

function ForgotPassword({ onSubmitHandle }) {
  const classes = useStyles();
  const history = useHistory();
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState(false);
  const [disabled, setDisabled] = useState(false);

  const { register, handleSubmit, errors: fieldsErrors } = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {
      email: '',
    },
  });

  const onSubmit = (data) => {
    setDisabled(true);
    Auth.forgotPassword(data.email)
      .then((response) => {
        setDisabled(false);
        history.push('/recover-password');
      })
      .catch((err) => {
        setOpen(true);
        setMessage(err.message);
        setDisabled(false);
      });
  };

  const handleClose = () => setOpen(false);

  return (
    <div className={classes.forgotForm}>
      <Message
        open={open}
        type="error"
        handleClose={handleClose}
        message={message}
      />
      <Container className={classes.container} maxWidth="sm">
        <Grid container justify="center">
          <Grid item sm={12}>
            <Typography className={classes.title}>Dont worry!</Typography>
            <Typography className={classes.description}>
              We are here to help you recovering your password. Please enter the
              email address you used when you joined and we will send you an
              email with the instructions to reset your password.
            </Typography>
          </Grid>
        </Grid>
        <form onSubmit={handleSubmit(onSubmitHandle || onSubmit)}>
          <FormGroup>
            <TextField
              label="Email address"
              type="email"
              name="email"
              autoComplete="off"
              margin="normal"
              variant="outlined"
              error={!!fieldsErrors.email}
              inputRef={register({
                required: 'Email is Required',
                pattern: {
                  value: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                  message: 'You must provide a valid email address!',
                },
              })}
              helperText={fieldsErrors.email && fieldsErrors.email.message}
              fullWidth
            />
          </FormGroup>
          <Grid
            container
            alignItems="center"
            justify="space-between"
            className={classes.bottom}>
            <Grid item sm={12} md={6}>
              <Link to={'/'}>Go back to Login</Link>
            </Grid>
            <Grid item sm={12} md={6}>
              <Grid container justify="flex-end">
                <Button
                  variant="contained"
                  color="primary"
                  type="submit"
                  disabled={disabled}>
                  Recover Password
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </form>
      </Container>
    </div>
  );
}

export default ForgotPassword;
