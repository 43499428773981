import React, { useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { Auth } from 'aws-amplify';
import {
  Container,
  FormGroup,
  InputAdornment,
  IconButton,
  Grid,
  Button,
  makeStyles,
  Typography,
  TextField,
} from '@material-ui/core';
import { Visibility, VisibilityOff } from '@material-ui/icons';

import Message from '../../components/Message';

const useStyles = makeStyles((theme) => ({
  container: {
    padding: theme.spacing(3),
  },
  recoveryContainer: {
    minHeight: '100vh',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  button: {
    width: 100,
    margin: theme.spacing(1),
  },
  title: {
    fontSize: 40,
    paddingTop: 30,
    paddingBottom: 30,
    color: '#3c3f46',
  },
  description: {
    fontSize: 18,
    color: '#666d77',
    marginBottom: 40,
  },
  bottom: {
    marginTop: 20,
  },
  logo: {
    maxWidth: '100%',
  },
  buttonWrapper: {
    [theme.breakpoints.down('xs')]: {
      justifyContent: 'space-between',
    },
  },
}));

function RecoverPassword() {
  const classes = useStyles();
  const history = useHistory();
  const [showPassword, setShowPassword] = useState(false);
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const { register, handleSubmit, errors: fieldsErrors } = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {
      email: '',
      password: '',
      verficationCode: '',
    },
  });

  const onSubmit = (data) => {
    setDisabled(true);
    Auth.forgotPasswordSubmit(data.email, data.verficationCode, data.password)
      .then((response) => {
        setDisabled(false);
        history.push('/');
      })
      .catch((err) => {
        setOpen(true);
        setMessage(err.message);
        setDisabled(false);
      });
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleClose = () => setOpen(false);

  return (
    <div className={classes.recoveryContainer}>
      <Message
        open={open}
        type="error"
        handleClose={handleClose}
        message={message}
      />
      <Container className={classes.container} maxWidth="sm">
        <Grid container justify="center">
          <Grid item sm={12}>
            <Typography className={classes.title}>Dont worry!</Typography>
            <Typography className={classes.description}>
              We are here to help you recovering your password. Please enter the
              email address you used when you joined and we will send you an
              email with the instructions to reset your password.
            </Typography>
          </Grid>
        </Grid>
        <form onSubmit={handleSubmit(onSubmit)}>
          <FormGroup>
            <TextField
              label="Email address"
              type="email"
              name="email"
              autoComplete="off"
              margin="normal"
              variant="outlined"
              error={!!fieldsErrors.email}
              inputRef={register({
                required: 'Email is Required',
                pattern: {
                  value: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                  message: 'You must provide a valid email address!',
                },
              })}
              helperText={fieldsErrors.email && fieldsErrors.email.message}
              fullWidth
            />
          </FormGroup>
          <FormGroup>
            <TextField
              name="password"
              label="Password"
              type={showPassword ? 'text' : 'password'}
              variant="outlined"
              margin="normal"
              error={!!fieldsErrors.password}
              inputRef={register({
                required: 'You must provide a password.',
              })}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}>
                      {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              fullWidth
              autoComplete="current-password"
              helperText={
                fieldsErrors.password && fieldsErrors.password.message
              }
            />
          </FormGroup>
          <FormGroup>
            <TextField
              name="verficationCode"
              label="Verfication Code"
              type="text"
              variant="outlined"
              margin="normal"
              error={!!fieldsErrors.verficationCode}
              inputRef={register({
                required: 'You must provide a verfication code.',
              })}
              fullWidth
              helperText={
                fieldsErrors.verficationCode &&
                fieldsErrors.verficationCode.message
              }
            />
          </FormGroup>
          <Grid
            container
            alignItems="center"
            justify="space-between"
            className={classes.bottom}>
            <Grid item sm={12} md={6}>
              <Link to={'/'}>Go back to Login</Link>
            </Grid>
            <Grid item sm={12} md={6}>
              <Grid container justify="flex-end">
                <Button
                  variant="contained"
                  color="primary"
                  type="submit"
                  disabled={disabled}>
                  Recover Password
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </form>
      </Container>
    </div>
  );
}

export default RecoverPassword;
