/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { Paper, Grid, Button } from '@material-ui/core';
import { API, graphqlOperation } from 'aws-amplify';

import { listReportPosts } from '../../api/listReportPosts';
import Title from '../../components/Title';
import CustomTable from '../../components/Table';
import SearchInput from '../../components/SearchInput';
import LoadingBar from '../../components/Loading';
import { affiliateReport } from '../../actions';

import moment from 'moment'

moment.updateLocale('en', {
  week: { dow: 1 }
});

const useStyles = makeStyles((theme) => ({
  seeMore: {
    marginTop: theme.spacing(3),
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  action: {
    display: 'flex',
    justifyContent: 'space-between',
    width: 100,
    float: 'right',
  },
  title: {
    marginBottom: theme.spacing(2),
  },
}));

const headers = [
  { id: 'affiliated_by', label: 'Affiliate Name', minWidth: 170, maxWidth: 250 },
  { id: 'thisWeek', label: 'This Week (' + moment().startOf('week').format('M-D-YY') + ' thru ' + moment().endOf('week').format('M-D-YY') + ')', align: 'right' },
  { id: 'lastWeek', label: 'Last Week (' + moment(Date.now() - 7 * 24 * 3600 * 1000).startOf('week').format('M-D-YY') + ' thru ' + moment(Date.now() - 7 * 24 * 3600 * 1000).endOf('week').format('M-D-YY') + ')', align: 'right' },
  { id: 'thisMonth', label: 'This Month (' + moment().format('MMM YYYY') + ')', align: 'right' },
  { id: 'total', label: 'All Time', align: 'right' },
];

function createData(affiliated_by, thisWeek, lastWeek, thisMonth, total) {
  return {
    affiliated_by,
    thisWeek,
    lastWeek,
    thisMonth,
    total,
  };
}

function GetSortOrder(prop) {    
    return function(a, b) {    
        if (a[prop] > b[prop]) {    
            return 1;    
        } else if (a[prop] < b[prop]) {    
            return -1;    
        }    
        return 0;    
    }    
}

function processJSON(data) {

  console.log('process json', data);



  var thisWeek = moment().week();
  var thisWeekYear = moment().year();
  var lastWeek = (thisWeek == 1 ? 52 : thisWeek - 1);  
  var thisMonth = moment().month();
  var thisYear = moment().year();

  const rows = {};

  data.forEach((v) => {
    var key = v.author.affiliated_by;
    var dateCreated = v.createdAt;

    var addThisWeek = (moment(dateCreated).week() == thisWeek ? 1 : 0); 
    var addLastWeek = (moment(dateCreated).week() == lastWeek ? 1 : 0); 
    var addthisMonth = (moment(dateCreated).month() == thisMonth ? 1 : 0); 

    if (key !== null && key.toString() !== '' ){
      key = key.toUpperCase().trim();
      if(rows[key]){
        rows[key].thisWeek += addThisWeek;
        rows[key].lastWeek += addLastWeek;
        rows[key].thisMonth += addthisMonth;
        rows[key].total += 1;
      }
      else{
        rows[key] = { affiliated_by: v.author.affiliated_by, thisWeek: addThisWeek, lastWeek: addLastWeek, thisMonth: addthisMonth, total: 1 };
      }
    }
  });

  var ret = [];

  for(var i in rows){
    var r = ([i, rows [i]]);
    var newR = {  affiliated_by: r[1].affiliated_by,
              thisWeek: r[1].thisWeek,
              lastWeek: r[1].lastWeek,
              thisMonth:r[1].thisMonth,
              total: r[1].total
            }

    ret.push(newR);
  }

  return ret;  
}

export default function AffiliateReports() {
  const classes = useStyles();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState();
  const [rows, setRows] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const history = useHistory();
  const dispatch = useDispatch();
  const { categories } = {};

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(event.target.value);
    setPage(0);
  };

  const handleRequestSort = (event, property) => {
    if (property === 'action') return;
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  useEffect(() => {
    setLoading(true);
    getAllReportPosts();
  }, []);

  useEffect(() => {
    generateRows(categories);
  }, [categories]);

  const getAllReportPosts = async (nextToken = null, list) => {
    try {
      var { data } = await API.graphql(graphqlOperation(listReportPosts, {
        filter: {
          is_approved: {
              eq: 1
          },
        },
        limit: 1000,
        nextToken: nextToken
      }));
      const newList = data.listPosts.items;
      list && newList.push(...list);
      
      data.listPosts.nextToken && getAllReportPosts(data.listPosts.nextToken, newList);

      if (!data.listPosts.nextToken) {
        data = processJSON(newList);
        generateRows(data);
        setLoading(false);
      };
    } catch (error) {
      console.log('error on fetching follows', error);
      setLoading(false);
    }
  };

  const generateRows = (data) => {
    if (data){
      const rows = [];
      data.forEach((v) => {
        rows.push(
          createData(
            v.affiliated_by,
            v.thisWeek,
            v.lastWeek,
            v.thisMonth,
            v.total
          )
        );
      });
      setRows(rows);
    }
  };

  const handleSearch = (e) => {
    const filtedCategories = categories.filter((v) =>
      v.title?.toLowerCase().includes(e.target.value.toLowerCase())
    );
    generateRows(filtedCategories);
  };

  return (
    <>
      <Paper className={classes.paper}>
        <Grid container justify="space-between" className={classes.title}>
          <Title>Affiliate Report</Title>
        </Grid>
        <CustomTable
          headers={headers}
          rows={rows}
          page={page}
          rowsPerPage={rowsPerPage}
          handleChangePage={handleChangePage}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
          handleRequestSort={handleRequestSort}
          order={order}
          orderBy={orderBy}
        />
      </Paper>
      {isLoading && <LoadingBar />}
    </>
  );
}
