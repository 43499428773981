import { handleActions } from 'redux-actions';
import { videoActions } from '../actions';

const defaultState = {
  posts: [],
  reporteds: [],
  categories: [],
  nextPostToken: null,
  nextReportedToken: null,
  nextCategoryToken: null,
};

const reducer = handleActions(
  {
    [videoActions.setVideoCategoryList]: (
      state,
      { payload: { listCategorys } }
    ) => {
      return {
        ...state,
        categories: listCategorys.items,
        nextCategoryToken: listCategorys.nextToken,
      };
    },
    [videoActions.setPostList]: (state, { payload: { listPosts } }) => {
      listPosts?.items.map((row) => {
        row.user_name = `${row.author?.first_name} ${row.author?.last_name}`;
        row.default_signature = row.author?.default_signature;
        return row;
      });
      return {
        ...state,
        posts: listPosts?.items,
        nextPostToken: listPosts?.nextToken,
      };
    },
    [videoActions.setReportedList]: (state, { payload: { listReporteds } }) => {
      return {
        ...state,
        reporteds: listReporteds.items,
        nextReportedToken: listReporteds.nextToken,
      };
    },
    [videoActions.updateVideoPost]: (state, { payload: { updatePost } }) => {
      const posts = [...state.posts];
      const updatedPosts = posts.map((v) => {
        if (v.id === updatePost.id) {
          v.is_approved = updatePost.is_approved;
        }
        return v;
      });
      return {
        ...state,
        posts: updatedPosts,
      };
    },
    [videoActions.deleteVideoPost]: (state, { payload: { deletePost } }) => {
      const posts = [...state.posts];
      const updatedPosts = posts.filter((v) => v.id !== deletePost?.id);
      return {
        ...state,
        posts: updatedPosts,
      };
    },
  },
  defaultState
);

export default reducer;
