import React from 'react';
import { makeStyles, Typography } from '@material-ui/core';
import IconError from '@material-ui/icons/Error';

const useStyles = makeStyles((theme) => ({
  flexCenter: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  icon: {
    display: 'block',
    width: 64,
    height: 64,
    opacity: 0.5,
  },
}));

function NotFound() {
  const classes = useStyles();
  return (
    <div className={classes.flexCenter}>
      <IconError className={classes.icon} color="action" />
      <Typography component="h1" variant="h6" align="center" gutterBottom>
        {'This page does not exist.'}
      </Typography>
    </div>
  );
}

export default NotFound;
