import React, { useState, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { Auth } from 'aws-amplify';
import {
  Container,
  FormGroup,
  FormControlLabel,
  InputAdornment,
  IconButton,
  Grid,
  Button,
  makeStyles,
  Typography,
  Checkbox,
  TextField,
} from '@material-ui/core';
import { Visibility, VisibilityOff } from '@material-ui/icons';

import Message from '../../components/Message';
import Logo from '../../assets/icons/logos/logo.svg';
import { authActions } from '../../actions';

const useStyles = makeStyles((theme) => ({
  container: {
    padding: theme.spacing(3),
  },
  registerForm: {
    minHeight: '100vh',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  formWrapper: {
    marginTop: 40,
  },
  title: {
    fontSize: 40,
    paddingTop: 30,
    paddingBottom: 30,
    color: '#3c3f46',
    textAlign: 'center',
  },
  description: {
    fontSize: 18,
    color: '#666d77',
    textAlign: 'center',
  },
  text: {
    fontSize: 16,
    color: '#666d77',
  },
  bottom: {
    marginTop: 20,
  },
  terms: {
    marginTop: 20,
  },
  logo: {
    maxWidth: '100%',
  },
}));

function Register({ onSubmitHandle }) {
  const classes = useStyles();
  const [showPassword, setShowPassword] = useState(false);
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const history = useHistory();
  const dispatch = useDispatch();
  const { register, handleSubmit, errors: fieldsErrors, watch } = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {
      firstName: '',
      lastName: '',
      email: '',
      password: '',
      country: '',
      agree: false,
    },
  });
  const password = useRef({});
  password.current = watch('password', '');

  const onSubmit = (data) => {
    setDisabled(true);
    const user = {
      username: data.email,
      password: data.password,
      attributes: {
        email: data.email,
      },
    };

    Auth.signUp(user)
      .then((response) => {
        setDisabled(false);
        history.push('/');
      })
      .catch((err) => {
        setOpen(true);
        setMessage(err.message);
        setDisabled(false);
      });
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleClose = () => setOpen(false);

  return (
    <div className={classes.registerForm}>
      <Message
        open={open}
        type="error"
        handleClose={handleClose}
        message={message}
      />
      <Container className={classes.container} maxWidth="sm">
        <Grid container justify="center">
          <img src={Logo} alt="logo" className={classes.logo} />
          <Grid item xs={12}>
            <Typography className={classes.title}>Welcome!</Typography>
            <Typography className={classes.description}>
              {' '}
              Sign up now to receive exclusive offers and promotions.
            </Typography>
            <Typography className={classes.description}>
              {' '}
              Already have an account? <Link to="/">Log In</Link>
            </Typography>
          </Grid>
        </Grid>
        <form
          onSubmit={handleSubmit(onSubmitHandle || onSubmit)}
          noValidate
          className={classes.formWrapper}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <FormGroup>
                <TextField
                  label="First Name"
                  type="text"
                  name="firstName"
                  margin="normal"
                  variant="outlined"
                  error={!!fieldsErrors.firstName}
                  inputRef={register({
                    required: 'First Name is Required',
                  })}
                  helperText={
                    fieldsErrors.firstName && fieldsErrors.firstName.message
                  }
                  fullWidth
                />
              </FormGroup>
            </Grid>
            <Grid item xs={12} md={6}>
              <FormGroup>
                <TextField
                  label="Last Name"
                  type="text"
                  name="lastName"
                  margin="normal"
                  variant="outlined"
                  error={!!fieldsErrors.lastName}
                  inputRef={register({
                    required: 'Last Name is Required',
                  })}
                  helperText={
                    fieldsErrors.lastName && fieldsErrors.lastName.message
                  }
                  fullWidth
                />
              </FormGroup>
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <FormGroup>
                <TextField
                  label="Email address"
                  type="email"
                  name="email"
                  autoComplete="off"
                  margin="normal"
                  variant="outlined"
                  error={!!fieldsErrors.email}
                  inputRef={register({
                    required: 'Email is Required',
                    pattern: {
                      value: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                      message: 'You must provide a valid email address!',
                    },
                  })}
                  helperText={fieldsErrors.email && fieldsErrors.email.message}
                  fullWidth
                />
              </FormGroup>
            </Grid>
            <Grid item xs={12} md={6}>
              <FormGroup>
                <TextField
                  label="Country"
                  name="country"
                  type="text"
                  margin="normal"
                  variant="outlined"
                  error={!!fieldsErrors.country}
                  inputRef={register({
                    required: 'Country is Required',
                  })}
                  helperText={
                    fieldsErrors.country && fieldsErrors.country.message
                  }
                  fullWidth
                />
              </FormGroup>
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <FormGroup>
                <TextField
                  name="password"
                  label="Password"
                  type={showPassword ? 'text' : 'password'}
                  variant="outlined"
                  margin="normal"
                  error={!!fieldsErrors.password}
                  inputRef={register({
                    required: 'You must provide a password.',
                    minLength: {
                      value: 8,
                      message: 'Password must have at least 8 characters',
                    },
                  })}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}>
                          {showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  fullWidth
                  helperText={
                    fieldsErrors.password && fieldsErrors.password.message
                  }
                />
              </FormGroup>
            </Grid>
            <Grid item xs={12} md={6}>
              <FormGroup>
                <TextField
                  name="confirmPass"
                  label="Confirm Password"
                  type="password"
                  variant="outlined"
                  margin="normal"
                  error={!!fieldsErrors.confirmPass}
                  inputRef={register({
                    validate: (value) =>
                      value === password.current ||
                      'The passwords do not match',
                  })}
                  fullWidth
                  helperText={
                    fieldsErrors.confirmPass && fieldsErrors.confirmPass.message
                  }
                />
              </FormGroup>
            </Grid>
          </Grid>
          <Grid container className={classes.terms}>
            <Grid item xs={12}>
              <FormControlLabel
                label="I agree to the Terms and Privacy Policy"
                name="agree"
                className={classes.text}
                control={<Checkbox inputRef={register()} color="primary" />}
              />
            </Grid>
            <Grid item xs={12}>
              <Typography className={classes.text}>
                By creating an account, you agree to the site{' '}
                <a href="#/" target="_blank">
                  Terms of Service
                </a>{' '}
                and{' '}
                <a href="#/" target="_blank">
                  Privacy Policy
                </a>
              </Typography>
            </Grid>
          </Grid>
          <Grid container justify="flex-end" className={classes.bottom}>
            <Button
              variant="contained"
              color="primary"
              type="submit"
              disabled={disabled}>
              Create account
            </Button>
          </Grid>
        </form>
      </Container>
    </div>
  );
}

export default Register;
