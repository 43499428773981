/* eslint-disable import/no-anonymous-default-export */
import auth from './auth';
import user from './user';
import category from './category';
import video from './video';

export default {
  auth,
  user,
  category,
  video,
};
