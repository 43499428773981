import { all } from 'redux-saga/effects'

// Sagas

/* ------------- Connect Types To Sagas ------------- */
export default function* root() {
  yield all([

  ])
}
