/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton'
import Select from '@material-ui/core/Select';
import Dialog from '@material-ui/core/Dialog';
import TextField from '@material-ui/core/TextField';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { green } from '@material-ui/core/colors';
import MenuItem from '@material-ui/core/MenuItem';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import Modal from '@material-ui/core/Modal';
import { API, graphqlOperation } from 'aws-amplify';
import moment from 'moment';
import axios from 'axios';
import { S3Image } from 'aws-amplify-react';

import { listPosts, listReporteds, listCategorys } from '../../graphql/queries';
import { updatePost, deletePost } from '../../graphql/mutations';
import CustomTable from '../../components/Table';
import CustomTab from '../../components/Tabs';
import SearchInput from '../../components/SearchInput';
import LoadingBar from '../../components/Loading';
import Message from '../../components/Message';
import VideoDialog from './VideoDialog';
import { videoActions } from '../../actions';

function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

const useStyles = makeStyles((theme) => ({
  seeMore: {
    marginTop: theme.spacing(3),
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  thumbnail: {
    width: 50,
    height: 75,
  },
  button: {
    marginTop: theme.spacing(1),
    width: 150,
    color: '#ffffff',
  },
  select: {
    minWidth: 100,
  },
  editButton: {
    color: '#000000',
    float: 'left',
    marginRight: '15px',
  },
  saveButton: {
    marginTop: 24,
    width: 150,
    color: '#ffffff',
  }
}));

const styles = makeStyles((theme) => ({
  paper: {
    position: 'absolute',
    width: theme.spacing.unit * 50,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing.unit * 10,
  },
}));

const ColorButton = withStyles((theme) => ({
  root: {
    color: '#ffffff',
    backgroundColor: green[500],
    '&:hover': {
      backgroundColor: green[700],
    },
  },
}))(Button);

const headers = [
  { id: 'action', label: '' },
  { id: 'deleted', label: '' },
  {
    id: 'video',
    label: 'Video',
    minWidth: 170,
    align: 'center',
  },
  {
    id: 'post_type',
    label: 'Post Type',
    minWidth: 170,
  },
  {
    id: 'language',
    label: 'Language',
    minWidth: 170,
  },
  {
    id: 'content',
    label: 'Description',
    minWidth: 200,
  },
  // {
  //   id: 'category',
  //   label: 'Category',
  //   minWidth: 170,
  // },
  {
    id: 'createdAt',
    label: 'Date',
    minWidth: 190,
  },
  { id: 'user_name', label: 'User', minWidth: 170 },
  { id: 'user_avatar', label: 'Profile Picture', minWidth: 170, align: 'center' },
  {
    id: 'loc_name',
    label: 'Location',
    minWidth: 170,
  },
  {
    id: 'user_email',
    label: 'User Email',
    minWidth: 170
  },
  {
    id: 'user_phone',
    label: 'User Phone',
    minWidth: 170
  },
  {
    id: 'referred_by',
    label: 'Referred By (Manual)',
    minWidth: 170
  },
  // {
  //   id: 'affiliated_by',
  //   label: 'Affiliated By (Automatic)',
  //   minWidth: 170
  // }
];

function createData(
  user_name,
  user_email,
  referred_by,
  // affiliated_by,
  // category,
  loc_name,
  post_type,
  createdAt,
  content,
  video,
  action,
  deleted,
  user_avatar,
  language,
  user_phone
) {
  return {
    user_name,
    user_email,
    referred_by,
    // affiliated_by,
    // category,
    loc_name,
    post_type,
    createdAt,
    content,
    video,
    action,
    deleted,
    user_avatar,
    language,
    user_phone
  };
}

const LABELS = [
  { id: 'all', label: 'ALL' },
  { id: 'waiting', label: 'WAITING APPROVAL' },
  { id: 'approved', label: 'APPROVED' },
  { id: 'rejected', label: 'REJECTED' },
  { id: 'flag', label: 'FLAGGED' },
];

export default function Videos() {
  const classes = useStyles();
  const modalClasses = styles();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [order, setOrder] = useState('desc');
  const [orderBy, setOrderBy] = useState('createdAt');
  const [rows, setRows] = useState([]);
  const [type, setType] = useState(0);
  const [category, setCategory] = useState();
  const [description, setDescription] = useState('');
  const [editedDescriptionID, setEditedDescriptionID] = useState();
  const [descriptionModalVisible, setDescriptionModalVisible] = useState(false);
  const [postType, setPostType] = useState();
  const [videoUrl, setVideoUrl] = useState();
  const [open, setOpenModal] = useState(false);
  const [openMessage, setMessageOpen] = useState(false);
  const [message, setMessage] = useState('');
  const [messageType, setMessageType] = useState('');
  const [loading, setLoading] = useState(false);
  const [filterKey, setFilterKey] = useState('');
  const [filteredPosts, setFilteredPosts] = useState([]);
  const [deleteModal, setDeleteModal] = useState(false);
  const [selectedVideo, setSelectedVideo] = useState(null);
  const dispatch = useDispatch();
  const { reporteds } = useSelector((state) => state.video);
  const [categories, setCategories] = useState();
  const [posts, setPosts] = useState();

  useEffect(() => {
    setLoading(true);
    Promise.all([getListCategories(), getListReporteds(), getListPosts()]);
  }, []);

  useEffect(() => {
    onSelectLabel(type);
  }, [posts]);

  useEffect(() => {
    if (filterKey) {
      updateRows(
        filteredPosts.filter(
          (v) =>
            v.author?.first_name?.toLowerCase().includes(filterKey) ||
            v.category?.toLowerCase().includes(filterKey) ||
            v.loc_name?.toLowerCase().includes(filterKey) ||
            v.post_type?.toLowerCase().includes(filterKey) ||
            v.createdAt?.toLowerCase().includes(filterKey) ||
            v.content?.toLowerCase().includes(filterKey) ||
            v.author?.email?.toLowerCase().includes(filterKey) ||
            v.author?.affiliated_by?.toLowerCase().includes(filterKey) ||
            v?.location?.toLowerCase().includes(filterKey)
        )
      );
    } else {
      filteredPosts && updateRows(filteredPosts);
    }
  }, [filteredPosts]);

  const getListCategories = async () => {
    try {
      const { data } = await API.graphql(graphqlOperation(listCategorys));
      setCategories(data.listCategorys.items);
      dispatch(videoActions.setVideoCategoryList(data));
    } catch (error) {
      console.log('error get list categories', error);
      setLoading(false);
    }
  };

  const getListPosts = async () => {
    try {
      const { data } = await API.graphql(
        graphqlOperation(listPosts, { limit: 1000 })
      );
      setPosts(data.listPosts.items);
      getMorePosts(data.listPosts.nextToken, data.listPosts.items);
    } catch (error) {
      console.log('error on get lists posts', error);
      setLoading(false);
    }
  };

  const getMorePosts = async (nextToken, list) => {
    setLoading(true);
    try {
      const { data } = await API.graphql(
        graphqlOperation(listPosts, { limit: 1000, nextToken: nextToken })
      );
      const newList = data.listPosts.items;
      newList.push(...list);
      setPosts(newList);
      data.listPosts.nextToken && getMorePosts(data.listPosts.nextToken, newList);
      !data.listPosts.nextToken && setLoading(false);
    } catch (error) {
      console.log('error on get lists posts', error);
      setLoading(false);
    }
  };

  const getListReporteds = async () => {
    try {
      const { data } = await API.graphql(graphqlOperation(listReporteds));
      dispatch(videoActions.setReportedList(data));
    } catch (error) {
      console.log('error on get list reporteds', error);
      setLoading(false);
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(event.target.value);
    setPage(0);
  };

  const handleRequestSort = (event, property) => {
    if (property === 'video' || property === 'deleted' || property === 'action')
      return;
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangeCategory = (event) => setCategory(event.target.value);

  const handleChangeDescription = (event, video) => {
    console.log('on change description', event.target.value)
    setDescription(event.target.value)
  }

  const handleChangePostType = (event, video) => {
    console.log('change post type', event.target.value, video);
    const updatedData = {
      id: video.id,
      post_type: event.target.value,
    };
    updateVideo(updatedData);
  };

  const handleChangeLanguage = (event, video) => {
    const updatedData = {
      id: video.id,
      language: event.target.value,
    };
    updateVideo(updatedData);
  };

  const updateRows = (filteredPosts) => {
    const rows = [];
    console.log('filtered posts', filteredPosts);
    filteredPosts.forEach((v) => {
      rows.push(
        createData(
          v.author?.first_name?.replaceAll('null', ''),
          // <>
          //   {v.categories.items.filter((_v) => _v).length > 0 && (
          //     <Select
          //       labelId="select-helper-label"
          //       id="select-helper"
          //       value={category}
          //       onChange={handleChangeCategory}
          //       className={classes.select}>
          //       {v.categories.items
          //         .filter((_v) => _v)
          //         .map((item) => (
          //           <MenuItem value={item.categoryID} key={item.id}>
          //             {item.category.title}
          //           </MenuItem>
          //         ))}
          //     </Select>
          //   )}
          // </>,
          v.author?.email,
          v.author?.affiliated_by,
          // v.author?.affiliated_by_link,
          v.loc_name,
          <>
            <Select
              labelId="post-type"
              id="post-type-select"
              value={v.post_type}
              onChange={(event) => handleChangePostType(event, v)}
            >
              <MenuItem value={'opp'}>opp</MenuItem>
              <MenuItem value={'seeker'}>seeker</MenuItem>
            </Select>
          </>,
          v.createdAt.replace('T', ' '),
          <>
            <IconButton
              color="#000000"
              className={classes.editButton}
              onClick={() => {
                setDescription(v.content)
                setEditedDescriptionID(v.id)
                setDescriptionModalVisible(true)
              }}>
              <EditIcon />
            </IconButton>
            <div>{v.content}</div>
          </>,
          <img
            src={v.post_thumbnail}
            alt="thumbnail"
            className={classes.thumbnail}
            onClick={() => showVideo(v.post_video)}
          />,
          <div>
            {type === 0 || type === 1 ? (
              <>
                {v.is_approved === 1 && (
                  <ColorButton
                    variant="contained"
                    color="green"
                    onClick={() => rejectVideo(v)}
                    className={classes.button}
                    startIcon={<CheckIcon />}>
                    Approved
                  </ColorButton>
                )}
                {v.is_approved === 2 && (
                  <Button
                    variant="contained"
                    color="secondary"
                    aria-label="close"
                    className={classes.button}
                    startIcon={<CloseIcon />}
                    onClick={() => approveVideo(v)}>
                    Rejected
                  </Button>
                )}
                {v.is_approved !== 1 && v.is_approved !== 2 && (
                  <>
                    <ColorButton
                      variant="contained"
                      color="green"
                      onClick={() => approveVideo(v)}
                      className={classes.button}
                      startIcon={<CheckIcon />}>
                      Approve
                    </ColorButton>
                    <Button
                      variant="contained"
                      color="secondary"
                      aria-label="close"
                      className={classes.button}
                      startIcon={<CloseIcon />}
                      onClick={() => rejectVideo(v)}>
                      Reject
                    </Button>
                  </>
                )}
              </>
            ) : type === 2 ? (
              <ColorButton
                variant="contained"
                color="green"
                onClick={() => rejectVideo(v)}
                className={classes.button}
                startIcon={<CheckIcon />}>
                Approved
              </ColorButton>
            ) : type === 3 ? (
              <Button
                variant="contained"
                color="secondary"
                aria-label="close"
                className={classes.button}
                startIcon={<CheckIcon />}
                onClick={() => approveVideo(v)}>
                Rejected
              </Button>
            ) : (
              <>
                <ColorButton
                  variant="contained"
                  color="primary"
                  onClick={() => approveVideo(v)}
                  className={classes.button}
                  startIcon={<CheckIcon />}>
                  Approve
                </ColorButton>
                <Button
                  variant="contained"
                  color="secondary"
                  aria-label="close"
                  className={classes.button}
                  startIcon={<CloseIcon />}
                  onClick={() => rejectVideo(v)}>
                  Reject
                </Button>
              </>
            )}
          </div>,
          <Button
            variant="contained"
            color="primary"
            aria-label="delete"
            className={classes.button}
            startIcon={<DeleteIcon />}
            onClick={() => {
              setSelectedVideo(v.id);
              setDeleteModal(true);
            }}>
            Delete
          </Button>,
          <>
            {v?.user_avatar?.substring(0,5) != 'https' ? (
              <S3Image
                level="public"
                imgKey={v?.author?.user_avatar}
                theme={{ photoImg: { width: '80px', height: '80px', borderRadius: 100 } }}
              />
            ) : (
              <>
              <img src={v?.author?.user_avatar} referrerpolicy="no-referrer" width="72" height="72" />
              </>
            )
            }
          </>,
          <Select
            labelId="language"
            id="language-select"
            value={v?.language}
            onChange={(event) => handleChangeLanguage(event, v)}
          >
            <MenuItem value={'en'}>English</MenuItem>
            <MenuItem value={'es'}>Spanish</MenuItem>
            <MenuItem value={'multiple'}>Multiple</MenuItem>
            <MenuItem value={'other'}>Other</MenuItem>
          </Select>,
          v?.author?.phone_number,
        )
      );
    });
    setRows(rows);
  };

  const showVideo = (video) => {
    setVideoUrl(video);
    setOpenModal(true);
  };

  const onSelectLabel = (id) => {
    setType(id);
    let filteredPosts = [];
    if (id === 0) {
      filteredPosts = posts?.filter((v) => v.is_active === 1);
    } else if (id === 1) {
      filteredPosts = posts?.filter(
        (v) => v.is_active === 1 && !v.is_flagged && v.is_approved === 0
      );
    } else if (id === 2) {
      filteredPosts = posts?.filter(
        (v) => v.is_active === 1 && v.is_approved === 1
      );
    } else if (id === 3) {
      filteredPosts = posts?.filter(
        (v) => v.is_active === 1 && v.is_approved === 2
      );
    } else {
      const reportedVideoIds = reporteds.map((v) => v.reported_id);
      filteredPosts = posts?.filter((v) => {
        return reportedVideoIds.includes(v.id);
      });
    }
    setFilteredPosts(filteredPosts);
  };

  const approveVideo = async (video) => {
    let correctCategories = []
    for (let i = 0; i < categories.length; i++) {
      var items = categories[i].posts.items;
      var result = items.find(obj => {
         return obj.post_id == video.id
      })
      result && correctCategories.push(result.categoryID);
    }
    const videoData = {
      id: video.id,
      lat: video.lat_lon.length > 2 ? parseFloat(video.lat_lon.split(',')[0].slice(5)) : null,
      lon: video.lat_lon.length > 2 ? parseFloat(video.lat_lon.split(',')[1].slice(5 , -1)) : null,
      categories: correctCategories,
      post_type: video.post_type,
      post_thumbnail: video.post_thumbnail,
      post_video: video.post_video,
      loc_name: video.loc_name,
      content: video.content,
      author_id: video.author_id,
      author_first_name: video.author.first_name,
      author_user_avatar: video.author.user_avatar,
      author_deviceinfo: video.author.deviceInfo,
      author_devicetoken: video.author.deviceToken,
      title: video.title,
      created_at: video.createdAt,
      post_id : video.post_id
    }
    const { data } = await axios({
      headers: {'content-type': 'application/JSON'},
      method: 'POST',
      url: 'https://search.cazvid.info/api/v1/T8aYltGzhEOeukEqbD9E',
      data: videoData,
    });
    let updatedData = {
      id: video.id,
      is_approved: 1,
    };
    if (type === 4) {
      updatedData.is_flagged = 0;
    }
    updateVideo(updatedData);
  };

  const rejectVideo = async (video) => {
    const { data } = await axios({
      headers: {'content-type': 'application/JSON'},
      method: 'DELETE',
      url: 'https://search.cazvid.info/api/v1/T8aYltGzhEOeukEqbD9E',
      data: {
        id: video.id
      },
    });
    let updatedData = {
      id: video.id,
      is_approved: 2,
    };
    if (type === 4) {
      updatedData.is_flagged = 1;
    }
    updateVideo(updatedData);
  };

  const unApproveVideo = (video) => {
    const updatedData = {
      id: video.id,
      is_approved: 0,
    };
    updateVideo(updatedData);
  };

  const deleteVideo = async () => {
    setDeleteModal(false);
    const deleteData = {
      id: selectedVideo,
    };
    setLoading(true);
    try {
      const { data } = await API.graphql(
        graphqlOperation(deletePost, { input: deleteData })
      );
      dispatch(videoActions.deleteVideoPost(data));
      setMessage('Video has been deleted successfully');
      setMessageType('success');
      setMessageOpen(true);
      setLoading(false);
    } catch (err) {
      console.log(err);
      setMessageOpen(true);
      setMessage('Please contact admin user');
      setMessageType('error');
      setLoading(false);
    }
  };

  const updateVideo = async (updatedData) => {
    setLoading(true);
    try {
      const { data } = await API.graphql(
        graphqlOperation(updatePost, { input: updatedData })
      );
      console.log('update video data', data);
      dispatch(videoActions.updateVideoPost(data));
      setMessage('Video has been updated successfully');
      setMessageType('success');
      setMessageOpen(true);
      getListPosts();
    } catch (err) {
      console.log('update post error', err)
      setMessageOpen(true);
      setMessage('Please contact admin user');
      setMessageType('error');
      setLoading(false);
    }
  };

  const handleSearch = (e) => {
    setFilterKey(e.target.value.toLowerCase());
    const filtedCategories = filteredPosts.filter(
      (v) =>
        v.user_name?.toLowerCase().includes(e.target.value.toLowerCase()) ||
        v.category?.toLowerCase().includes(e.target.value.toLowerCase()) ||
        v.loc_name?.toLowerCase().includes(e.target.value.toLowerCase()) ||
        v.post_type?.toLowerCase().includes(e.target.value.toLowerCase()) ||
        v.createdAt?.toLowerCase().includes(e.target.value.toLowerCase()) ||
        v.content?.toLowerCase().includes(e.target.value.toLowerCase()) ||
        v.author?.email?.toLowerCase().includes(filterKey) ||
        v.author?.affiliated_by?.toLowerCase().includes(filterKey) ||
        v.author?.affiliated_by_link?.toLowerCase().includes(filterKey)
    );
    updateRows(filtedCategories);
  };

  const updateDescription = () => {
    updateVideo({
      id: editedDescriptionID,
      content: description
    })
  }

  return (
    <>
      <Message
        open={openMessage}
        type={messageType}
        handleClose={() => setMessageOpen(false)}
        message={message}
      />
      <Modal
        open={descriptionModalVisible}
        onClose={() => {
          setDescriptionModalVisible(false)
        }}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <div style={getModalStyle()} className={modalClasses.paper}>
          <h3>Edit Video Description</h3>
          <TextField multiline defaultValue={description} id="outlined-basic" onChange={(event) => handleChangeDescription(event)}/>
          <Button
            variant="contained"
            color="primary"
            aria-label="close"
            className={classes.saveButton}
            onClick={() => {
              setDescriptionModalVisible(false)
              updateDescription()
            }}>
            Save
          </Button>
        </div>
      </Modal>
      <Paper className={classes.paper}>
        <SearchInput onChange={handleSearch} />
        <CustomTab labels={LABELS} onSelectLabel={onSelectLabel}>
          <CustomTable
            headers={headers}
            rows={rows}
            page={page}
            rowsPerPage={rowsPerPage}
            handleChangePage={handleChangePage}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
            handleRequestSort={handleRequestSort}
            order={order}
            orderBy={orderBy}
          />
        </CustomTab>
        <VideoDialog
          open={open}
          videoUrl={videoUrl}
          handleClose={() => setOpenModal(false)}
        />
      </Paper>
      <Dialog
        open={deleteModal}
        onClose={() => setDeleteModal(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title">Comfirm Delete</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to delete this video?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDeleteModal(false)} color="primary">
            No
          </Button>
          <Button onClick={() => deleteVideo()} color="primary" autoFocus>
            Yes
          </Button>
        </DialogActions>
      </Dialog>
      {loading && <LoadingBar />}
    </>
  );
}