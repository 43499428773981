/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getCategory = /* GraphQL */ `
  query GetCategory($id: ID!) {
    getCategory(id: $id) {
      id
      category_Id
      title
      posts {
        items {
          id
          post_id
          categoryID
          category {
            id
            category_Id
            title
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
          post {
            id
            post_id
            is_active
            is_approved
            is_flagged
            author_id
            content
            post_video
            post_thumbnail
            post_type
            lat_lon
            loc_name
            updated_by
            createdAt
            updatedAt
          }
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const listCategorys = /* GraphQL */ `
  query ListCategorys(
    $filter: ModelCategoryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCategorys(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        category_Id
        title
        posts {
          items {
            id
            post_id
            categoryID
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getPostLike = /* GraphQL */ `
  query GetPostLike($id: ID!) {
    getPostLike(id: $id) {
      id
      post_id
      user_id
      created_at
      createdAt
      updatedAt
      user {
        user_id
        is_active
        username
        first_name
        last_name
        phone_number
        email
        postal_code
        user_avatar
        default_signature
        profile_info
        affiliate_link
        affiliated_by
        affiliated_by_link
        url
        email_alerts
        email_alerts_contact
        email_alerts_follow
        sms_alerts
        sms_alerts_contact
        sms_alerts_follow
        push_alerts
        push_alerts_contact
        push_alerts_follow
        hide_account
        premium_account
        premium_account_lvl
        last_active
        following {
          items {
            id
            follower_id
            following_id
            createdAt
            updatedAt
          }
          nextToken
        }
        blocked {
          items {
            id
            blocker_id
            blocked_id
            createdAt
            updatedAt
          }
          nextToken
        }
        hidden {
          items {
            id
            reporter_id
            hidden_id
            createdAt
            updatedAt
          }
          nextToken
        }
        favorites {
          items {
            id
            post_id
            user_id
            created_at
            createdAt
            updatedAt
          }
          nextToken
        }
        updated_by
        createdAt
        updatedAt
        posts {
          items {
            id
            post_id
            is_active
            is_approved
            is_flagged
            author_id
            content
            post_video
            post_thumbnail
            post_type
            lat_lon
            loc_name
            updated_by
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      post {
        id
        post_id
        is_active
        is_approved
        is_flagged
        author_id
        content
        post_video
        post_thumbnail
        post_type
        lat_lon
        loc_name
        categories {
          items {
            id
            post_id
            categoryID
            createdAt
            updatedAt
          }
          nextToken
        }
        likes {
          items {
            id
            post_id
            user_id
            created_at
            createdAt
            updatedAt
          }
          nextToken
        }
        favorites {
          items {
            id
            post_id
            user_id
            created_at
            createdAt
            updatedAt
          }
          nextToken
        }
        updated_by
        createdAt
        updatedAt
        author {
          user_id
          is_active
          username
          first_name
          last_name
          phone_number
          email
          postal_code
          user_avatar
          default_signature
          profile_info
          affiliate_link
          affiliated_by
          affiliated_by_link
          url
          email_alerts
          email_alerts_contact
          email_alerts_follow
          sms_alerts
          sms_alerts_contact
          sms_alerts_follow
          push_alerts
          push_alerts_contact
          push_alerts_follow
          hide_account
          premium_account
          premium_account_lvl
          last_active
          following {
            nextToken
          }
          blocked {
            nextToken
          }
          hidden {
            nextToken
          }
          favorites {
            nextToken
          }
          updated_by
          createdAt
          updatedAt
          posts {
            nextToken
          }
        }
      }
    }
  }
`;
export const listPostLikes = /* GraphQL */ `
  query ListPostLikes(
    $filter: ModelPostLikeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPostLikes(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        post_id
        user_id
        created_at
        createdAt
        updatedAt
        user {
          user_id
          is_active
          username
          first_name
          last_name
          phone_number
          email
          postal_code
          user_avatar
          default_signature
          profile_info
          affiliate_link
          affiliated_by
          affiliated_by_link
          url
          email_alerts
          email_alerts_contact
          email_alerts_follow
          sms_alerts
          sms_alerts_contact
          sms_alerts_follow
          push_alerts
          push_alerts_contact
          push_alerts_follow
          hide_account
          premium_account
          premium_account_lvl
          last_active
          following {
            nextToken
          }
          blocked {
            nextToken
          }
          hidden {
            nextToken
          }
          favorites {
            nextToken
          }
          updated_by
          createdAt
          updatedAt
          posts {
            nextToken
          }
        }
        post {
          id
          post_id
          is_active
          is_approved
          is_flagged
          author_id
          content
          post_video
          post_thumbnail
          post_type
          lat_lon
          loc_name
          categories {
            nextToken
          }
          likes {
            nextToken
          }
          favorites {
            nextToken
          }
          updated_by
          createdAt
          updatedAt
          author {
            user_id
            is_active
            username
            first_name
            last_name
            phone_number
            email
            postal_code
            default_signature
            profile_info
            affiliate_link
            affiliated_by
            affiliated_by_link
            url
            email_alerts
            email_alerts_contact
            email_alerts_follow
            sms_alerts
            sms_alerts_contact
            sms_alerts_follow
            push_alerts
            push_alerts_contact
            push_alerts_follow
            hide_account
            premium_account
            premium_account_lvl
            last_active
            updated_by
            createdAt
            updatedAt
          }
        }
      }
      nextToken
    }
  }
`;
export const getPostFavorite = /* GraphQL */ `
  query GetPostFavorite($id: ID!) {
    getPostFavorite(id: $id) {
      id
      post_id
      user_id
      created_at
      createdAt
      updatedAt
      user {
        user_id
        is_active
        username
        first_name
        last_name
        phone_number
        email
        postal_code
        user_avatar
        default_signature
        profile_info
        affiliate_link
        affiliated_by
        affiliated_by_link
        url
        email_alerts
        email_alerts_contact
        email_alerts_follow
        sms_alerts
        sms_alerts_contact
        sms_alerts_follow
        push_alerts
        push_alerts_contact
        push_alerts_follow
        hide_account
        premium_account
        premium_account_lvl
        last_active
        following {
          items {
            id
            follower_id
            following_id
            createdAt
            updatedAt
          }
          nextToken
        }
        blocked {
          items {
            id
            blocker_id
            blocked_id
            createdAt
            updatedAt
          }
          nextToken
        }
        hidden {
          items {
            id
            reporter_id
            hidden_id
            createdAt
            updatedAt
          }
          nextToken
        }
        favorites {
          items {
            id
            post_id
            user_id
            created_at
            createdAt
            updatedAt
          }
          nextToken
        }
        updated_by
        createdAt
        updatedAt
        posts {
          items {
            id
            post_id
            is_active
            is_approved
            is_flagged
            author_id
            content
            post_video
            post_thumbnail
            post_type
            lat_lon
            loc_name
            updated_by
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      post {
        id
        post_id
        is_active
        is_approved
        is_flagged
        author_id
        content
        post_video
        post_thumbnail
        post_type
        lat_lon
        loc_name
        categories {
          items {
            id
            post_id
            categoryID
            createdAt
            updatedAt
          }
          nextToken
        }
        likes {
          items {
            id
            post_id
            user_id
            created_at
            createdAt
            updatedAt
          }
          nextToken
        }
        favorites {
          items {
            id
            post_id
            user_id
            created_at
            createdAt
            updatedAt
          }
          nextToken
        }
        updated_by
        createdAt
        updatedAt
        author {
          user_id
          is_active
          username
          first_name
          last_name
          phone_number
          email
          postal_code
          user_avatar
          default_signature
          profile_info
          affiliate_link
          affiliated_by
          affiliated_by_link
          url
          email_alerts
          email_alerts_contact
          email_alerts_follow
          sms_alerts
          sms_alerts_contact
          sms_alerts_follow
          push_alerts
          push_alerts_contact
          push_alerts_follow
          hide_account
          premium_account
          premium_account_lvl
          last_active
          following {
            nextToken
          }
          blocked {
            nextToken
          }
          hidden {
            nextToken
          }
          favorites {
            nextToken
          }
          updated_by
          createdAt
          updatedAt
          posts {
            nextToken
          }
        }
      }
    }
  }
`;
export const listPostFavorites = /* GraphQL */ `
  query ListPostFavorites(
    $filter: ModelPostFavoriteFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPostFavorites(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        post_id
        user_id
        created_at
        createdAt
        updatedAt
        user {
          user_id
          is_active
          username
          first_name
          last_name
          phone_number
          email
          postal_code
          user_avatar
          default_signature
          profile_info
          affiliate_link
          affiliated_by
          affiliated_by_link
          url
          email_alerts
          email_alerts_contact
          email_alerts_follow
          sms_alerts
          sms_alerts_contact
          sms_alerts_follow
          push_alerts
          push_alerts_contact
          push_alerts_follow
          hide_account
          premium_account
          premium_account_lvl
          last_active
          following {
            nextToken
          }
          blocked {
            nextToken
          }
          hidden {
            nextToken
          }
          favorites {
            nextToken
          }
          updated_by
          createdAt
          updatedAt
          posts {
            nextToken
          }
        }
        post {
          id
          post_id
          is_active
          is_approved
          is_flagged
          author_id
          content
          post_video
          post_thumbnail
          post_type
          lat_lon
          loc_name
          categories {
            nextToken
          }
          likes {
            nextToken
          }
          favorites {
            nextToken
          }
          updated_by
          createdAt
          updatedAt
          author {
            user_id
            is_active
            username
            first_name
            last_name
            phone_number
            email
            postal_code
            default_signature
            profile_info
            affiliate_link
            affiliated_by
            affiliated_by_link
            url
            email_alerts
            email_alerts_contact
            email_alerts_follow
            sms_alerts
            sms_alerts_contact
            sms_alerts_follow
            push_alerts
            push_alerts_contact
            push_alerts_follow
            hide_account
            premium_account
            premium_account_lvl
            last_active
            updated_by
            createdAt
            updatedAt
          }
        }
      }
      nextToken
    }
  }
`;
export const getFollowing = /* GraphQL */ `
  query GetFollowing($id: ID!) {
    getFollowing(id: $id) {
      id
      follower_id
      following_id
      createdAt
      updatedAt
      follower {
        user_id
        is_active
        username
        first_name
        last_name
        phone_number
        email
        postal_code
        user_avatar
        default_signature
        profile_info
        affiliate_link
        affiliated_by
        affiliated_by_link
        url
        email_alerts
        email_alerts_contact
        email_alerts_follow
        sms_alerts
        sms_alerts_contact
        sms_alerts_follow
        push_alerts
        push_alerts_contact
        push_alerts_follow
        hide_account
        premium_account
        premium_account_lvl
        last_active
        following {
          items {
            id
            follower_id
            following_id
            createdAt
            updatedAt
          }
          nextToken
        }
        blocked {
          items {
            id
            blocker_id
            blocked_id
            createdAt
            updatedAt
          }
          nextToken
        }
        hidden {
          items {
            id
            reporter_id
            hidden_id
            createdAt
            updatedAt
          }
          nextToken
        }
        favorites {
          items {
            id
            post_id
            user_id
            created_at
            createdAt
            updatedAt
          }
          nextToken
        }
        updated_by
        createdAt
        updatedAt
        posts {
          items {
            id
            post_id
            is_active
            is_approved
            is_flagged
            author_id
            content
            post_video
            post_thumbnail
            post_type
            lat_lon
            loc_name
            updated_by
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      following {
        user_id
        is_active
        username
        first_name
        last_name
        phone_number
        email
        postal_code
        user_avatar
        default_signature
        profile_info
        affiliate_link
        affiliated_by
        affiliated_by_link
        url
        email_alerts
        email_alerts_contact
        email_alerts_follow
        sms_alerts
        sms_alerts_contact
        sms_alerts_follow
        push_alerts
        push_alerts_contact
        push_alerts_follow
        hide_account
        premium_account
        premium_account_lvl
        last_active
        following {
          items {
            id
            follower_id
            following_id
            createdAt
            updatedAt
          }
          nextToken
        }
        blocked {
          items {
            id
            blocker_id
            blocked_id
            createdAt
            updatedAt
          }
          nextToken
        }
        hidden {
          items {
            id
            reporter_id
            hidden_id
            createdAt
            updatedAt
          }
          nextToken
        }
        favorites {
          items {
            id
            post_id
            user_id
            created_at
            createdAt
            updatedAt
          }
          nextToken
        }
        updated_by
        createdAt
        updatedAt
        posts {
          items {
            id
            post_id
            is_active
            is_approved
            is_flagged
            author_id
            content
            post_video
            post_thumbnail
            post_type
            lat_lon
            loc_name
            updated_by
            createdAt
            updatedAt
          }
          nextToken
        }
      }
    }
  }
`;
export const listFollowings = /* GraphQL */ `
  query ListFollowings(
    $filter: ModelFollowingFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listFollowings(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        follower_id
        following_id
        createdAt
        updatedAt
        follower {
          user_id
          is_active
          username
          first_name
          last_name
          phone_number
          email
          postal_code
          user_avatar
          default_signature
          profile_info
          affiliate_link
          affiliated_by
          affiliated_by_link
          url
          email_alerts
          email_alerts_contact
          email_alerts_follow
          sms_alerts
          sms_alerts_contact
          sms_alerts_follow
          push_alerts
          push_alerts_contact
          push_alerts_follow
          hide_account
          premium_account
          premium_account_lvl
          last_active
          following {
            nextToken
          }
          blocked {
            nextToken
          }
          hidden {
            nextToken
          }
          favorites {
            nextToken
          }
          updated_by
          createdAt
          updatedAt
          posts {
            nextToken
          }
        }
        following {
          user_id
          is_active
          username
          first_name
          last_name
          phone_number
          email
          postal_code
          user_avatar
          default_signature
          profile_info
          affiliate_link
          affiliated_by
          affiliated_by_link
          url
          email_alerts
          email_alerts_contact
          email_alerts_follow
          sms_alerts
          sms_alerts_contact
          sms_alerts_follow
          push_alerts
          push_alerts_contact
          push_alerts_follow
          hide_account
          premium_account
          premium_account_lvl
          last_active
          following {
            nextToken
          }
          blocked {
            nextToken
          }
          hidden {
            nextToken
          }
          favorites {
            nextToken
          }
          updated_by
          createdAt
          updatedAt
          posts {
            nextToken
          }
        }
      }
      nextToken
    }
  }
`;
export const getBlocked = /* GraphQL */ `
  query GetBlocked($id: ID!) {
    getBlocked(id: $id) {
      id
      blocker_id
      blocked_id
      createdAt
      updatedAt
      blocker {
        user_id
        is_active
        username
        first_name
        last_name
        phone_number
        email
        postal_code
        user_avatar
        default_signature
        profile_info
        affiliate_link
        affiliated_by
        affiliated_by_link
        url
        email_alerts
        email_alerts_contact
        email_alerts_follow
        sms_alerts
        sms_alerts_contact
        sms_alerts_follow
        push_alerts
        push_alerts_contact
        push_alerts_follow
        hide_account
        premium_account
        premium_account_lvl
        last_active
        following {
          items {
            id
            follower_id
            following_id
            createdAt
            updatedAt
          }
          nextToken
        }
        blocked {
          items {
            id
            blocker_id
            blocked_id
            createdAt
            updatedAt
          }
          nextToken
        }
        hidden {
          items {
            id
            reporter_id
            hidden_id
            createdAt
            updatedAt
          }
          nextToken
        }
        favorites {
          items {
            id
            post_id
            user_id
            created_at
            createdAt
            updatedAt
          }
          nextToken
        }
        updated_by
        createdAt
        updatedAt
        posts {
          items {
            id
            post_id
            is_active
            is_approved
            is_flagged
            author_id
            content
            post_video
            post_thumbnail
            post_type
            lat_lon
            loc_name
            updated_by
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      blocked {
        user_id
        is_active
        username
        first_name
        last_name
        phone_number
        email
        postal_code
        user_avatar
        default_signature
        profile_info
        affiliate_link
        affiliated_by
        affiliated_by_link
        url
        email_alerts
        email_alerts_contact
        email_alerts_follow
        sms_alerts
        sms_alerts_contact
        sms_alerts_follow
        push_alerts
        push_alerts_contact
        push_alerts_follow
        hide_account
        premium_account
        premium_account_lvl
        last_active
        following {
          items {
            id
            follower_id
            following_id
            createdAt
            updatedAt
          }
          nextToken
        }
        blocked {
          items {
            id
            blocker_id
            blocked_id
            createdAt
            updatedAt
          }
          nextToken
        }
        hidden {
          items {
            id
            reporter_id
            hidden_id
            createdAt
            updatedAt
          }
          nextToken
        }
        favorites {
          items {
            id
            post_id
            user_id
            created_at
            createdAt
            updatedAt
          }
          nextToken
        }
        updated_by
        createdAt
        updatedAt
        posts {
          items {
            id
            post_id
            is_active
            is_approved
            is_flagged
            author_id
            content
            post_video
            post_thumbnail
            post_type
            lat_lon
            loc_name
            updated_by
            createdAt
            updatedAt
          }
          nextToken
        }
      }
    }
  }
`;
export const listBlockeds = /* GraphQL */ `
  query ListBlockeds(
    $filter: ModelBlockedFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listBlockeds(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        blocker_id
        blocked_id
        createdAt
        updatedAt
        blocker {
          user_id
          is_active
          username
          first_name
          last_name
          phone_number
          email
          postal_code
          user_avatar
          default_signature
          profile_info
          affiliate_link
          affiliated_by
          affiliated_by_link
          url
          email_alerts
          email_alerts_contact
          email_alerts_follow
          sms_alerts
          sms_alerts_contact
          sms_alerts_follow
          push_alerts
          push_alerts_contact
          push_alerts_follow
          hide_account
          premium_account
          premium_account_lvl
          last_active
          following {
            nextToken
          }
          blocked {
            nextToken
          }
          hidden {
            nextToken
          }
          favorites {
            nextToken
          }
          updated_by
          createdAt
          updatedAt
          posts {
            nextToken
          }
        }
        blocked {
          user_id
          is_active
          username
          first_name
          last_name
          phone_number
          email
          postal_code
          user_avatar
          default_signature
          profile_info
          affiliate_link
          affiliated_by
          affiliated_by_link
          url
          email_alerts
          email_alerts_contact
          email_alerts_follow
          sms_alerts
          sms_alerts_contact
          sms_alerts_follow
          push_alerts
          push_alerts_contact
          push_alerts_follow
          hide_account
          premium_account
          premium_account_lvl
          last_active
          following {
            nextToken
          }
          blocked {
            nextToken
          }
          hidden {
            nextToken
          }
          favorites {
            nextToken
          }
          updated_by
          createdAt
          updatedAt
          posts {
            nextToken
          }
        }
      }
      nextToken
    }
  }
`;
export const getHidden = /* GraphQL */ `
  query GetHidden($id: ID!) {
    getHidden(id: $id) {
      id
      reporter_id
      hidden_id
      createdAt
      updatedAt
      reporter {
        user_id
        is_active
        username
        first_name
        last_name
        phone_number
        email
        postal_code
        user_avatar
        default_signature
        profile_info
        affiliate_link
        affiliated_by
        affiliated_by_link
        url
        email_alerts
        email_alerts_contact
        email_alerts_follow
        sms_alerts
        sms_alerts_contact
        sms_alerts_follow
        push_alerts
        push_alerts_contact
        push_alerts_follow
        hide_account
        premium_account
        premium_account_lvl
        last_active
        following {
          items {
            id
            follower_id
            following_id
            createdAt
            updatedAt
          }
          nextToken
        }
        blocked {
          items {
            id
            blocker_id
            blocked_id
            createdAt
            updatedAt
          }
          nextToken
        }
        hidden {
          items {
            id
            reporter_id
            hidden_id
            createdAt
            updatedAt
          }
          nextToken
        }
        favorites {
          items {
            id
            post_id
            user_id
            created_at
            createdAt
            updatedAt
          }
          nextToken
        }
        updated_by
        createdAt
        updatedAt
        posts {
          items {
            id
            post_id
            is_active
            is_approved
            is_flagged
            author_id
            content
            post_video
            post_thumbnail
            post_type
            lat_lon
            loc_name
            updated_by
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      hidden {
        id
        post_id
        is_active
        is_approved
        is_flagged
        author_id
        content
        post_video
        post_thumbnail
        post_type
        lat_lon
        loc_name
        categories {
          items {
            id
            post_id
            categoryID
            createdAt
            updatedAt
          }
          nextToken
        }
        likes {
          items {
            id
            post_id
            user_id
            created_at
            createdAt
            updatedAt
          }
          nextToken
        }
        favorites {
          items {
            id
            post_id
            user_id
            created_at
            createdAt
            updatedAt
          }
          nextToken
        }
        updated_by
        createdAt
        updatedAt
        author {
          user_id
          is_active
          username
          first_name
          last_name
          phone_number
          email
          postal_code
          user_avatar
          default_signature
          profile_info
          affiliate_link
          affiliated_by
          affiliated_by_link
          url
          email_alerts
          email_alerts_contact
          email_alerts_follow
          sms_alerts
          sms_alerts_contact
          sms_alerts_follow
          push_alerts
          push_alerts_contact
          push_alerts_follow
          hide_account
          premium_account
          premium_account_lvl
          last_active
          following {
            nextToken
          }
          blocked {
            nextToken
          }
          hidden {
            nextToken
          }
          favorites {
            nextToken
          }
          updated_by
          createdAt
          updatedAt
          posts {
            nextToken
          }
        }
      }
    }
  }
`;
export const listHiddens = /* GraphQL */ `
  query ListHiddens(
    $filter: ModelHiddenFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listHiddens(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        reporter_id
        hidden_id
        createdAt
        updatedAt
        reporter {
          user_id
          is_active
          username
          first_name
          last_name
          phone_number
          email
          postal_code
          user_avatar
          default_signature
          profile_info
          affiliate_link
          affiliated_by
          affiliated_by_link
          url
          email_alerts
          email_alerts_contact
          email_alerts_follow
          sms_alerts
          sms_alerts_contact
          sms_alerts_follow
          push_alerts
          push_alerts_contact
          push_alerts_follow
          hide_account
          premium_account
          premium_account_lvl
          last_active
          following {
            nextToken
          }
          blocked {
            nextToken
          }
          hidden {
            nextToken
          }
          favorites {
            nextToken
          }
          updated_by
          createdAt
          updatedAt
          posts {
            nextToken
          }
        }
        hidden {
          id
          post_id
          is_active
          is_approved
          is_flagged
          author_id
          content
          post_video
          post_thumbnail
          post_type
          lat_lon
          loc_name
          categories {
            nextToken
          }
          likes {
            nextToken
          }
          favorites {
            nextToken
          }
          updated_by
          createdAt
          updatedAt
          author {
            user_id
            is_active
            username
            first_name
            last_name
            phone_number
            email
            postal_code
            default_signature
            profile_info
            affiliate_link
            affiliated_by
            affiliated_by_link
            url
            email_alerts
            email_alerts_contact
            email_alerts_follow
            sms_alerts
            sms_alerts_contact
            sms_alerts_follow
            push_alerts
            push_alerts_contact
            push_alerts_follow
            hide_account
            premium_account
            premium_account_lvl
            last_active
            updated_by
            createdAt
            updatedAt
          }
        }
      }
      nextToken
    }
  }
`;
export const getReported = /* GraphQL */ `
  query GetReported($id: ID!) {
    getReported(id: $id) {
      id
      reporter_id
      reported_id
      report_reason
      report_desc
      createdAt
      updatedAt
      reporter {
        user_id
        is_active
        username
        first_name
        last_name
        phone_number
        email
        postal_code
        user_avatar
        default_signature
        profile_info
        affiliate_link
        affiliated_by
        affiliated_by_link
        url
        email_alerts
        email_alerts_contact
        email_alerts_follow
        sms_alerts
        sms_alerts_contact
        sms_alerts_follow
        push_alerts
        push_alerts_contact
        push_alerts_follow
        hide_account
        premium_account
        premium_account_lvl
        last_active
        following {
          items {
            id
            follower_id
            following_id
            createdAt
            updatedAt
          }
          nextToken
        }
        blocked {
          items {
            id
            blocker_id
            blocked_id
            createdAt
            updatedAt
          }
          nextToken
        }
        hidden {
          items {
            id
            reporter_id
            hidden_id
            createdAt
            updatedAt
          }
          nextToken
        }
        favorites {
          items {
            id
            post_id
            user_id
            created_at
            createdAt
            updatedAt
          }
          nextToken
        }
        updated_by
        createdAt
        updatedAt
        posts {
          items {
            id
            post_id
            is_active
            is_approved
            is_flagged
            author_id
            content
            post_video
            post_thumbnail
            post_type
            lat_lon
            loc_name
            updated_by
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      reported {
        id
        post_id
        is_active
        is_approved
        is_flagged
        author_id
        content
        post_video
        post_thumbnail
        post_type
        lat_lon
        loc_name
        categories {
          items {
            id
            post_id
            categoryID
            createdAt
            updatedAt
          }
          nextToken
        }
        likes {
          items {
            id
            post_id
            user_id
            created_at
            createdAt
            updatedAt
          }
          nextToken
        }
        favorites {
          items {
            id
            post_id
            user_id
            created_at
            createdAt
            updatedAt
          }
          nextToken
        }
        updated_by
        createdAt
        updatedAt
        author {
          user_id
          is_active
          username
          first_name
          last_name
          phone_number
          email
          postal_code
          user_avatar
          default_signature
          profile_info
          affiliate_link
          affiliated_by
          affiliated_by_link
          url
          email_alerts
          email_alerts_contact
          email_alerts_follow
          sms_alerts
          sms_alerts_contact
          sms_alerts_follow
          push_alerts
          push_alerts_contact
          push_alerts_follow
          hide_account
          premium_account
          premium_account_lvl
          last_active
          following {
            nextToken
          }
          blocked {
            nextToken
          }
          hidden {
            nextToken
          }
          favorites {
            nextToken
          }
          updated_by
          createdAt
          updatedAt
          posts {
            nextToken
          }
        }
      }
    }
  }
`;
export const listReporteds = /* GraphQL */ `
  query ListReporteds(
    $filter: ModelReportedFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listReporteds(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        reporter_id
        reported_id
        report_reason
        report_desc
        createdAt
        updatedAt
        reporter {
          user_id
          is_active
          username
          first_name
          last_name
          phone_number
          email
          postal_code
          user_avatar
          default_signature
          profile_info
          affiliate_link
          affiliated_by
          affiliated_by_link
          url
          email_alerts
          email_alerts_contact
          email_alerts_follow
          sms_alerts
          sms_alerts_contact
          sms_alerts_follow
          push_alerts
          push_alerts_contact
          push_alerts_follow
          hide_account
          premium_account
          premium_account_lvl
          last_active
          following {
            nextToken
          }
          blocked {
            nextToken
          }
          hidden {
            nextToken
          }
          favorites {
            nextToken
          }
          updated_by
          createdAt
          updatedAt
          posts {
            nextToken
          }
        }
        reported {
          id
          post_id
          is_active
          is_approved
          is_flagged
          author_id
          content
          post_video
          post_thumbnail
          post_type
          lat_lon
          loc_name
          categories {
            nextToken
          }
          likes {
            nextToken
          }
          favorites {
            nextToken
          }
          updated_by
          createdAt
          updatedAt
          author {
            user_id
            is_active
            username
            first_name
            last_name
            phone_number
            email
            postal_code
            default_signature
            profile_info
            affiliate_link
            affiliated_by
            affiliated_by_link
            url
            email_alerts
            email_alerts_contact
            email_alerts_follow
            sms_alerts
            sms_alerts_contact
            sms_alerts_follow
            push_alerts
            push_alerts_contact
            push_alerts_follow
            hide_account
            premium_account
            premium_account_lvl
            last_active
            updated_by
            createdAt
            updatedAt
          }
        }
      }
      nextToken
    }
  }
`;
export const getS3Object = /* GraphQL */ `
  query GetS3Object($id: ID!) {
    getS3Object(id: $id) {
      id
      bucket
      region
      key
      createdAt
      updatedAt
    }
  }
`;
export const listS3Objects = /* GraphQL */ `
  query ListS3Objects(
    $filter: ModelS3ObjectFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listS3Objects(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        bucket
        region
        key
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const likesByUser = /* GraphQL */ `
  query LikesByUser(
    $user_id: ID
    $created_at: ModelIntKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelPostLikeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    likesByUser(
      user_id: $user_id
      created_at: $created_at
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        post_id
        user_id
        created_at
        createdAt
        updatedAt
        user {
          user_id
          is_active
          username
          first_name
          last_name
          phone_number
          email
          postal_code
          user_avatar
          default_signature
          profile_info
          affiliate_link
          affiliated_by
          affiliated_by_link
          url
          email_alerts
          email_alerts_contact
          email_alerts_follow
          sms_alerts
          sms_alerts_contact
          sms_alerts_follow
          push_alerts
          push_alerts_contact
          push_alerts_follow
          hide_account
          premium_account
          premium_account_lvl
          last_active
          following {
            nextToken
          }
          blocked {
            nextToken
          }
          hidden {
            nextToken
          }
          favorites {
            nextToken
          }
          updated_by
          createdAt
          updatedAt
          posts {
            nextToken
          }
        }
        post {
          id
          post_id
          is_active
          is_approved
          is_flagged
          author_id
          content
          post_video
          post_thumbnail
          post_type
          lat_lon
          loc_name
          categories {
            nextToken
          }
          likes {
            nextToken
          }
          favorites {
            nextToken
          }
          updated_by
          createdAt
          updatedAt
          author {
            user_id
            is_active
            username
            first_name
            last_name
            phone_number
            email
            postal_code
            default_signature
            profile_info
            affiliate_link
            affiliated_by
            affiliated_by_link
            url
            email_alerts
            email_alerts_contact
            email_alerts_follow
            sms_alerts
            sms_alerts_contact
            sms_alerts_follow
            push_alerts
            push_alerts_contact
            push_alerts_follow
            hide_account
            premium_account
            premium_account_lvl
            last_active
            updated_by
            createdAt
            updatedAt
          }
        }
      }
      nextToken
    }
  }
`;
export const favoritesByUser = /* GraphQL */ `
  query FavoritesByUser(
    $user_id: ID
    $created_at: ModelIntKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelPostFavoriteFilterInput
    $limit: Int
    $nextToken: String
  ) {
    favoritesByUser(
      user_id: $user_id
      created_at: $created_at
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        post_id
        user_id
        created_at
        createdAt
        updatedAt
        user {
          user_id
          is_active
          username
          first_name
          last_name
          phone_number
          email
          postal_code
          user_avatar
          default_signature
          profile_info
          affiliate_link
          affiliated_by
          affiliated_by_link
          url
          email_alerts
          email_alerts_contact
          email_alerts_follow
          sms_alerts
          sms_alerts_contact
          sms_alerts_follow
          push_alerts
          push_alerts_contact
          push_alerts_follow
          hide_account
          premium_account
          premium_account_lvl
          last_active
          following {
            nextToken
          }
          blocked {
            nextToken
          }
          hidden {
            nextToken
          }
          favorites {
            nextToken
          }
          updated_by
          createdAt
          updatedAt
          posts {
            nextToken
          }
        }
        post {
          id
          post_id
          is_active
          is_approved
          is_flagged
          author_id
          content
          post_video
          post_thumbnail
          post_type
          lat_lon
          loc_name
          categories {
            nextToken
          }
          likes {
            nextToken
          }
          favorites {
            nextToken
          }
          updated_by
          createdAt
          updatedAt
          author {
            user_id
            is_active
            username
            first_name
            last_name
            phone_number
            email
            postal_code
            default_signature
            profile_info
            affiliate_link
            affiliated_by
            affiliated_by_link
            url
            email_alerts
            email_alerts_contact
            email_alerts_follow
            sms_alerts
            sms_alerts_contact
            sms_alerts_follow
            push_alerts
            push_alerts_contact
            push_alerts_follow
            hide_account
            premium_account
            premium_account_lvl
            last_active
            updated_by
            createdAt
            updatedAt
          }
        }
      }
      nextToken
    }
  }
`;
export const listUsers = /* GraphQL */ `
  query ListUsers(
    $user_id: ID
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listUsers(
      user_id: $user_id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        user_id
        is_active
        username
        first_name
        last_name
        phone_number
        email
        postal_code
        user_avatar
        default_signature
        profile_info
        affiliate_link
        affiliated_by
        affiliated_by_link
        url
        email_alerts
        email_alerts_contact
        email_alerts_follow
        sms_alerts
        sms_alerts_contact
        sms_alerts_follow
        push_alerts
        push_alerts_contact
        push_alerts_follow
        hide_account
        premium_account
        premium_account_lvl
        last_active
        deviceInfo
        language
        type
        location
        following {
          items {
            id
            follower_id
            following_id
            createdAt
            updatedAt
          }
          nextToken
        }
        blocked {
          items {
            id
            blocker_id
            blocked_id
            createdAt
            updatedAt
          }
          nextToken
        }
        hidden {
          items {
            id
            reporter_id
            hidden_id
            createdAt
            updatedAt
          }
          nextToken
        }
        favorites {
          items {
            id
            post_id
            user_id
            created_at
            createdAt
            updatedAt
          }
          nextToken
        }
        updated_by
        createdAt
        updatedAt
        posts {
          items {
            id
            post_id
            is_active
            is_approved
            is_flagged
            author_id
            content
            post_video
            post_thumbnail
            post_type
            lat_lon
            loc_name
            updated_by
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const getUser = /* GraphQL */ `
  query GetUser($user_id: ID!) {
    getUser(user_id: $user_id) {
      user_id
      is_active
      username
      first_name
      last_name
      phone_number
      email
      postal_code
      user_avatar
      default_signature
      profile_info
      affiliate_link
      affiliated_by
      affiliated_by_link
      url
      email_alerts
      email_alerts_contact
      email_alerts_follow
      sms_alerts
      sms_alerts_contact
      sms_alerts_follow
      push_alerts
      push_alerts_contact
      push_alerts_follow
      hide_account
      premium_account
      premium_account_lvl
      last_active
      following {
        items {
          id
          follower_id
          following_id
          createdAt
          updatedAt
          follower {
            user_id
            is_active
            username
            first_name
            last_name
            phone_number
            email
            postal_code
            default_signature
            profile_info
            affiliate_link
            affiliated_by
            affiliated_by_link
            url
            email_alerts
            email_alerts_contact
            email_alerts_follow
            sms_alerts
            sms_alerts_contact
            sms_alerts_follow
            push_alerts
            push_alerts_contact
            push_alerts_follow
            hide_account
            premium_account
            premium_account_lvl
            last_active
            updated_by
            createdAt
            updatedAt
          }
          following {
            user_id
            is_active
            username
            first_name
            last_name
            phone_number
            email
            postal_code
            default_signature
            profile_info
            affiliate_link
            affiliated_by
            affiliated_by_link
            url
            email_alerts
            email_alerts_contact
            email_alerts_follow
            sms_alerts
            sms_alerts_contact
            sms_alerts_follow
            push_alerts
            push_alerts_contact
            push_alerts_follow
            hide_account
            premium_account
            premium_account_lvl
            last_active
            updated_by
            createdAt
            updatedAt
          }
        }
        nextToken
      }
      blocked {
        items {
          id
          blocker_id
          blocked_id
          createdAt
          updatedAt
          blocker {
            user_id
            is_active
            username
            first_name
            last_name
            phone_number
            email
            postal_code
            default_signature
            profile_info
            affiliate_link
            affiliated_by
            affiliated_by_link
            url
            email_alerts
            email_alerts_contact
            email_alerts_follow
            sms_alerts
            sms_alerts_contact
            sms_alerts_follow
            push_alerts
            push_alerts_contact
            push_alerts_follow
            hide_account
            premium_account
            premium_account_lvl
            last_active
            updated_by
            createdAt
            updatedAt
          }
          blocked {
            user_id
            is_active
            username
            first_name
            last_name
            phone_number
            email
            postal_code
            default_signature
            profile_info
            affiliate_link
            affiliated_by
            affiliated_by_link
            url
            email_alerts
            email_alerts_contact
            email_alerts_follow
            sms_alerts
            sms_alerts_contact
            sms_alerts_follow
            push_alerts
            push_alerts_contact
            push_alerts_follow
            hide_account
            premium_account
            premium_account_lvl
            last_active
            updated_by
            createdAt
            updatedAt
          }
        }
        nextToken
      }
      hidden {
        items {
          id
          reporter_id
          hidden_id
          createdAt
          updatedAt
          reporter {
            user_id
            is_active
            username
            first_name
            last_name
            phone_number
            email
            postal_code
            default_signature
            profile_info
            affiliate_link
            affiliated_by
            affiliated_by_link
            url
            email_alerts
            email_alerts_contact
            email_alerts_follow
            sms_alerts
            sms_alerts_contact
            sms_alerts_follow
            push_alerts
            push_alerts_contact
            push_alerts_follow
            hide_account
            premium_account
            premium_account_lvl
            last_active
            updated_by
            createdAt
            updatedAt
          }
          hidden {
            id
            post_id
            is_active
            is_approved
            is_flagged
            author_id
            content
            post_video
            post_thumbnail
            post_type
            lat_lon
            loc_name
            updated_by
            createdAt
            updatedAt
          }
        }
        nextToken
      }
      favorites {
        items {
          id
          post_id
          user_id
          created_at
          createdAt
          updatedAt
          user {
            user_id
            is_active
            username
            first_name
            last_name
            phone_number
            email
            postal_code
            default_signature
            profile_info
            affiliate_link
            affiliated_by
            affiliated_by_link
            url
            email_alerts
            email_alerts_contact
            email_alerts_follow
            sms_alerts
            sms_alerts_contact
            sms_alerts_follow
            push_alerts
            push_alerts_contact
            push_alerts_follow
            hide_account
            premium_account
            premium_account_lvl
            last_active
            updated_by
            createdAt
            updatedAt
          }
          post {
            id
            post_id
            is_active
            is_approved
            is_flagged
            author_id
            content
            post_video
            post_thumbnail
            post_type
            lat_lon
            loc_name
            updated_by
            createdAt
            updatedAt
          }
        }
        nextToken
      }
      updated_by
      createdAt
      updatedAt
      posts {
        items {
          id
          post_id
          is_active
          is_approved
          is_flagged
          author_id
          content
          post_video
          post_thumbnail
          post_type
          lat_lon
          loc_name
          categories {
            nextToken
          }
          likes {
            nextToken
          }
          favorites {
            nextToken
          }
          updated_by
          createdAt
          updatedAt
          author {
            user_id
            is_active
            username
            first_name
            last_name
            phone_number
            email
            postal_code
            default_signature
            profile_info
            affiliate_link
            affiliated_by
            affiliated_by_link
            url
            email_alerts
            email_alerts_contact
            email_alerts_follow
            sms_alerts
            sms_alerts_contact
            sms_alerts_follow
            push_alerts
            push_alerts_contact
            push_alerts_follow
            hide_account
            premium_account
            premium_account_lvl
            last_active
            updated_by
            createdAt
            updatedAt
          }
        }
        nextToken
      }
    }
  }
`;
export const listPosts = /* GraphQL */ `
  query ListPosts(
    $filter: ModelPostFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPosts(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        post_id
        is_active
        is_approved
        is_flagged
        author_id
        content
        post_video
        post_thumbnail
        post_type
        lat_lon
        loc_name
        title
        language
        categories {
          items {
            id
            post_id
            categoryID
            createdAt
            updatedAt
            category {
              id
              category_Id
              title
              createdAt
              updatedAt
            }
          }
          nextToken
        }
        likes {
          items {
            id
            post_id
            user_id
            created_at
            createdAt
            updatedAt
          }
          nextToken
        }
        favorites {
          items {
            id
            post_id
            user_id
            created_at
            createdAt
            updatedAt
          }
          nextToken
        }
        updated_by
        createdAt
        updatedAt
        author {
          user_id
          is_active
          username
          first_name
          last_name
          phone_number
          email
          postal_code
          user_avatar
          default_signature
          profile_info
          affiliate_link
          affiliated_by
          affiliated_by_link
          url
          email_alerts
          email_alerts_contact
          email_alerts_follow
          sms_alerts
          sms_alerts_contact
          sms_alerts_follow
          push_alerts
          push_alerts_contact
          push_alerts_follow
          hide_account
          premium_account
          premium_account_lvl
          last_active
          deviceInfo
          deviceToken
          following {
            nextToken
          }
          blocked {
            nextToken
          }
          hidden {
            nextToken
          }
          favorites {
            nextToken
          }
          updated_by
          createdAt
          updatedAt
          posts {
            nextToken
          }
        }
      }
      nextToken
    }
  }
`;
export const getPost = /* GraphQL */ `
  query GetPost($id: ID!) {
    getPost(id: $id) {
      id
      post_id
      is_active
      is_approved
      is_flagged
      author_id
      content
      post_video
      post_thumbnail
      post_type
      lat_lon
      loc_name
      categories {
        items {
          id
          post_id
          categoryID
          category {
            id
            category_Id
            title
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
          post {
            id
            post_id
            is_active
            is_approved
            is_flagged
            author_id
            content
            post_video
            post_thumbnail
            post_type
            lat_lon
            loc_name
            updated_by
            createdAt
            updatedAt
          }
        }
        nextToken
      }
      likes {
        items {
          id
          post_id
          user_id
          created_at
          createdAt
          updatedAt
          user {
            user_id
            is_active
            username
            first_name
            last_name
            phone_number
            email
            postal_code
            default_signature
            profile_info
            affiliate_link
            affiliated_by
            affiliated_by_link
            url
            email_alerts
            email_alerts_contact
            email_alerts_follow
            sms_alerts
            sms_alerts_contact
            sms_alerts_follow
            push_alerts
            push_alerts_contact
            push_alerts_follow
            hide_account
            premium_account
            premium_account_lvl
            last_active
            updated_by
            createdAt
            updatedAt
          }
          post {
            id
            post_id
            is_active
            is_approved
            is_flagged
            author_id
            content
            post_video
            post_thumbnail
            post_type
            lat_lon
            loc_name
            updated_by
            createdAt
            updatedAt
          }
        }
        nextToken
      }
      favorites {
        items {
          id
          post_id
          user_id
          created_at
          createdAt
          updatedAt
          user {
            user_id
            is_active
            username
            first_name
            last_name
            phone_number
            email
            postal_code
            default_signature
            profile_info
            affiliate_link
            affiliated_by
            affiliated_by_link
            url
            email_alerts
            email_alerts_contact
            email_alerts_follow
            sms_alerts
            sms_alerts_contact
            sms_alerts_follow
            push_alerts
            push_alerts_contact
            push_alerts_follow
            hide_account
            premium_account
            premium_account_lvl
            last_active
            updated_by
            createdAt
            updatedAt
          }
          post {
            id
            post_id
            is_active
            is_approved
            is_flagged
            author_id
            content
            post_video
            post_thumbnail
            post_type
            lat_lon
            loc_name
            updated_by
            createdAt
            updatedAt
          }
        }
        nextToken
      }
      updated_by
      createdAt
      updatedAt
      author {
        user_id
        is_active
        username
        first_name
        last_name
        phone_number
        email
        postal_code
        user_avatar
        default_signature
        profile_info
        affiliate_link
        affiliated_by
        affiliated_by_link
        url
        email_alerts
        email_alerts_contact
        email_alerts_follow
        sms_alerts
        sms_alerts_contact
        sms_alerts_follow
        push_alerts
        push_alerts_contact
        push_alerts_follow
        hide_account
        premium_account
        premium_account_lvl
        last_active
        following {
          items {
            id
            follower_id
            following_id
            createdAt
            updatedAt
          }
          nextToken
        }
        blocked {
          items {
            id
            blocker_id
            blocked_id
            createdAt
            updatedAt
          }
          nextToken
        }
        hidden {
          items {
            id
            reporter_id
            hidden_id
            createdAt
            updatedAt
          }
          nextToken
        }
        favorites {
          items {
            id
            post_id
            user_id
            created_at
            createdAt
            updatedAt
          }
          nextToken
        }
        updated_by
        createdAt
        updatedAt
        posts {
          items {
            id
            post_id
            is_active
            is_approved
            is_flagged
            author_id
            content
            post_video
            post_thumbnail
            post_type
            lat_lon
            loc_name
            updated_by
            createdAt
            updatedAt
          }
          nextToken
        }
      }
    }
  }
`;
